import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import _, { get } from "lodash";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { FieldArray, getFormValues, reduxForm } from "redux-form";
import {
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  AccountTree,
  Add,
  AddCircleOutline,
  Check,
  CheckCircle,
  Close,
  Delete,
  DeleteSweep,
  Description,
  Error,
  GetApp,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Print,
  Remove,
  SystemUpdateAlt,
  Warning,
} from "@material-ui/icons";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox, {
  ALIGN_CENTER,
  ALIGN_END,
  ALIGN_START,
  JUSTIFY_CENTER,
  JUSTIFY_END,
  JUSTIFY_SPACE_AROUND,
  JUSTIFY_START,
} from "../ui-core/FlexBox";
import {
  getValue,
  isEqualDataIn,
  kirilToLatinByKeyboardPlace,
  toJS,
} from "../../helpers/DataUtils";
import {
  formatDateTimeToUrlWithSeconds,
  formatText,
} from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ORDER_LIST_URL,
  POST_CREATE_URL,
} from "../../constants/AdminPathConstants";
import FormCheckbox from "../form/FormCheckbox";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import AddressTypes, {
  BUSINESS,
  RESIDENTIAL,
} from "../../constants/AddressType";
import { button } from "../../../shared/theme/main-theme";
import { fromJS, List, Map } from "immutable";
import FormCustomerWithMerchantsAutoComplete from "../form/FormCustomerWithMerchantsAutoComplete";
import {
  getAVVALUserId,
  getIsUsingScale,
  getUserLanguage,
  getUserName,
  hasUserPermission,
} from "../../reducers/ProfileReducer";
import PostcodeAutoCompleteForOrder from "../form/PostcodeAutoCompleteForOrder";
import FormJurisdictionSelectField from "../form/FormJurisdictionSelectField";
import {
  createOrder,
  createOrUpdateOrder,
  getAdminServiceTypes,
  getCheckId,
  getCourierPrices2,
  setReceipt,
} from "../../api/v2/admin/AdminOrderApi";
import {
  COD,
  COD_FEE,
  CUSTOMS_FEE,
  FORWARDING_FEE,
  INSURANCE_FEE,
  NOTIFICATION_FEE,
  SERVICE,
  SERVICE_ADDED,
  SERVICE_MAIN,
  STAMP,
  TOTAL,
} from "../../constants/OrderChargeItemTypes";
import { TransitionUp } from "../dialog/TransitionUp";
import {
  showErrorMessage,
  showSuccessMessage,
  SUCCESS_MESSAGE,
} from "../../reducers/NotificationsReducer";
import {
  CARD,
  CASH,
  CORPORATE_INTERNAL,
  CREDIT_BALANCE,
  freePayTypes,
  NON_PAYMENT,
} from "../../constants/OrderPaymentTypes";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";
import {
  createNotEmptyValidator,
  createNotFalsyValidator,
  createNotNullValidator,
  getPDF,
  tariffCodeValidator,
  toFinite,
} from "../../helpers/FormUtils";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import MuiPhoneNumber from "material-ui-phone-number-uzpost";
import PageLoading from "../ui-core/PageLoading";
import SubmitNotPaidPostsDialog from "../../wrappers/admin/SubmitNotPaidPostsDialog";
import { getHSCodes } from "../../api/admin/AdminOrderApi";
import { api } from "../../api/shared/BaseApi";
import { getBatchChargeItems } from "../../api/admin/AdminBatchApi";
import { CASH_CLIENT2, CLIENT_MERCHANT2 } from "../../constants/ClientType";
import { green, lightGreen, orange, red } from "@material-ui/core/colors";
import { getCurrencies } from "../../api/shared/CurrencyApi";
import SimpleAutocomplete from "../form/SimpleAutocomplete";
import {
  getCachedPostServices,
  getPostServicesPredictions2,
} from "../../api/shared/OrderApi";
import { RUSSIAN_LANGUAGE, UZBEK_LANGUAGE } from "../../constants/LocaleTypes";
import { getPaymentMethods2 } from "../../api/v2/admin/AdminCustomerApi";
import {
  BANDEROL,
  LETTER,
  PARCEL,
  POSTCARD,
  SEKOGRAMM,
  SMALL_PACKETS,
  TELEGRAM,
} from "../../helpers/OrderOutboundSortingHelper";
import { calculateVAT } from "../order-details-dialog/v2/OrderDetailsDialog";
import { getJurisdictionByLocation } from "../../api/admin/AdminJurisdictionsApi";
import { YANDEX_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import FormYandexGeoAutoComplete from "../form/FormYandexGeoAutoComplete";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { getPostcodesByLocation } from "../../api/admin/AdminPostcodesApi";
import LocationAddresstypes, {
  BIZ,
  GEO,
} from "../../constants/LocationAddresstypes";
import { formatAddressTypeLocalisation } from "../../helpers/OrderHelper";
import ManualSetPostLocationDialog from "../form/ManualSetPostLocationDialog";
import FormCurrencyAutoComplete from "../form/FormCurrencyAutoComplete";
import CommissionTINDialog, {
  avvalSearchUrl,
  contentItem,
} from "./CommissionTINDialog";
import ProductCategoryDialog, {
  productCategory,
} from "./ProductCategoryDialog";
import { MERCHANTS } from "../../constants/MerchantTypes";

export const NOT_STARTED = "NOT_STARTED";
export const LOADING = "LOADING";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";

export const RED = "RED";
export const GREEN = "GREEN";

export const IS_PRINTING = "isPrinting";
export const IS_DOWNLOADING = "isDownloading";

export const AWB_URL = `${API_V2_ROOT_URL}/awb`;
export const CP72_URL = `${API_V2_ROOT_URL}/cp72`;
export const RECEIPT_URL = `${API_V2_ROOT_URL}/receipt`;
export const COD_RECEIPT_URL = `${API_V2_ROOT_URL}/cod_receipt`;
export const RTO_RECEIPT_URL = `${API_V2_ROOT_URL}/rto_receipt`;
export const BATCH_RECEIPT_URL = `${API_V2_ROOT_URL}/batch_receipt`;
export const RECEIPT_PRINTABLE_URL = `${API_V2_ROOT_URL}/receipt_printable`;
export const ZEBRA_URL = `${API_V2_ROOT_URL}/cn22_shipping_label`;
export const BARCODE_VALIDATION_URL = `${API_V2_ROOT_URL}/admin/order/barcode/validate`;
export const UZ = "UZ";
export const SALE_OF_GOODS = "SALE_OF_GOODS";
export const RETURNED_GOODS = "RETURNED_GOODS";
export const GIFT = "GIFT";
export const COMMERCIAL_SAMPLE = "COMMERCIAL_SAMPLE";
export const DOCUMENTS = "DOCUMENTS";
export const MIXED_CONTENT = "MIXED_CONTENT";
export const OTHER = "OTHER";

const transportationTypeArray = [
  "AIR",
  "TERRESTRIAL",
  "COURIER_SERVICE",
  "ONE_STEP",
  "CORPORATE_INTERNAL",
];

const formatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 2,
});
export const money = x => formatter.format(toFinite(x));
export const dots =
  "........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................";
export const showedPrice = v => (v ? "rate_legal" : "rate_individual");
const reducerCIS = (a, b) => a + toFinite(b.price);

export const mapResponseListAsList = fp.flow(
  response => fromJS(response),
  response =>
    fromJS({
      pending: response.get("pending", false),
      list: response.getIn(["payload", "data", "list"], List()),
    }),
);
export const JURISDICTION_UZBEKISTAN = {
  id: 246,
  name: "Узбекистан",
  description: UZ,
  country_code: UZ,
  parent_id: null,
  lat: 41.311081,
  lng: 69.240562,
};
export const UZB_CURRENCY = { code: "UZS", name: "Uzbekistan Som" };
export const CUSTOMS_FEE_AVVAL = {
  type: "customs_fee",
  id: 2557386,
  name: "Таможенный сбор",
  code: "CUSTOMS_FEE",
};

export const FORWARDING_FEE_AVVAL = {
  type: "forwarding_fee",
  id: 6408851,
  name: "Экспедиционный сбор",
  code: "FORWARDING_FEE",
};
//
// export const NOTIFICATION_FEE_AVVAL = {
//   type: "notification_fee",
//   id: 2639752,
//   name: "Уведомление",
//   code: "NOTIFICATION_FEE",
// };

export const INSURANCE_FEE_AVVAL = {
  type: "insurance_fee",
  id: 7185496,
  name: "Страховой сбор",
  code: "INSURANCE_FEE",
};
export const COD_FEE_AVVAL = {
  type: "cod_fee",
  id: 9185496,
  name: "Плата за наложенный платеж",
  code: "COD_FEE",
};
export const avvalServiceTypes = [
  CUSTOMS_FEE_AVVAL,
  FORWARDING_FEE_AVVAL,
  INSURANCE_FEE_AVVAL,
  COD_FEE_AVVAL,
];
export const urlAVVAL = "http://127.0.0.1:2031/app/";
export const serviceId = "14ca2a5f4872973acabe338ffa068325";
export const RefundInfo = {
  TerminalID: "",
  ReceiptSeq: "",
  DateTime: "",
  FiscalSign: "",
};

export const formatPaymentTypeLocalisation = (currentLanguage, type) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          Оплата за отправление принята основываясь на{" "}
          <span style={{ fontWeight: "900", textTransform: "uppercase" }}>
            {type}
          </span>
          . После совершения оплаты тип оплаты изменить нельзя.
        </span>
      );
    case UZBEK_LANGUAGE:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          Jo'natma uchun to'lov{" "}
          <span style={{ fontWeight: "900", textTransform: "uppercase" }}>
            {type}
          </span>{" "}
          asosida qabul qilingan. To'lov amalga oshirilgandan so’ng to'lov
          turini o'zgartirish mumkin emas.
        </span>
      );
    default:
      return (
        <span
          style={{
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "170%",
            letterSpacing: "0.15px",
            textAlign: "justify",
          }}
        >
          The payment for the shipment has been accepted by{" "}
          <span style={{ fontWeight: "900", textTransform: "uppercase" }}>
            {type}
          </span>
          , the payment type cannot be changed after payment.
        </span>
      );
  }
};

export const cardType = ["PRIVATE", "CORPORATIVE"];
export const creatPostData = (
  isYandexMap,
  { paymentType, paymentType2, ...values },
  withoutPay,
) => {
  const showCOD =
    _.get(values, "corporate", false) &&
    _.get(values, "senderName.can_create_cod_order", false);
  const totalArray =
    values.contentItems && values.contentItems.length
      ? values.contentItems.map(item => item.total)
      : [];

  const cod = showCOD
    ? totalArray && totalArray.length
      ? totalArray.reduce((acc, current) => acc + current)
      : 0
    : values.cod;

  return isYandexMap
    ? {
        product_categories: values.productCategories,
        card_type: values.cardType,
        ppt_id: values.pptId,
        valuable: values.valuable,
        nature_type: values.nature_type,
        without_pay: withoutPay,
        id: values.id,
        parcel_value: values.parcelValue,
        content_items: showCOD
          ? values.contentItems.map(item => ({
              ...item,
              currency: item.currency || UZB_CURRENCY,
            }))
          : values.contentItems.map(item => ({
              ...item,
              currency: item.currency || UZB_CURRENCY,
              net_weight: _.divide(item.net_weight, 1000),
            })),
        corporate: values.corporate,
        notification_enabled: values.notificationEnabled,
        notification: {
          enabled: values.notificationEnabled,
          barcode: values.notificationBarcode,
        },
        barcode: values.barcode && _.toUpper(values.barcode),
        payment_type: paymentType,
        payment_type_additional: paymentType2,
        transportation_type: _.get(values.package, "transportation_type"),
        package_type: {
          id: _.get(values.package, "id"),
          package_price: {
            id: _.get(values.package, "price.id"),
          },
        },
        charge_items: values.addedServices
          .map(v => ({
            paid: freePayTypes.includes(paymentType),
            charge_type: SERVICE_ADDED,
            additional_service_id: v.id,
            quantity: v.quantity,
            charge: v[showedPrice(values.corporate)],
            card_type: values.cardType2,
            ppt_id: values.pptId2,
            payment_type:
              paymentType2 || values[`addedServices${v.id}`] || paymentType,
          }))
          .concat([
            {
              charge_type: STAMP,
              charge: values.paidWithStamps,
              paid: true,
              payment_type: NON_PAYMENT,
            },
            {
              charge_type: CUSTOMS_FEE,
              charge: values.customsFee,
              payment_type: paymentType2 || paymentType,
            },
            {
              charge_type: NOTIFICATION_FEE,
              charge: values.notificationFee,
              payment_type: paymentType,
            },
            {
              charge_type: FORWARDING_FEE,
              charge: values.forwardingFee,
              payment_type: paymentType2 || paymentType,
            },
            {
              charge_type: COD_FEE,
              charge: values.codFee,
              payment_type: paymentType2 || paymentType,
            },
            {
              charge_type: INSURANCE_FEE,
              charge: _.multiply(toFinite(values.parcelValue), 0.05),
              payment_type: paymentType2 || paymentType,
            },
            {
              charge_type: COD,
              charge: cod,
            },
          ]),
        sender_data: {
          jurisdiction: values.senderJurisdiction,
          customer: values.senderName,
          phone: values.senderPhone,
          address: values.senderLocation.address,
          lat: values.senderLocation.lat,
          lon: values.senderLocation.lng,
          address_type:
            values.senderAddressLocationType === GEO ? RESIDENTIAL : BUSINESS,
          postcode: values.senderPostcode,
        },
        recipient_data: {
          jurisdiction: values.recipientJurisdiction,
          customer: values.recipientName,
          phone: values.recipientPhone,
          address: values.recipientLocation.address,
          lat: values.recipientLocation.lat,
          lon: values.recipientLocation.lng,
          address_type:
            values.recipientAddressLocationType === GEO
              ? RESIDENTIAL
              : BUSINESS,
          postcode: values.recipientPostcode,
        },
        dimensions: {
          width: values.width,
          length: values.length,
          height: values.height,
          weight: _.divide(values.weight, 1000),
        },
      }
    : {
        product_categories: values.productCategories,
        card_type: values.cardType,
        ppt_id: values.pptId,
        valuable: values.valuable,
        nature_type: values.nature_type,
        without_pay: withoutPay,
        id: values.id,
        parcel_value: values.parcelValue,
        content_items: showCOD
          ? values.contentItems.map(item => ({
              ...item,
              currency: item.currency || UZB_CURRENCY,
            }))
          : values.contentItems.map(item => ({
              ...item,
              currency: item.currency || UZB_CURRENCY,
              net_weight: _.divide(item.net_weight, 1000),
            })),
        corporate: values.corporate,
        notification_enabled: values.notificationEnabled,
        notification: {
          enabled: values.notificationEnabled,
          barcode: values.notificationBarcode,
        },
        barcode: values.barcode && _.toUpper(values.barcode),
        payment_type: paymentType,
        payment_type_additional: paymentType2,
        transportation_type: _.get(values.package, "transportation_type"),
        package_type: {
          id: _.get(values.package, "id"),
          package_price: {
            id: _.get(values.package, "price.id"),
          },
        },
        charge_items: values.addedServices
          .map(v => ({
            charge_type: SERVICE_ADDED,
            additional_service_id: v.id,
            quantity: v.quantity,
            paid: freePayTypes.includes(paymentType),
            charge: v[showedPrice(values.corporate)],
            card_type: values.cardType2,
            ppt_id: values.pptId2,
            payment_type:
              paymentType2 || values[`addedServices${v.id}`] || paymentType,
          }))
          .concat([
            {
              charge_type: STAMP,
              charge: values.paidWithStamps,
              paid: true,
              payment_type: NON_PAYMENT,
            },
            {
              charge_type: CUSTOMS_FEE,
              charge: values.customsFee,
              payment_type: paymentType2 || paymentType,
            },
            {
              charge_type: NOTIFICATION_FEE,
              charge: values.notificationFee,
              payment_type: paymentType,
            },
            {
              charge_type: FORWARDING_FEE,
              charge: values.forwardingFee,
              payment_type: paymentType2 || paymentType,
            },
            {
              charge_type: COD_FEE,
              charge: values.codFee,
              payment_type: paymentType2 || paymentType,
            },
            {
              charge_type: INSURANCE_FEE,
              charge: _.multiply(toFinite(values.parcelValue), 0.05),
              payment_type: paymentType2 || paymentType,
            },
            {
              charge_type: COD,
              charge: cod,
            },
          ]),
        sender_data: {
          jurisdiction: values.senderJurisdiction,
          customer: values.senderName,
          phone: values.senderPhone,
          address: values.senderLocation,
          address_type: values.senderAddressType,
          postcode: values.senderPostcode,
        },
        recipient_data: {
          jurisdiction: values.recipientJurisdiction,
          customer: values.recipientName,
          phone: values.recipientPhone,
          address: values.recipientLocation,
          address_type: values.recipientAddressType,
          postcode: values.recipientPostcode,
        },
        dimensions: {
          width: values.width,
          length: values.length,
          height: values.height,
          weight: _.divide(values.weight, 1000),
        },
      };
};

const getValues = getFormValues("AdminPostForm");

const ContentItems = ({
  contentItems,
  fields,
  getLocalisationMessage,
  change,
}) => {
  const temptariffCodeOptions = [];
  const tempsearchHS = [];

  contentItems.forEach((item, index) => {
    const tariffCode = _.get(item, "tariff_code");

    if (tariffCode) {
      tempsearchHS[index] = {
        loading: false,
        value: tariffCode,
      };

      temptariffCodeOptions[index] = [tariffCode];
    }
  });

  const [tariffCodeOptions, setTariffCodeOptions] = useState([
    ...temptariffCodeOptions,
  ]);

  const [searchHS, setSearchHS] = useState([...tempsearchHS]);
  const [currencies, setCurrencies] = useState(List());

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const findedIndex = searchHS.findIndex(x => x.loading);

      if (findedIndex > -1) {
        const tempSearchHS = [...searchHS];
        const findedElement = searchHS[findedIndex];

        getHSCodes(findedElement.value)
          .then(res => {
            // eslint-disable-next-line lodash/prefer-get
            if (res && res.data && res.data.list) {
              const tempTariffCodeOptions = [...tariffCodeOptions];

              tempTariffCodeOptions[findedIndex] = get(
                res,
                "data.list",
                [],
              ).map(v => _.get(v, "hscode"));

              setTariffCodeOptions(tempTariffCodeOptions);
              findedElement.loading = false;
              tempSearchHS[findedIndex] = findedElement;
              setSearchHS(tempSearchHS);
            } else {
              const temporaryTariffCodeOptions = [...tariffCodeOptions];

              temporaryTariffCodeOptions[findedIndex] = [];
              setTariffCodeOptions(temporaryTariffCodeOptions);

              findedElement.loading = false;
              tempSearchHS[findedIndex] = findedElement;
              setSearchHS(tempSearchHS);
            }
          })
          .catch(error => {
            findedElement.loading = false;
            tempSearchHS[findedIndex] = findedElement;
            setSearchHS(tempSearchHS);

            showErrorMessage(error);
          });
      }
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchHS]);

  useEffect(
    () =>
      getCurrencies()
        .then(res => {
          const tempCurrencies = _.get(res, "data");
          if (_.get(tempCurrencies, "length") > 0) {
            setCurrencies(fromJS(tempCurrencies));
          }
        })
        .catch(error => showErrorMessage(error)),
    [],
  );

  return (
    <FlexBox flex={true} direction="column">
      <FlexBox flex={true} direction="column">
        {fields.map((item, index) => (
          <FlexBox key={index} gutter={8} style={{ marginBottom: ".25rem" }}>
            <FlexBox flex={true} direction="column">
              {index === 0 && (
                <h6>{getLocalisationMessage("nomination", "Nomination")}</h6>
              )}
              <FormTextField
                immediatelyShowError={true}
                fullWidth={true}
                name={`${item}.name`}
                onChange={(e, value) => {
                  const tempSearchHS = [...searchHS];
                  tempSearchHS[index] = { loading: true, value };
                  setSearchHS(tempSearchHS);
                  change(`${item}.tariff_code`, null);
                }}
                validate={createNotFalsyValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox direction="column">
              {index === 0 && (
                <h6>
                  {getLocalisationMessage(
                    "hs_tariff_number",
                    "HS tariff number",
                  )}
                </h6>
              )}
              <FlexBox flex={true}>
                <FormSelectField
                  autoWidth={true}
                  fullWidth={true}
                  immediatelyShowError={true}
                  loading={searchHS[index] && searchHS[index].loading}
                  name={`${item}.tariff_code`}
                  options={
                    tariffCodeOptions[index] &&
                    tariffCodeOptions[index].length > 0
                      ? fromJS(tariffCodeOptions[index])
                      : List()
                  }
                  disabled={
                    Boolean(searchHS[index] && searchHS[index].loading) ||
                    Boolean(
                      !tariffCodeOptions[index] ||
                        tariffCodeOptions[index].length < 1,
                    )
                  }
                  validate={tariffCodeValidator(
                    getLocalisationMessage("this_field_is_required"),
                  )}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} direction="column">
              {index === 0 && (
                <h6>
                  {getLocalisationMessage(
                    "country_of_origin",
                    "Country of origin",
                  )}
                </h6>
              )}
              <FormJMAutoComplete
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((v, i, arr) =>
                        i === arr.length - 1 ? v.name : `${v.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                fullWidth={true}
                name={`${item}.origin_country`}
              />
            </FlexBox>
            <FlexBox direction="column">
              {index === 0 && (
                <h6>{getLocalisationMessage("quantity", "Quantity")}</h6>
              )}
              <FormTextField
                fullWidth={true}
                name={`${item}.qty`}
                type="number"
                onFocus={event => {
                  event.target.select();
                }}
                parse={value => {
                  if (value <= 0) {
                    return 0;
                  }

                  return String(Math.round(parseFloat(String(value))));
                }}
              />
            </FlexBox>
            <FlexBox direction="column">
              {index === 0 && (
                <h6>{getLocalisationMessage("weight_g", "Weight (g)")}</h6>
              )}
              <FormTextField
                fullWidth={true}
                name={`${item}.net_weight`}
                type="number"
                onFocus={event => {
                  event.target.select();
                }}
                parse={value => {
                  if (value <= 0) {
                    return 0;
                  }

                  return String(Math.round(parseFloat(String(value))));
                }}
              />
            </FlexBox>
            <FlexBox direction="column">
              {index === 0 && (
                <h6>{getLocalisationMessage("price", "Price")}</h6>
              )}
              <FormTextField
                immediatelyShowError={true}
                fullWidth={true}
                name={`${item}.price`}
                type="number"
                onFocus={event => {
                  event.target.select();
                }}
                parse={value => {
                  if (value <= 0) {
                    return 0;
                  }

                  return String(Math.round(parseFloat(String(value))));
                }}
                validate={createNotNullValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
              />
            </FlexBox>
            <FlexBox flex={true} direction="column">
              {index === 0 && <h6>{getLocalisationMessage("currency")}</h6>}
              <FormCurrencyAutoComplete
                immediatelyShowError={true}
                name={`${item}.currency`}
                fullWidth={true}
                ignoreTextField={true}
                hintText={getLocalisationMessage("type_to_search")}
                validate={createNotEmptyValidator(
                  getLocalisationMessage("this_field_is_required"),
                )}
              />
            </FlexBox>
            {2 === 3 && (
              <FlexBox direction="column">
                {index === 0 && <h6>{getLocalisationMessage("currency")}</h6>}
                <FormSelectField
                  immediatelyShowError={true}
                  autoWidth={true}
                  name={`${item}.currency2`}
                  label={getLocalisationMessage("currency")}
                  options={currencies}
                  formatOption={x => x.get("name")}
                  validate={createNotEmptyValidator(
                    getLocalisationMessage("this_field_is_required"),
                  )}
                />
              </FlexBox>
            )}
            <FlexBox
              align={ALIGN_CENTER}
              style={{ paddingTop: index === 0 && "2rem" }}
            >
              <IconButton size="small" onClick={() => fields.remove(index)}>
                <Close color="error" />
              </IconButton>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>

      <FlexBox
        style={{ marginTop: ".5rem" }}
        justify={JUSTIFY_START}
        align={ALIGN_CENTER}
      >
        <CustomButton
          variant={OUTLINED}
          onClick={() =>
            fields.push({
              name: "",
              qty: 1,
              net_weight: 0,
              price: 0,
              origin_country: JURISDICTION_UZBEKISTAN,
              currency: UZB_CURRENCY,
            })
          }
          startIcon={<AddCircleOutline />}
        >
          {getLocalisationMessage("add_more", "Add more")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
};
ContentItems.propTypes = {
  fields: PropTypes.object,
  contentItems: PropTypes.array,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
};

const useStyles = makeStyles({
  rightBorder: {
    paddingRight: "1rem",
    borderRight: "1px solid #EEEEEE",
    color: "rgba(38, 50, 56, 0.7)",
  },
  rightBorder2: {
    padding: "0 1rem",
    borderRight: "1px solid #EEEEEE",
    color: "rgba(38, 50, 56, 0.7)",
    textTransform: "capitalize",
  },
  titleStyle: {
    color: "rgba(38, 50, 56, 0.7)",
  },
  padding: {
    padding: "0 .5rem",
    color: "rgba(38, 50, 56, 0.7)",
  },
  fullWidth: {
    flex: "0 1 auto",
    maxWidth: "68%",
    textTransform: "capitalize",
  },
  price: {
    flex: "0 1 auto",
    paddingLeft: ".5rem",
  },
  dots: {
    clear: "both",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    flex: 1,
    padding: "0 .5rem",
  },
  content: {
    minWidth: "1200px",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  titleWithBorder: {
    borderBottom: "1px solid #eeeeee",
    paddingTop: 0,
  },
  titleWithBorder2: {
    borderBottom: "1px solid #eeeeee",
  },
  card: {
    padding: "1rem",
  },
  btn: {
    flex: "1 1 auto",
    margin: "0 .5rem",
  },
  btnWrapper: {
    margin: "0 -.5rem",
  },
  changeSize: {
    cursor: "pointer",
    color: button,
  },
  sizesParent: {
    marginTop: 16,
    "&>div": {
      flex: "1 1 auto",
      margin: "0 .25rem",
    },
    "&>:first-child": {
      margin: "0 .25rem 0 0",
    },
    "&>:last-child": {
      margin: "0 0 0 .25rem",
    },
  },
  h5: {
    color: "rgba(38, 50, 56, 0.7)",
    fontsize: "1rem",
    margin: ".25rem 0",
  },
  tab: {
    padding: 0,
    fontSize: "13px",
    marginLeft: "12px",
    marginRight: "12px",
    color: button,
    border: "none",
    outline: "none",
    cursor: "pointer",
    background: "none",
    fontFamily: "Blogger Sans",
    textTransform: "uppercase",
    transition: `color 250ms ease`,
    "&:hover": {
      color: button,
      textDecoration: "none",
    },
  },
  jmIcon: {
    border: "1px solid #bdbdbd",
    borderLeft: "none",
    borderRadius: 0,
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  jmWrapper: {
    paddingRight: "0 !important",
    overflow: "hidden",
  },
  weightAnimationPlay: {
    animationPlayState: "paused",
  },
  weightAnimationStop: {
    animationPlayState: "running",
  },
  oneLine: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  weightAnimation: {
    "&>div>fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      animationName: "$flicker",
      animationDuration: "250ms",

      animationIterationCount: "5",
      animationTimingFunction: "ease",
    },
  },
  "@keyframes flicker": {
    from: {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    to: {
      borderColor: lightGreen.A400,
    },
  },

  searchTypeIndex: {
    width: 150,
    "@media (min-width: 1440px)": {
      width: 220,
    },
    "@media (max-width: 991px)": {
      width: 120,
    },
  },
});
const enhancer = compose(
  connect(
    state => ({
      userLanguage: getUserLanguage(state),
      userName: getUserName(state),
      userIdAVVAL: getAVVALUserId(state),
      isUsingScale: getIsUsingScale(state),
      mapProvider: getMapProvider(state),
      hasEditCostPermission: hasUserPermission(state, "ADMIN_ORDER_COST_EDIT"),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(({ order = fromJS({}), ...props }) => {
        const addedServices = [];
        let chargeItems = order.get("charge_items");
        let lastTotalSum = 0;
        if (chargeItems) {
          chargeItems.forEach(v => {
            if (v.get("charge_type") === TOTAL) {
              lastTotalSum = order.get("paid") ? v.get("charge") : 0;
            }
            if (v.get("charge_type") === SERVICE_ADDED) {
              const service = toJS(v);
              addedServices.push({
                ...service,
                id: service.additional_service_id,
              });
            }
          });
          chargeItems = order.get("charge_items")
            ? order
                .get("charge_items")
                .toMap()
                .mapEntries(([, v]) => [v.get("charge_type"), v])
            : Map();
        }
        const corporate = order.get("corporate")
          ? order.get("corporate")
          : order.getIn(["customer", "merchant_type"]) === MERCHANTS;
        const isYandexMap = props.mapProvider === YANDEX_MAP_PROVIDER;
        const notWeight =
          corporate && order.getIn(["customer", "can_create_cod_order"]);
        const senderPhone = getValue(order, ["sender_data", "phone"]);
        let contentItems = toJS(order.get("content_items")) || [];
        if (contentItems.length > 0)
          contentItems = notWeight
            ? contentItems.map(item => ({
                name: item.name,
                spic: item.spic,
                price: item.price,
                service_id: item.service_id,
                package_code: item.package_code,
                barcode: item.barcode,
                qty: item.qty,
                vat_percentage: item.vat_percentage,
                commission_tin: item.commission_tin,
                vat_total:
                  item.vat_percentage > 0
                    ? parseFloat(
                        (Number(item.qty) *
                          Number(item.price) *
                          (Number(item.vat_percentage) / 100)) /
                          1.12,
                      ).toFixed(2)
                    : 0,
                total: Number(item.qty) * Number(item.price),
              }))
            : contentItems.map(item => ({
                ...item,
                net_weight: _.multiply(item.net_weight, 1000),
              }));

        const productCategories = toJS(order.get("product_categories")) || [];

        const pptId2 =
          addedServices && addedServices.length > 0
            ? addedServices[0].ppt_id
            : null;
        const cardType2 =
          addedServices && addedServices.length > 0
            ? addedServices[0].card_type
            : null;

        const contentItemsSum = contentItems.reduce(reducerCIS, 0);
        const paymentType = order.get("payment_type");
        return {
          isPaid: order.get("paid"),
          cardType2,
          pptId2,
          paymentType2: order.get("payment_type_additional"),
          avval_receipt: order.get("avval_receipt"),
          lastTotalSum,
          addedServices,
          isCISPriority: contentItemsSum === order.get("parcel_value"),
          contentItems,
          productCategories,
          id: order.get("id"),
          serviceType: toJS(order.get("service_type")),
          valuable: toJS(order.get("valuable")),
          nature_type: order.get("nature_type"),
          corporate,
          notificationEnabled: order.getIn(["notification", "enabled"]),
          barcode: order.get("barcode"),
          notificationBarcode: order.getIn(["notification", "barcode"]),
          transportationType: order.getIn([
            "package_type",
            "transportation_type",
          ]),
          cardType: order.get("card_type"),
          pptId: order.get("ppt_id"),
          reference_id: order.get("reference_id"),
          package: toJS(order.get("package_type")),
          paymentType,
          cod: getValue(chargeItems, [COD, "charge"]),
          notificationFee: getValue(chargeItems, [NOTIFICATION_FEE, "charge"]),
          customsFee: getValue(chargeItems, [CUSTOMS_FEE, "charge"]),
          forwardingFee: getValue(chargeItems, [FORWARDING_FEE, "charge"]),
          codFee: getValue(chargeItems, [COD_FEE, "charge"]),
          paidWithStamps: getValue(chargeItems, [STAMP, "charge"]),
          parcelValue: order.get("parcel_value"),
          senderName: {
            ...toJS(getValue(order, ["sender_data", "customer"])),
            name: getValue(order, ["sender_data", "name"]),
            can_create_cod_order: getValue(order, [
              "customer",
              "can_create_cod_order",
            ]),
          },
          senderLocation: isYandexMap
            ? {
                address: getValue(order, ["sender_data", "address"]),
                lat: getValue(order, ["sender_data", "lat"]),
                lng:
                  getValue(order, ["sender_data", "lon"]) ||
                  getValue(order, ["sender_data", "lng"]),
              }
            : getValue(order, ["sender_data", "address"]),
          senderJurisdiction:
            toJS(getValue(order, ["sender_data", "jurisdiction"])) ||
            toJS(props.userJurisdiction),
          senderPostcode:
            fp.get(["sender_data", "postcode"], toJS(order)) ||
            props.senderPostcode,
          senderAddressType:
            getValue(order, ["sender_data", "address_type"]) || RESIDENTIAL,
          senderAddressLocationType:
            getValue(order, ["sender_data", "address_type"]) === BUSINESS
              ? "biz"
              : "geo",
          senderPhone,
          recipientName: {
            ...toJS(getValue(order, ["recipient_data", "customer"])),
            name: getValue(order, ["recipient_data", "name"]),
          },
          recipientLocation: isYandexMap
            ? {
                address: getValue(order, ["recipient_data", "address"]),
                lat: getValue(order, ["recipient_data", "lat"]),
                lng:
                  getValue(order, ["recipient_data", "lng"]) ||
                  getValue(order, ["recipient_data", "lon"]),
              }
            : getValue(order, ["recipient_data", "address"]),
          recipientJurisdiction: toJS(
            getValue(order, ["recipient_data", "jurisdiction"]),
          ),
          recipientPostcode:
            fp.get(["recipient_data", "postcode"], toJS(order)) || "",
          recipientAddressType:
            getValue(order, ["recipient_data", "address_type"]) || RESIDENTIAL,
          recipientAddressLocationType:
            getValue(order, ["recipient_data", "address_type"]) === BUSINESS
              ? "biz"
              : "geo",
          recipientPhone: getValue(order, ["recipient_data", "phone"]),
          width: getValue(order, ["dimensions", "width"]),
          length: getValue(order, ["dimensions", "length"]),
          height: getValue(order, ["dimensions", "height"]),
          weight:
            _.multiply(
              toFinite(getValue(order, ["dimensions", "weight"])),
              1000,
            ) || 20,
        };
      })
      .startWith(null);

    const handleSubmit = props => values => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit(
        creatPostData(props.mapProvider === YANDEX_MAP_PROVIDER, values),
      );
    };

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
        onSubmit: handleSubmit(props),
      }),
    );
  }),
  reduxForm({
    enableReinitialize: true,
    form: "AdminPostForm",
    validate: (values, { getLocalisationMessage }) => ({
      senderJurisdiction:
        !fp.get(["senderJurisdiction", "id"], values) &&
        getLocalisationMessage("this_field_is_required"),
      senderName: values.corporate
        ? !get(values, "senderName.id") &&
          getLocalisationMessage("this_field_is_required")
        : get(values, "senderName.name.length", 0) < 1 &&
          getLocalisationMessage("this_field_is_required"),
      senderAddress:
        !values.senderAddress &&
        getLocalisationMessage("this_field_is_required"),
      senderPhone:
        (!values.senderPhone || values.senderPhone.length < 8) &&
        getLocalisationMessage("this_field_is_required"),
      senderAddressType:
        !values.senderAddressType &&
        getLocalisationMessage("this_field_is_required"),
      senderPostcode: !values.senderPostcode
        ? getLocalisationMessage("this_field_is_required")
        : fp.get(["senderJurisdiction", "country_code"], values) === UZ &&
          _.get(values, "SPVLevel") === RED &&
          getLocalisationMessage(
            "this_zip_code_does_not_belong_to_the_specified_jurisdiction",
          ),
      recipientPostcode: !values.recipientPostcode
        ? getLocalisationMessage("this_field_is_required")
        : fp.get(["recipientJurisdiction", "country_code"], values) === UZ &&
          _.get(values, "RPVLevel") === RED &&
          getLocalisationMessage(
            "this_zip_code_does_not_belong_to_the_specified_jurisdiction",
          ),
      recipientJurisdiction:
        !fp.get(["recipientJurisdiction", "id"], values) &&
        getLocalisationMessage("this_field_is_required"),
      recipientName:
        get(values, "recipientName.name.length", 0) < 1 &&
        getLocalisationMessage("this_field_is_required"),
      recipientAddress:
        !values.recipientAddress &&
        getLocalisationMessage("this_field_is_required"),
      recipientPhone:
        (!values.recipientPhone || values.recipientPhone.length < 8) &&
        getLocalisationMessage("this_field_is_required"),
      recipientAddressType:
        !values.recipientAddressType &&
        getLocalisationMessage("this_field_is_required"),
      package:
        !getValue(values.package, "id") &&
        getLocalisationMessage("this_field_is_required"),
      paymentType:
        !values.paymentType && getLocalisationMessage("this_field_is_required"),
      paymentType2:
        !values.paymentType2 &&
        getLocalisationMessage("this_field_is_required"),
      barcode: values.barcode && _.get(values, "barcodeErrorMessage"),
      notificationBarcode:
        values.barcode &&
        values.notificationBarcode === values.barcode &&
        getLocalisationMessage("barcode_must_be_unique"),
      weight:
        _.get(values, "contentItemsWeight") > 0
          ? _.get(values, "contentItemsWeight") > values.weight &&
            "Указанный вес меньше чем у опись"
          : values.weight < 0 &&
            getLocalisationMessage("this_field_is_required"),
      contentItems:
        values.recipientJurisdiction &&
        values.recipientJurisdiction.country_code !== UZ &&
        !values.contentItems.length &&
        getLocalisationMessage("this_field_is_required"),
      parcelValue:
        !values.parcelValue &&
        values.valuable &&
        getLocalisationMessage("this_field_is_required"),
      cardType:
        values.paymentType === CARD &&
        !values.cardType &&
        getLocalisationMessage("this_field_is_required"),
      pptId:
        values.paymentType === CARD &&
        !values.pptId &&
        getLocalisationMessage("this_field_is_required"),
      cardType2:
        values.paymentType2 === CARD &&
        !values.cardType2 &&
        getLocalisationMessage("this_field_is_required"),
      pptId2:
        values.paymentType2 === CARD &&
        !values.pptId2 &&
        getLocalisationMessage("this_field_is_required"),
    }),
  }),
  connect(state => ({
    values: getValues(state),
  })),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

// let port;
// let reader;
// let writer;

const AdminPostForm = ({
  change,
  edit,
  getLocalisationMessage,
  handleSubmit,
  initialValues,
  isLoading,
  orderId,
  orderBarcode,
  resMessage,
  setIsLoading,
  setLocation,
  setResMessage,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  untouch,
  userName,
  userWarehouseName,
  valid,
  values = {},
  mapProvider,
  // isUsingScale,
  userLanguage,
  userIdAVVAL,
  order,
  hasEditCostPermission,
  printReceipt,
}) => {
  // variables
  const classes = useStyles();
  const {
    senderAddressLocationType,
    recipientAddressLocationType,
    senderJurisdiction,
    corporate,
    recipientJurisdiction,
    customsFee = 0,
    notificationFee = 0,
    forwardingFee = 0,
    codFee = 0,
    parcelValue,
    paidWithStamps = 0,
    weight = 0,
    contentItemsWeight = 0,
    contentItems = [],
    productCategories = [],
    addedServices = [],
    notPaidPosts = [],
    senderPhone,
    recipientPhone,
    lastTotalSum,
    barcode,
    paymentType,
    notificationEnabled,
    isPackageTelegram,
    senderName,
    serviceType,
    senderPostcode,
    recipientPostcode,
    paymentType2,
    checkID = 0,
    valuable,
    isPaid,
    senderLocation,
    recipientLocation,
    notificationBarcode,
  } = values;

  const isYandexMap = mapProvider === YANDEX_MAP_PROVIDER;
  const packageId = _.get(values.package, "id");
  const packageName = _.get(values.package, "name");
  const packagePrice = _.get(values.package, "price");
  const packagePriceId = _.get(values.package, "price.id");
  const packagePriceTotal = toFinite(_.get(packagePrice, "total", 0));
  const isNotPaidMode = notPaidPosts.length > 0;
  const senderCountryCode = getValue(senderJurisdiction, "country_code");
  const recipientCountryCode = getValue(recipientJurisdiction, "country_code");
  const contentItemsLength = contentItems.length;
  const allServicesSum = addedServices.reduce(
    (a, b) =>
      a + _.multiply(b[showedPrice(corporate)], _.get(b, "quantity", 1)),
    0,
  );

  const nalog = _.multiply(toFinite(parcelValue), 0.05);
  const senderJurisdictionId = getValue(senderJurisdiction, "id");
  const recipientJurisdictionId = getValue(recipientJurisdiction, "id");
  const avvalReceipt = _.get(values, "avval_receipt");
  const isFreePayType = freePayTypes.find(
    v => v === paymentType || v === paymentType2,
  );
  // useStates
  const [createdPostId, setCreatedPostId] = useState(orderId);
  const [createdBarcode, setCreatedBarcode] = useState(orderBarcode);
  const [refreshChekId, setRefreshChekId] = useState(false);
  const [paymentTypesIsLoading, setPaymentTypesIsLoading] = useState(false);
  const [isNeedPayWithStamps, setIsNeedPayWithStamps] = useState(false);
  const [isLoadingAVVAL, setIsLoadingAVVAL] = useState(false);
  const [isLoadingConnectingAVVAL, setIsLoadingConnectingAVVAL] = useState(
    false,
  );
  const [isConnectedAVVAL, setIsConnectedAVVAL] = useState(false);
  const [allSum, setAllSum] = useState(0);
  const [differenceSum, setDifferenceSum] = useState(0);
  const isCanEditCost = !isPaid || differenceSum === 0 || hasEditCostPermission;
  const isCanPaymentType =
    edit &&
    (paymentType === order.get("payment_type") || hasEditCostPermission);
  const isCanAdditionalPaymentType =
    edit &&
    (paymentType2 === order.get("payment_type_additional") ||
      hasEditCostPermission);

  const [serviceTypes, setServiceTypes] = useState([]);
  const [packagePrices, setPackagePrices] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [isLoadingCreatWithoutPay, setIsLoadingCreatWithoutPay] = useState(
    false,
  );
  const [isValidatedOpisName, setIsValidatedOpisName] = useState(false);
  const [isValidatedContentItems, setIsValidatedContentItems] = useState(false);
  const [
    isValidatedProductCategories,
    setIsValidatedProductCategories,
  ] = useState(false);
  const [serviceTypesIsLoading, setServiceTypesIsLoading] = useState(false);
  const [packagePricesIsLoading, setPackagePricesIsLoading] = useState(false);
  const [isOpenMultipleSubmit, setIsOpenMultipleSubmit] = useState(false);
  const [isOpenServicesDialog, setIsOpenServicesDialog] = useState(false);
  const [senderPostcodeOptions, setSenderPostcodeOptions] = useState([]);
  const [recipientPostcodeOptions, setRecipientPostcodeOptions] = useState([]);
  const [isOpenSubmitDialog, setIsOpenSubmitDialog] = useState(false);
  const [isOpenBarcodeDialog, setIsOpenBarcodeDialog] = useState(true);
  const [isOpenContentItems, setIsOpenContentItems] = useState(false);
  const [simpleSizeType, setSimpleSizeType] = useState(true);
  const [editSenderJurisdiction, setEditSenderJurisdiction] = useState(false);
  const [isContentItemsNotValid, setIsContentItemsNotValid] = useState(false);
  const [
    isProductCategoriesNotValid,
    setIsProductCategoriesNotValid,
  ] = useState(false);
  const [isOpenCommissionTIN, setIsOpenCommissionTIN] = useState(false);
  const [isOpenProductCategories, setIsOpenProductCategories] = useState(false);
  const [editRecipientJurisdiction, setEditRecipientJurisdiction] = useState(
    false,
  );
  const [senderLocationEdit, setSenderLocationEdit] = useState(edit);
  const [recipientLocationEdit, setRecipientLocationEdit] = useState(edit);
  const [stateAWB, setStateAWB] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: get(recipientJurisdiction, "country_code") === UZ ? AWB_URL : CP72_URL,
  });
  const [stateReceipt, setStateReceipt] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RECEIPT_URL,
  });
  const [zebra, setZebra] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ZEBRA_URL,
  });
  const [stateReceiptText, setStateReceiptText] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RECEIPT_PRINTABLE_URL,
  });
  // useEffects
  useEffect(() => {
    if (orderId) {
      setCreatedPostId(orderId);
    }
  }, [orderId]);
  useEffect(() => {
    if (packageId) {
      if (_.get(values.package, "pay_with_stamp")) {
        setIsNeedPayWithStamps(true);
      } else {
        setIsNeedPayWithStamps(false);
      }
      // eslint-disable-next-line camelcase
      const price_id = _.get(packagePrice, "id");
      setPaymentTypesIsLoading(true);
      getPaymentMethods2(
        corporate
          ? { customerId: getValue(senderName, "id"), price_id }
          : { price_id },
      )
        .then(res => setPaymentTypes(_.get(res, "data")))
        .catch(error => showErrorMessage1(error))
        .finally(() => setPaymentTypesIsLoading(false));
    }
  }, [packagePriceId]);
  useEffect(() => {
    if (userIdAVVAL) {
      setIsLoadingConnectingAVVAL(true);
      fetch(urlAVVAL, {
        method: "POST",
        body: JSON.stringify({
          method: "get-info",
          userId: userIdAVVAL,
          serviceId,
          request: {
            login: "123",
          },
        }),
      })
        .then(() => {
          setIsConnectedAVVAL(true);
        })
        .catch(() => setIsConnectedAVVAL(false))
        .finally(() => setIsLoadingConnectingAVVAL(false));
    }
  }, [userIdAVVAL]);
  useEffect(() => {
    if (!isFreePayType) {
      getCheckId().then(res => change("checkID", _.get(res, "data")));
    }
  }, [refreshChekId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isFreePayType && !checkID) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [checkID]);

  useEffect(() => {
    if (senderJurisdictionId && recipientJurisdictionId) {
      setServiceTypes([]);
      setServiceTypesIsLoading(true);
      const delayDebounceFn = setTimeout(() => {
        getAdminServiceTypes({
          "dimensions.weight": _.divide(getValue(values, "weight"), 1000),
          fromJurisdictionId: getValue(values, ["senderJurisdiction", "id"]),
          toJurisdictionId: getValue(values, ["recipientJurisdiction", "id"]),
          fromPostcodeId: getValue(values, ["senderPostcode", "id"]),
          toPostcodeId: getValue(values, ["recipientPostcode", "id"]),
          customerId: getValue(values.senderName, "id"),
          clientType: values.corporate ? CLIENT_MERCHANT2 : CASH_CLIENT2,
        })
          .then(res => {
            const list = _.get(res, "data");
            const tempServiceTypes = [];
            const xat = list.find(v => _.get(v, "code") === LETTER);
            const banderol = list.find(v => _.get(v, "code") === BANDEROL);
            const pochtaKartasi = list.find(v => _.get(v, "code") === POSTCARD);
            const maydaPaket = list.find(
              v => _.get(v, "code") === SMALL_PACKETS,
            );
            const posilka = list.find(v => _.get(v, "code") === PARCEL);
            const sekogramma = list.find(v => _.get(v, "code") === SEKOGRAMM);
            const telegramma = list.find(v => _.get(v, "code") === TELEGRAM);
            if (xat) {
              tempServiceTypes.push(xat);
              if (!serviceType) change("serviceType", xat);
            }
            if (banderol) tempServiceTypes.push(banderol);
            if (pochtaKartasi) tempServiceTypes.push(pochtaKartasi);
            if (maydaPaket) tempServiceTypes.push(maydaPaket);
            if (posilka) tempServiceTypes.push(posilka);
            if (sekogramma) tempServiceTypes.push(sekogramma);
            if (telegramma) tempServiceTypes.push(telegramma);
            list.forEach(v => {
              const code = _.get(v, "code");
              if (
                code !== LETTER &&
                code !== BANDEROL &&
                code !== POSTCARD &&
                code !== SMALL_PACKETS &&
                code !== PARCEL &&
                code !== SEKOGRAMM &&
                code !== TELEGRAM
              ) {
                tempServiceTypes.push(v);
              }
            });
            setServiceTypes(tempServiceTypes);
          })
          .catch(error => showErrorMessage1(error))
          .finally(() => setServiceTypesIsLoading(false));
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [
    weight,
    senderJurisdiction,
    recipientJurisdiction,
    senderPostcode,
    recipientPostcode,
    senderName,
    corporate,
  ]);
  useEffect(() => {
    if (serviceType && senderJurisdictionId && recipientJurisdictionId) {
      setPackagePrices([]);
      if (!edit) change("package", null);

      setPackagePricesIsLoading(true);
      const delayDebounceFn = setTimeout(() => {
        getCourierPrices2({
          "dimensions.weight": _.divide(getValue(values, "weight"), 1000),
          serviceTypeId: getValue(serviceType, "id"),
          fromJurisdictionId: getValue(values, ["senderJurisdiction", "id"]),
          toJurisdictionId: getValue(values, ["recipientJurisdiction", "id"]),
          fromPostcodeId: getValue(values, ["senderPostcode", "id"]),
          toPostcodeId: getValue(values, ["recipientPostcode", "id"]),
          customerId: getValue(values.senderName, "id"),
          clientType: values.corporate ? CLIENT_MERCHANT2 : CASH_CLIENT2,
          transportationTypes: transportationTypeArray.toString(),
        })
          .then(res => {
            const packageList = _.get(res, "data.list", []);
            setPackagePrices(packageList);
            if (edit) {
              const found = packageList.find(
                v => _.get(v, "price.id") === _.get(values.package, "price.id"),
              );
              if (found) {
                change("package", found);
              }
            }
          })
          .catch(error => showErrorMessage1(error))
          .finally(() => setPackagePricesIsLoading(false));
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [
    weight,
    senderJurisdiction,
    recipientJurisdiction,
    senderPostcode,
    recipientPostcode,
    senderName,
    corporate,
    serviceType,
  ]);

  useEffect(() => {
    if (corporate) {
      change("senderAddressLocationType", BIZ);
    } else {
      change("senderAddressLocationType", GEO);
    }
  }, [corporate]);

  useEffect(() => {
    if (values.package && parcelValue > 0) {
      const forwardingFee2 = fp.get(
        ["price", "forwarding_fee"],
        toJS(values.package),
      );
      if (forwardingFee !== forwardingFee2)
        change("forwardingFee", forwardingFee2);
    }
  }, [parcelValue, values.package]);

  useEffect(
    () =>
      printReceipt &&
      isLoading === SUCCESS &&
      getPDF(
        stateReceiptText,
        setStateReceiptText,
        IS_PRINTING,
        createdPostId,
        showErrorMessage1,
      ),
    [printReceipt, isLoading],
  );

  useEffect(() => {
    if (notificationBarcode) {
      const delayDebounceFn = setTimeout(() => {
        const convertedValue = kirilToLatinByKeyboardPlace(notificationBarcode);
        change("notificationBarcode", convertedValue);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }

    return null;
  }, [notificationBarcode]);

  useEffect(() => {
    if (barcode) setIsOpenBarcodeDialog(false);
    else setIsOpenBarcodeDialog(true);
    if (barcode) {
      const delayDebounceFn = setTimeout(() => {
        const convertedValue = kirilToLatinByKeyboardPlace(barcode);
        const tempBarcode =
          convertedValue !== barcode ? convertedValue : barcode;
        api
          .get(BARCODE_VALIDATION_URL, {
            params: { barcode: tempBarcode, order_id: createdPostId },
          })
          .then(res => {
            if (res.status !== SUCCESS_MESSAGE)
              change("barcodeErrorMessage", res.message);
            else change("barcodeErrorMessage", false);
          })
          .catch(error => {
            showErrorMessage(error);
          })
          .finally(
            () =>
              convertedValue !== barcode && change("barcode", convertedValue),
          );
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    return null;
  }, [barcode]);
  useEffect(() => {
    if (contentItems.length > 0) {
      setIsValidatedOpisName(
        contentItems.filter(v => _.isObject(v.currency) && v.name).length ===
          contentItems.length,
      );
      setIsValidatedContentItems(
        contentItems.filter(v =>
          Boolean(
            v.name &&
              v.spic &&
              v.spic.length === 17 &&
              v.service_id &&
              v.package_code &&
              v.price &&
              v.qty &&
              v.commission_tin &&
              (v.commission_tin.length === 9 || v.commission_tin.length === 14),
          ),
        ).length === contentItems.length,
      );
      const tempcontentItemsWeight = contentItems.reduce(
        (a, b) => a + toFinite(b.net_weight),
        0,
      );
      if (contentItemsWeight !== tempcontentItemsWeight)
        change("contentItemsWeight", tempcontentItemsWeight);
    }
  }, [contentItems]);

  useEffect(() => {
    if (productCategories.length > 0) {
      setIsValidatedProductCategories(
        productCategories.filter(v =>
          Boolean(v.category_type && v.category_type.id && v.count && v.weight),
        ).length === productCategories.length,
      );
    }
  }, [productCategories]);

  useEffect(() => {
    if (get(recipientJurisdiction, "country_code") === UZ) {
      setStateAWB({
        ...stateAWB,
        url: AWB_URL,
      });
    } else {
      setStateAWB({
        ...stateAWB,
        url: CP72_URL,
      });
    }
  }, [get(recipientJurisdiction, "country_code")]);
  useEffect(() => {
    if (
      packageId &&
      recipientJurisdiction &&
      !contentItemsLength &&
      recipientJurisdiction.country_code !== UZ
    ) {
      if (valuable || _.includes([135, 136], packageId))
        setIsContentItemsNotValid(true);
      else setIsContentItemsNotValid(false);
    } else setIsContentItemsNotValid(false);
  }, [packageId, contentItems, valuable, recipientJurisdiction]);

  useEffect(() => {
    if (!(corporate && getValue(senderName, "can_create_cod_order", false))) {
      if (
        packageId &&
        getValue(serviceType, "category", false) &&
        !productCategories.length
      ) {
        setIsProductCategoriesNotValid(true);
      } else setIsProductCategoriesNotValid(false);
    }
  }, [packageId, productCategories, serviceType]);

  useEffect(() => {
    if (paymentType !== CORPORATE_INTERNAL) {
      const tempAllSum =
        packagePriceTotal +
        toFinite(nalog) -
        toFinite(paidWithStamps) +
        toFinite(allServicesSum) +
        toFinite(customsFee) +
        notificationFee +
        toFinite(forwardingFee) +
        toFinite(codFee);
      setAllSum(tempAllSum);
      if (edit) setDifferenceSum(tempAllSum - lastTotalSum);
    }
  }, [
    paymentType,
    packagePriceTotal,
    nalog,
    paidWithStamps,
    allServicesSum,
    customsFee,
    notificationFee,
    forwardingFee,
    codFee,
  ]);
  const setInitialValues = withCurrentSender => {
    const senderKeys = [
      "senderName",
      "senderLocation",
      "senderJurisdiction",
      "senderPostcode",
      "senderAddressType",
      "senderPhone",
      "corporate",
    ];
    let valuesKeys = Object.keys(values);
    if (withCurrentSender)
      valuesKeys = valuesKeys.filter(v => !senderKeys.includes(v));
    valuesKeys.forEach(objectKey => {
      const fieldValue = initialValues[objectKey];
      change(objectKey, fieldValue !== undefined ? fieldValue : null);
      if (objectKey !== "contentItems" || objectKey !== "productCategories")
        untouch(objectKey);
    });
    setIsOpenSubmitDialog(false);
    setIsLoading(NOT_STARTED);
    setResMessage(false);
  };
  const saveNotPaidPost = () => {
    setIsLoadingCreatWithoutPay(true);
    createOrder(creatPostData(isYandexMap, values, true))
      .then(res => {
        if (res.status === "success") {
          change("parcelValue", 0);
          notPaidPosts.push(fp.get("data.id", res));
          change("notPaidPosts", notPaidPosts);
          change("addedServices", []);
          change("productCategories", []);
          showSuccessMessage1(
            getLocalisationMessage(
              "successfully_created",
              "Successfully Created",
            ),
          );
          [
            "recipientName",
            "recipientLocation",
            "barcode",
            "recipientPostcode",
            "recipientPhone",
            "notificationBarcode",
            "contentItems",
            "paymentType2",
          ].forEach(objectKey => {
            const fieldValue = initialValues[objectKey];
            change(objectKey, fieldValue !== undefined ? fieldValue : null);
          });
        } else showErrorMessage1(res.message);
      })
      .catch(error => showErrorMessage1(error))
      .finally(() => setIsLoadingCreatWithoutPay(false));
  };
  const setValidationLevel = (v, valueName, isSender, byLocation) => {
    const postcode = isSender
      ? senderPostcodeOptions.find(option => option.name === v.name)
      : recipientPostcodeOptions.find(option => option.name === v.name);
    if (postcode || byLocation) change(valueName, GREEN);
    else change(valueName, RED);
  };

  useEffect(() => {
    if (
      isYandexMap &&
      !senderLocationEdit &&
      senderLocation &&
      senderLocation.lat &&
      senderLocation.lng
    ) {
      getJurisdictionByLocation({
        lat: senderLocation.lat,
        lng: senderLocation.lng,
      })
        .then(res => {
          if ("data" in res) {
            change("senderJurisdiction", res.data);
          }
        })
        .catch(error => {
          showErrorMessage(error);
        });
    }
  }, [senderLocation]);

  useEffect(() => {
    if (
      isYandexMap &&
      senderLocation &&
      !senderLocationEdit &&
      senderLocation.lat &&
      senderLocation.lng
    ) {
      getPostcodesByLocation({
        lat: senderLocation.lat,
        lng: senderLocation.lng,
        distance: 1000,
      })
        .then(res => {
          if ("data" in res) {
            change("senderPostcode", res.data);
            setValidationLevel(res.data, "SPVLevel", true, true);
          }
        })
        .catch(error => {
          showErrorMessage(error);
        });
    }
  }, [senderLocation]);

  useEffect(() => {
    if (
      isYandexMap &&
      !recipientLocationEdit &&
      recipientLocation &&
      recipientLocation.lat &&
      recipientLocation.lng
    ) {
      getJurisdictionByLocation({
        lat: recipientLocation.lat,
        lng: recipientLocation.lng,
        distance: 1000,
      })
        .then(res => {
          if ("data" in res) {
            change("recipientJurisdiction", res.data);
          }
        })
        .catch(error => {
          showErrorMessage(error);
        });
    }
  }, [recipientLocation]);

  useEffect(() => {
    if (
      isYandexMap &&
      !recipientLocationEdit &&
      recipientLocation &&
      recipientLocation.lat &&
      recipientLocation.lng
    ) {
      getPostcodesByLocation({
        lat: recipientLocation.lat,
        lng: recipientLocation.lng,
      })
        .then(res => {
          if ("data" in res) {
            change("recipientPostcode", res.data);
            setValidationLevel(res.data, "RPVLevel", false, true);
          }
        })
        .catch(error => {
          showErrorMessage(error);
        });
    }
  }, [recipientLocation]);

  /* eslint-enable */
  const createAVVALData = (chNumber, tempCreatedBarcode) => {
    const servicesAvval = [];
    const Items = [];
    const createService = v =>
      servicesAvval.push({
        type: _.get(v, "type"),
        id: _.get(v, "id"),
        name:
          _.get(v, "name") && _.get(v, "name").length
            ? _.get(v, "name").slice(0, 254)
            : "",
      });
    const createRequest = (v, price) =>
      Items.push({
        Name:
          _.get(v, "name", "") && _.get(v, "name", "").length
            ? _.get(v, "name", "").slice(0, 254)
            : "",
        Barcode: _.get(v, "barcode", "$10406001001000000$"),
        Label: _.get(v, "label", ""),
        Spic: _.get(v, "spic", "10105001001000000"),
        Units: _.get(v, "units", 1221),
        Price: _.multiply(_.multiply(price, 100), _.get(v, "quantity", 1) || 1),
        VatPercent: 12,
        PackageCode: "",
        CommissionInfo: {
          TIN: "",
        },
        OwnerType: 2,
        Vat: calculateVAT(price),
        Amount: _.multiply(_.get(v, "quantity", 1) || 1, 1000),
        Discount: _.get(v, "discount", 0),
        Other: _.get(v, "other", 0),
      });
    const tempPackage = toJS(values.package);
    if (codFee) {
      createService(COD_FEE_AVVAL);
      createRequest(COD_FEE_AVVAL);
    }
    if (forwardingFee) {
      createService(FORWARDING_FEE_AVVAL);
      createRequest(FORWARDING_FEE_AVVAL, forwardingFee);
    }
    if (customsFee) {
      createService(CUSTOMS_FEE_AVVAL);
      createRequest(CUSTOMS_FEE_AVVAL, customsFee);
    }
    if (parcelValue) {
      createService(INSURANCE_FEE_AVVAL);
      createRequest(INSURANCE_FEE_AVVAL, nalog);
    }
    if (allServicesSum) {
      addedServices.forEach(v => {
        createService(v);
        createRequest(v, v[showedPrice(corporate)]);
      });
    }
    if (paymentType !== NON_PAYMENT) {
      createService(tempPackage);
      createRequest(tempPackage, packagePriceTotal);
    }
    const convertedToTiyin = _.multiply(allSum, 100);
    const tempPaymentType = paymentType2 || paymentType;
    return {
      method: "sale-services",
      userId: userIdAVVAL,
      serviceId,
      checkID,
      chNumber,
      barcode: tempCreatedBarcode,
      services: servicesAvval,
      request: {
        Items,
        ReceivedCash: tempPaymentType === CASH ? convertedToTiyin : 0,
        ReceivedCard: tempPaymentType === CARD ? convertedToTiyin : 0,
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            values.cardType === "CORPORATIVE"
              ? 1
              : values.cardType === "PRIVATE"
              ? 2
              : values.cardType2 === "CORPORATIVE"
              ? 1
              : values.cardType2 === "PRIVATE"
              ? 2
              : "",
          PPTID: values.pptId || values.pptId2,
        },
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
      },
    };
  };
  const editAVVALData = () => {
    const servicesAvval = [];
    const Items = [];
    const createService = v =>
      servicesAvval.push({
        type:
          _.get(v, "charge_type") === SERVICE
            ? SERVICE_MAIN
            : _.get(v, "charge_type"),
        id: _.get(v, "id"),
        name:
          _.get(v, "name", "") && _.get(v, "name", "").length
            ? _.get(v, "name", "").slice(0, 254)
            : "",
      });
    const createRequest = (v, price) =>
      Items.push({
        Name:
          _.get(v, "name", "") && _.get(v, "name", "").length
            ? _.get(v, "name", "").slice(0, 254)
            : "",
        Barcode: _.get(v, "barcode", "$10406001001000000$"),
        Label: _.get(v, "label", ""),
        Spic: _.get(v, "spic", "10105001001000000"),
        Units: _.get(v, "units", 1221),
        Price: _.multiply(_.multiply(price, 100), _.get(v, "quantity", 1) || 1),
        VatPercent: 12,
        PackageCode: "",
        CommissionInfo: {
          TIN: "",
        },
        OwnerType: 2,
        Vat: calculateVAT(price),
        Amount: _.multiply(_.get(v, "quantity", 1) || 1, 1000),
        Discount: _.get(v, "discount", 0),
        Other: _.get(v, "other", 0),
      });
    const initOrder = toJS(order);
    const chargeItems = _.get(initOrder, "charge_items", []);
    let initPayType = _.get(initOrder, "payment_type_additional");
    if (!initPayType) initPayType = _.get(initOrder, "payment_type");
    const charges = chargeItems
      .filter(
        x =>
          x.charge_type === INSURANCE_FEE ||
          x.charge_type === CUSTOMS_FEE ||
          x.charge_type === FORWARDING_FEE ||
          x.charge_type === SERVICE ||
          x.charge_type === SERVICE_ADDED ||
          x.charge_type === NOTIFICATION_FEE,
      )
      .filter(v => v.charge);
    charges
      .filter(x => x.payment_type === CASH || x.payment_type === CARD)
      .forEach(v => {
        createService(v);
        createRequest(v, v.charge);
      });
    const convertedToTiyin = _.multiply(lastTotalSum, 100);
    return {
      method: "cancel-services",
      userId: userIdAVVAL,
      serviceId,
      checkID,
      chNumber: createdPostId,
      barcode: createdBarcode,
      services: servicesAvval,
      request: {
        Items,
        ReceivedCash: initPayType === CASH ? convertedToTiyin : 0,
        ReceivedCard: initPayType === CARD ? convertedToTiyin : 0,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo: avvalReceipt,
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            values.cardType === "CORPORATIVE"
              ? 1
              : values.cardType === "PRIVATE"
              ? 2
              : values.cardType2 === "CORPORATIVE"
              ? 1
              : values.cardType2 === "PRIVATE"
              ? 2
              : "",
          PPTID: values.pptId || values.pptId2,
        },
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
      },
    };
  };
  const checkAVVALres = response => {
    if (response === null) {
      showErrorMessage1(
        getLocalisationMessage(
          "unexpected_error_occurred_and_payment_failed_please_contact_your_manage_or_try_later",
        ),
      );
      return false;
    } else if (
      response &&
      getValue(response, "resInfo.result.QRCodeURL") === null
    ) {
      showErrorMessage1(
        getLocalisationMessage("the_payment_was_not_successful"),
      );
      return false;
    } else if (_.get(response, "msg") === "error") {
      const errorMessage = `${getLocalisationMessage(
        "error_msg_from_avval",
      )} : ${_.get(response, "resInfo.message")}`;
      showErrorMessage1(errorMessage);
      return false;
    }
    return true;
  };

  const totalArray =
    values.contentItems && values.contentItems.length > 0
      ? values.contentItems.map(item => item.total)
      : [];
  const cod =
    corporate && getValue(senderName, "can_create_cod_order", false)
      ? totalArray && totalArray.length > 0
        ? totalArray.reduce((acc, current) => acc + current)
        : 0
      : values.cod;

  const totalAdditionalArray =
    addedServices && addedServices.length
      ? addedServices.map(item => item.quantity)
      : [];

  const totalAdditionalCount =
    totalAdditionalArray && totalAdditionalArray.length
      ? totalAdditionalArray.reduce((acc, current) => acc + current)
      : 0;

  return (
    <div>
      <PageLoading isLoading={isLoadingCreatWithoutPay} />
      <FlexBox
        element={<form onSubmit={handleSubmit} />}
        direction="column"
        style={{ margin: "0 3rem" }}
      >
        {isOpenMultipleSubmit && (
          <SubmitNotPaidPostsDialog
            getBatchChargeItems={getBatchChargeItems}
            redirectToOrderList={true}
            isDisabledPaymentType={true}
            postIds={notPaidPosts}
            closeDialog={() => setIsOpenMultipleSubmit(false)}
          />
        )}
        <Dialog
          TransitionComponent={TransitionUp}
          open={isOpenServicesDialog}
          fullWidth={true}
          className={classes.content}
          maxWidth="lg"
          onClose={() => setIsOpenServicesDialog(false)}
        >
          <DialogTitle className={classes.titleWithBorder2}>
            {getLocalisationMessage("add_services", "Add services")}
          </DialogTitle>
          <DialogContent>
            <FlexBox direction="column" flex={true}>
              <FlexBox flex={true} style={{ marginRight: "2rem" }}>
                <SimpleAutocomplete
                  isAddedServices={true}
                  fullWidth={true}
                  filters={{
                    page: 0,
                    size: 20,
                    active: true,
                  }}
                  chooseData="data.list"
                  label={getLocalisationMessage("service")}
                  getById={getCachedPostServices}
                  chooseAPI={getPostServicesPredictions2}
                  renderOption={v =>
                    `${_.get(v, "name")} - ${_.get(v, [
                      showedPrice(corporate),
                    ])}${getLocalisationMessage("som")}`
                  }
                  onChange={v => {
                    if (
                      v &&
                      v.id &&
                      !addedServices.some(item => item.id === v.id)
                    ) {
                      change("addedServices", [
                        ...addedServices,
                        { ...v, quantity: 1 },
                      ]);
                    }
                  }}
                />
              </FlexBox>
              {addedServices.map(v => (
                <FlexBox
                  style={{ marginTop: "1rem" }}
                  align={ALIGN_CENTER}
                  justify={JUSTIFY_CENTER}
                  flex={true}
                  key={v.id}
                >
                  <span className={classes.fullWidth}>{v.name}</span>
                  <span className={classes.dots}>{dots}</span>
                  <span className={classes.price}>
                    {money(v[showedPrice(corporate)]) +
                      getLocalisationMessage("som", "so'm")}
                  </span>
                  <strong>&nbsp; x &nbsp;</strong>
                  <IconButton
                    onClick={() =>
                      change(
                        "addedServices",
                        addedServices.map(item =>
                          item.id === v.id
                            ? { ...v, quantity: _.get(v, "quantity", 1) + 1 }
                            : item,
                        ),
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Add />
                  </IconButton>
                  <strong>{_.get(v, "quantity", 1)}</strong>
                  <IconButton
                    disabled={_.get(v, "quantity", 1) < 2}
                    onClick={() =>
                      change(
                        "addedServices",
                        addedServices.map(item =>
                          item.id === v.id
                            ? { ...v, quantity: _.get(v, "quantity", 1) - 1 }
                            : item,
                        ),
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Remove />
                  </IconButton>
                  <strong>
                    &nbsp;=&nbsp;
                    {money(
                      _.multiply(
                        v[showedPrice(corporate)],
                        _.get(v, "quantity", 1),
                      ),
                    ) + getLocalisationMessage("som", "so'm")}
                  </strong>
                  <IconButton
                    onClick={() => {
                      change(
                        "addedServices",
                        addedServices.filter(item => item.id !== v.id),
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Delete />
                  </IconButton>
                </FlexBox>
              ))}
            </FlexBox>
          </DialogContent>
          <DialogActions>
            <FlexBox
              flex={true}
              justify={JUSTIFY_CENTER}
              style={{ paddingBottom: "1rem" }}
            >
              <FlexBox className={classes.btnWrapper}>
                <CustomButton
                  className={classes.btn}
                  onClick={() => setIsOpenServicesDialog(false)}
                  startIcon={<KeyboardArrowLeft />}
                  variant={OUTLINED}
                  color={SECONDARY}
                >
                  {getLocalisationMessage("close_window", "Close window")}
                </CustomButton>
              </FlexBox>
            </FlexBox>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={TransitionUp}
          open={isOpenContentItems}
          fullWidth={true}
          className={classes.content}
          maxWidth="xl"
        >
          <DialogTitle className={classes.titleWithBorder2}>
            <FlexBox flex={true} justify={JUSTIFY_SPACE_AROUND}>
              <span>
                {getLocalisationMessage("add_inventory", "Add inventory")}
              </span>
              <FormSelectField
                defaultValue={SALE_OF_GOODS}
                autoWidth={true}
                name="nature_type"
                label={getLocalisationMessage("opis_type")}
                options={fromJS([
                  SALE_OF_GOODS,
                  RETURNED_GOODS,
                  GIFT,
                  COMMERCIAL_SAMPLE,
                  DOCUMENTS,
                  MIXED_CONTENT,
                  OTHER,
                ])}
                formatOption={x => getLocalisationMessage(x, formatText(x))}
              />
            </FlexBox>
          </DialogTitle>
          <DialogContent>
            <FlexBox direction="column" flex={true}>
              <FieldArray
                name="contentItems"
                props={{
                  getLocalisationMessage,
                  contentItems,
                  change,
                }}
                component={ContentItems}
              />
            </FlexBox>
          </DialogContent>
          <DialogActions>
            {contentItemsWeight > weight ? (
              <FlexBox
                flex={true}
                justify={JUSTIFY_CENTER}
                align={ALIGN_CENTER}
                style={{ paddingBottom: "1rem" }}
                direction="column"
              >
                <h6 style={{ color: "#f57c00", marginRight: "1rem" }}>
                  {getLocalisationMessage(
                    "the_total_weight_of_the_contents_is_greater_than_the_weight_of_the_item_itself",
                  )}
                </h6>
                <FlexBox className={classes.btnWrapper}>
                  <CustomButton
                    className={classes.btn}
                    onClick={() => {
                      if (isValidatedOpisName) {
                        change("weight", contentItemsWeight);
                        setIsOpenContentItems(false);
                      }
                    }}
                    variant={OUTLINED}
                    color={SECONDARY}
                  >
                    {`${getLocalisationMessage(
                      "edit_weight_to",
                    )} ${contentItemsWeight}-${getLocalisationMessage(
                      "g",
                    )} ${getLocalisationMessage(
                      "and",
                    )} ${getLocalisationMessage("close_window")}`}
                  </CustomButton>
                </FlexBox>
              </FlexBox>
            ) : (
              <FlexBox
                flex={true}
                justify={JUSTIFY_CENTER}
                style={{ paddingBottom: "1rem" }}
              >
                <FlexBox className={classes.btnWrapper}>
                  <CustomButton
                    className={classes.btn}
                    onClick={() => {
                      change("contentItems", []);
                      change("nature_type", null);
                      setIsOpenContentItems(false);
                    }}
                    startIcon={<DeleteSweep />}
                    variant={OUTLINED}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage("clear_and_close")}
                  </CustomButton>
                  <CustomButton
                    disabled={contentItemsWeight > weight}
                    className={classes.btn}
                    onClick={() =>
                      isValidatedOpisName && setIsOpenContentItems(false)
                    }
                    endIcon={<SystemUpdateAlt />}
                    variant={OUTLINED}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage("save")}
                  </CustomButton>
                </FlexBox>
              </FlexBox>
            )}
          </DialogActions>
        </Dialog>

        <CommissionTINDialog
          classes={classes}
          contentItems={contentItems}
          change={change}
          getLocalisationMessage={getLocalisationMessage}
          showErrorMessage={showErrorMessage1}
          isOpen={isOpenCommissionTIN}
          setIsOpen={setIsOpenCommissionTIN}
          isValidatedContentItems={isValidatedContentItems}
        />

        <ProductCategoryDialog
          classes={classes}
          productCategories={productCategories}
          change={change}
          getLocalisationMessage={getLocalisationMessage}
          showErrorMessage={showErrorMessage1}
          isOpen={isOpenProductCategories}
          setIsOpen={setIsOpenProductCategories}
          isValidatedProductCategories={isValidatedProductCategories}
        />
        <Dialog
          TransitionComponent={TransitionUp}
          open={isOpenSubmitDialog}
          fullWidth={true}
          className={classes.content}
          maxWidth="xl"
        >
          {isOpenBarcodeDialog ? (
            <React.Fragment>
              <DialogTitle className={classes.titleWithBorder2}>
                <FlexBox direction="column" align={ALIGN_CENTER}>
                  <h2 style={{ fontWeight: "bold" }}>
                    {getLocalisationMessage(
                      "we_remind_you_that_you_have_not_entered_a_barcode",
                      "We remind you that you have not entered a barcode",
                    )}
                  </h2>
                </FlexBox>
              </DialogTitle>
              <DialogActions>
                <FlexBox
                  flex={true}
                  justify={JUSTIFY_CENTER}
                  style={{ paddingBottom: "1rem" }}
                >
                  <FlexBox className={classes.btnWrapper}>
                    <CustomButton
                      className={classes.btn}
                      onClick={() => setIsOpenSubmitDialog(false)}
                      startIcon={<KeyboardArrowLeft />}
                      variant={OUTLINED}
                      color={SECONDARY}
                    >
                      {getLocalisationMessage("back", "Back")}
                    </CustomButton>
                    <CustomButton
                      className={classes.btn}
                      onClick={() => setIsOpenBarcodeDialog(false)}
                      startIcon={<KeyboardArrowRight />}
                      variant={OUTLINED}
                      color={SECONDARY}
                    >
                      {getLocalisationMessage("get_paid", "Get paid")}
                    </CustomButton>
                  </FlexBox>
                </FlexBox>
              </DialogActions>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <DialogTitle className={classes.titleWithBorder2}>
                <FlexBox direction="column" align={ALIGN_CENTER}>
                  <h2 style={{ fontWeight: "bold" }}>
                    {getLocalisationMessage("payment", "Payment")}
                  </h2>
                  <FlexBox direction="column" style={{ textAlign: "center" }}>
                    <h5 className={classes.h5}>
                      {getLocalisationMessage("warehouse")}: {userWarehouseName}{" "}
                    </h5>
                    <h5 className={classes.h5}>
                      {getLocalisationMessage("operator")}: {userName}
                    </h5>
                    <h5 className={classes.h5}>
                      {getLocalisationMessage("date")}:{" "}
                      {new Date(Date.now()).toLocaleDateString()}
                    </h5>
                  </FlexBox>
                </FlexBox>
              </DialogTitle>
              <DialogContent>
                <FlexBox direction="column" flex={true}>
                  {contentItems.length < 1 && (
                    <FlexBox
                      style={{ marginTop: "1rem" }}
                      align={ALIGN_CENTER}
                      justify={JUSTIFY_CENTER}
                      flex={true}
                    >
                      <span
                        className={classes.fullWidth}
                        style={{ color: "#f57c00", textTransform: "uppercase" }}
                      >
                        {getLocalisationMessage("no_inventory_added")}
                      </span>
                    </FlexBox>
                  )}
                  <FlexBox
                    style={{ marginTop: "1rem" }}
                    align={ALIGN_CENTER}
                    justify={JUSTIFY_CENTER}
                    flex={true}
                  >
                    <span className={classes.fullWidth}>
                      {getLocalisationMessage(
                        "original_price",
                        "Original price",
                      )}
                    </span>
                    <span className={classes.dots}>{dots}</span>
                    <span className={classes.price}>
                      {money(packagePriceTotal) +
                        getLocalisationMessage("som", "so'm")}
                    </span>
                  </FlexBox>
                  <FlexBox
                    style={{ marginTop: "1rem" }}
                    align={ALIGN_CENTER}
                    justify={JUSTIFY_CENTER}
                    flex={true}
                  >
                    <span className={classes.fullWidth}>
                      {getLocalisationMessage(
                        "insurance_premium_5_of_the_cost_of_the_shipment",
                      )}
                    </span>
                    <span className={classes.dots}>{dots}</span>
                    <span className={classes.price}>
                      {money(nalog) + getLocalisationMessage("som", "so'm")}
                    </span>
                  </FlexBox>
                  {notificationFee > 0 && (
                    <FlexBox
                      style={{ marginTop: "1rem" }}
                      align={ALIGN_CENTER}
                      justify={JUSTIFY_CENTER}
                      flex={true}
                    >
                      <span className={classes.fullWidth}>
                        {getLocalisationMessage("notification", "Notification")}
                      </span>
                      <span className={classes.dots}>{dots}</span>
                      <span className={classes.price}>
                        {money(notificationFee) +
                          getLocalisationMessage("som", "so'm")}
                      </span>
                    </FlexBox>
                  )}
                  {customsFee > 0 && (
                    <FlexBox
                      style={{ marginTop: "1rem" }}
                      align={ALIGN_CENTER}
                      justify={JUSTIFY_CENTER}
                      flex={true}
                    >
                      <span className={classes.fullWidth}>
                        {getLocalisationMessage("customs_fee", "Customs fee")}
                      </span>
                      <span className={classes.dots}>{dots}</span>
                      <span className={classes.price}>
                        {money(customsFee) +
                          getLocalisationMessage("som", "so'm")}
                      </span>
                    </FlexBox>
                  )}
                  {forwardingFee > 0 && (
                    <FlexBox
                      style={{ marginTop: "1rem" }}
                      align={ALIGN_CENTER}
                      justify={JUSTIFY_CENTER}
                      flex={true}
                    >
                      <span className={classes.fullWidth}>
                        {getLocalisationMessage("forwarding_fee")}
                      </span>
                      <span className={classes.dots}>{dots}</span>
                      <span className={classes.price}>
                        {money(forwardingFee) +
                          getLocalisationMessage("som", "so'm")}
                      </span>
                    </FlexBox>
                  )}
                  {codFee > 0 && (
                    <FlexBox
                      style={{ marginTop: "1rem" }}
                      align={ALIGN_CENTER}
                      justify={JUSTIFY_CENTER}
                      flex={true}
                    >
                      <span className={classes.fullWidth}>
                        {getLocalisationMessage("cod_fee", "COD Fee")}
                      </span>
                      <span className={classes.dots}>{dots}</span>
                      <span className={classes.price}>
                        {money(codFee) + getLocalisationMessage("som", "so'm")}
                      </span>
                    </FlexBox>
                  )}
                  {addedServices.map(v => (
                    <FlexBox
                      style={{ marginTop: "1rem" }}
                      align={ALIGN_CENTER}
                      justify={JUSTIFY_CENTER}
                      flex={true}
                      key={v.id}
                    >
                      <span className={classes.fullWidth}>{v.name}</span>
                      <span className={classes.dots}>{dots}</span>
                      <span className={classes.price}>
                        {money(v[showedPrice(corporate)]) +
                          getLocalisationMessage("som", "so'm")}
                      </span>
                      <span>
                        &nbsp; x &nbsp;
                        {_.get(v, "quantity", 1)}
                      </span>
                      <span>
                        &nbsp;=&nbsp;
                        {money(
                          _.multiply(
                            v[showedPrice(corporate)],
                            _.get(v, "quantity", 1),
                          ),
                        ) + getLocalisationMessage("som", "so'm")}
                      </span>
                    </FlexBox>
                  ))}
                  <FlexBox
                    style={{ marginTop: "1rem" }}
                    align={ALIGN_CENTER}
                    justify={JUSTIFY_CENTER}
                    flex={true}
                  >
                    <span className={classes.fullWidth}>
                      {getLocalisationMessage("payment_done", "Payment Done")}
                    </span>
                    <span className={classes.dots}>{dots}</span>
                    <span
                      className={classes.fullWidth}
                      style={{ paddingLeft: ".5rem", color: "#EB5757" }}
                    >
                      -
                      {money(paidWithStamps) +
                        getLocalisationMessage("som", "so'm")}
                    </span>
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  justify={JUSTIFY_END}
                  style={{
                    marginRight: "1rem",
                  }}
                >
                  {edit ? (
                    <h5>
                      {getLocalisationMessage("to_pay", "To pay")}:{" "}
                      {money(allSum)}-{money(lastTotalSum)}(
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                          fontStyle: "italic",
                        }}
                      >
                        последний платеж
                      </span>
                      ) ={differenceSum}
                      {getLocalisationMessage("som", "so'm")}
                    </h5>
                  ) : (
                    <h5>
                      {getLocalisationMessage("to_pay", "To pay")}:{" "}
                      {money(allSum)}
                      {getLocalisationMessage("som", "so'm")}
                    </h5>
                  )}
                </FlexBox>
                <FlexBox>
                  <FlexBox direction="column">
                    {isLoading === LOADING && (
                      <FlexBox align={ALIGN_CENTER}>
                        <CircularProgress
                          style={{
                            width: "2rem",
                            height: "2rem",
                          }}
                          color="secondary"
                        />
                        <span style={{ marginLeft: ".5rem" }}>
                          {getLocalisationMessage("sending", "Sending...")}
                        </span>
                      </FlexBox>
                    )}
                    {isLoading === SUCCESS && (
                      <FlexBox align={ALIGN_CENTER}>
                        <CheckCircle
                          style={{
                            color: "#27AE60",
                            backgroundColor: "white",
                            width: "2rem",
                            height: "2rem",
                          }}
                        />
                        <span style={{ marginLeft: ".5rem" }}>
                          {resMessage}
                        </span>
                      </FlexBox>
                    )}
                    {isLoading === ERROR && (
                      <FlexBox align={ALIGN_CENTER}>
                        <Error
                          color="error"
                          style={{
                            width: "2rem",
                            height: "2rem",
                          }}
                        />
                        <span style={{ marginLeft: ".5rem" }}>
                          {resMessage}
                        </span>
                      </FlexBox>
                    )}
                  </FlexBox>
                  {isLoading === NOT_STARTED && (
                    <FlexBox flex={true} justify={JUSTIFY_CENTER}>
                      <p>
                        {getLocalisationMessage(
                          "confirm_payment_for_the_shipment",
                          "Confirm payment for the shipment?",
                        )}
                      </p>
                    </FlexBox>
                  )}
                </FlexBox>
              </DialogContent>
              <DialogActions>
                <FlexBox
                  flex={true}
                  justify={JUSTIFY_CENTER}
                  style={{ paddingBottom: "1rem" }}
                >
                  <FlexBox className={classes.btnWrapper}>
                    {isLoading !== SUCCESS && (
                      <CustomButton
                        className={classes.btn}
                        onClick={() => setIsOpenSubmitDialog(false)}
                        startIcon={<KeyboardArrowLeft />}
                        variant={OUTLINED}
                        color={SECONDARY}
                      >
                        {getLocalisationMessage("back", "Back")}
                      </CustomButton>
                    )}
                    {isLoading === SUCCESS ? (
                      <FlexBox direction="column">
                        <FlexBox
                          justify={JUSTIFY_SPACE_AROUND}
                          style={{ marginBottom: "1rem" }}
                        >
                          <CustomButton
                            className={classes.btn}
                            onClick={() => setLocation(ORDER_LIST_URL)}
                            startIcon={<KeyboardArrowLeft />}
                            variant={OUTLINED}
                            color={SECONDARY}
                          >
                            {getLocalisationMessage("go_to_list", "Go To List")}
                          </CustomButton>
                          {printReceipt && (
                            <React.Fragment>
                              <ButtonGroup
                                className={classes.btn}
                                variant={OUTLINED}
                                color={SECONDARY}
                              >
                                <CustomButton
                                  onClick={() =>
                                    getPDF(
                                      stateReceiptText,
                                      setStateReceiptText,
                                      IS_PRINTING,
                                      createdPostId,
                                      showErrorMessage1,
                                    )
                                  }
                                  endIcon={
                                    stateReceiptText[IS_PRINTING] ? (
                                      <CircularProgress
                                        size={20}
                                        color="secondary"
                                      />
                                    ) : (
                                      <Print />
                                    )
                                  }
                                >
                                  {getLocalisationMessage("receipt")}
                                </CustomButton>
                                <CustomButton
                                  onClick={() =>
                                    getPDF(
                                      stateReceipt,
                                      setStateReceipt,
                                      IS_DOWNLOADING,
                                      createdPostId,
                                      showErrorMessage1,
                                    )
                                  }
                                >
                                  {stateReceipt[IS_DOWNLOADING] ? (
                                    <CircularProgress
                                      size={20}
                                      color="secondary"
                                    />
                                  ) : (
                                    <GetApp />
                                  )}
                                </CustomButton>
                                <CustomButton
                                  onClick={() =>
                                    getPDF(
                                      stateReceipt,
                                      setStateReceipt,
                                      IS_PRINTING,
                                      createdPostId,
                                      showErrorMessage1,
                                    )
                                  }
                                >
                                  {stateReceipt[IS_PRINTING] ? (
                                    <CircularProgress
                                      size={20}
                                      color="secondary"
                                    />
                                  ) : (
                                    <Description />
                                  )}
                                </CustomButton>
                              </ButtonGroup>
                              <ButtonGroup
                                className={classes.btn}
                                variant={OUTLINED}
                                color={SECONDARY}
                              >
                                <CustomButton
                                  onClick={() =>
                                    getPDF(
                                      zebra,
                                      setZebra,
                                      IS_PRINTING,
                                      createdPostId,
                                      showErrorMessage1,
                                    )
                                  }
                                  endIcon={
                                    zebra[IS_PRINTING] ? (
                                      <CircularProgress
                                        size={20}
                                        color="secondary"
                                      />
                                    ) : (
                                      <Print />
                                    )
                                  }
                                >
                                  {getLocalisationMessage("address_label")}
                                </CustomButton>
                                <CustomButton
                                  onClick={() =>
                                    getPDF(
                                      zebra,
                                      setZebra,
                                      IS_DOWNLOADING,
                                      createdPostId,
                                      showErrorMessage1,
                                    )
                                  }
                                >
                                  {zebra[IS_DOWNLOADING] ? (
                                    <CircularProgress
                                      size={20}
                                      color="secondary"
                                    />
                                  ) : (
                                    <GetApp />
                                  )}
                                </CustomButton>
                              </ButtonGroup>
                              <ButtonGroup
                                className={classes.btn}
                                variant={OUTLINED}
                                color={SECONDARY}
                              >
                                <CustomButton
                                  onClick={() =>
                                    getPDF(
                                      stateAWB,
                                      setStateAWB,
                                      IS_PRINTING,
                                      createdPostId,
                                      showErrorMessage1,
                                    )
                                  }
                                  endIcon={
                                    stateAWB[IS_PRINTING] ? (
                                      <CircularProgress
                                        size={20}
                                        color="secondary"
                                      />
                                    ) : (
                                      <Print />
                                    )
                                  }
                                >
                                  {getLocalisationMessage("awb", "AWB")}
                                </CustomButton>
                                <CustomButton
                                  onClick={() =>
                                    getPDF(
                                      stateAWB,
                                      setStateAWB,
                                      IS_DOWNLOADING,
                                      createdPostId,
                                      showErrorMessage1,
                                    )
                                  }
                                >
                                  {stateAWB[IS_DOWNLOADING] ? (
                                    <CircularProgress
                                      size={20}
                                      color="secondary"
                                    />
                                  ) : (
                                    <GetApp />
                                  )}
                                </CustomButton>
                              </ButtonGroup>
                            </React.Fragment>
                          )}
                          {edit && (
                            <CustomButton
                              className={classes.btn}
                              onClick={() => setLocation(POST_CREATE_URL)}
                              endIcon={<Add />}
                              variant={CONTAINED}
                              color={SECONDARY}
                            >
                              {getLocalisationMessage(
                                "create_new_post",
                                "Create New Post",
                              )}
                            </CustomButton>
                          )}
                        </FlexBox>
                        {!edit && (
                          <FlexBox justify={JUSTIFY_SPACE_AROUND}>
                            <CustomButton
                              className={classes.btn}
                              onClick={() => {
                                setRefreshChekId(!refreshChekId);
                                setInitialValues(false);
                              }}
                              endIcon={<Add />}
                              variant={CONTAINED}
                              color={SECONDARY}
                            >
                              {getLocalisationMessage(
                                "create_new_post",
                                "Create New Post",
                              )}
                            </CustomButton>
                            <CustomButton
                              className={classes.btn}
                              onClick={() => {
                                setRefreshChekId(!refreshChekId);
                                setInitialValues(true);
                              }}
                              endIcon={<Add />}
                              variant={CONTAINED}
                              color={SECONDARY}
                            >
                              {getLocalisationMessage(
                                "сreate_a_new_shipment_with_the_current_sender",
                                "Create a new shipment with the current sender",
                              )}
                            </CustomButton>
                          </FlexBox>
                        )}
                      </FlexBox>
                    ) : (
                      <React.Fragment>
                        <CustomButton
                          disabled={isLoadingAVVAL || isLoading === LOADING}
                          className={classes.btn}
                          variant={CONTAINED}
                          color={SECONDARY}
                          onClick={() => {
                            setIsLoading(LOADING);
                            if (isFreePayType) {
                              handleSubmit();
                            } else if (lastTotalSum !== allSum) {
                              setIsLoadingAVVAL(true);
                              createOrUpdateOrder(
                                creatPostData(
                                  isYandexMap,
                                  values,
                                  paymentType !== NON_PAYMENT,
                                ),
                                createdPostId,
                                edit,
                              )
                                .then(resOrder => {
                                  if (resOrder.status === "success") {
                                    const tempCreatedPostId = fp.get(
                                      "data.id",
                                      resOrder,
                                    );
                                    const tempCreatedBarcode = fp.get(
                                      "data.barcode",
                                      resOrder,
                                    );
                                    setCreatedPostId(tempCreatedPostId);
                                    setCreatedBarcode(tempCreatedBarcode);
                                    const isNeedToCancel = Boolean(
                                      edit && avvalReceipt,
                                    );

                                    const optionsPayment = {
                                      method: "POST",
                                      headers: {
                                        Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
                                        "Content-Type": "application/json",
                                        Cookie:
                                          "PHPSESSID=5lgnfmu5ov5vgb3b8g7tdb9rg2",
                                      },
                                      body: JSON.stringify({
                                        search_chek: {
                                          barcode: tempCreatedBarcode,
                                          method: "sale-services",
                                        },
                                      }),
                                    };

                                    fetch(avvalSearchUrl, optionsPayment)
                                      .then(response => response.json())
                                      .then(rescheck => {
                                        if (
                                          rescheck &&
                                          rescheck.res_id === 103
                                        ) {
                                          setReceipt(tempCreatedPostId, {
                                            check_number: tempCreatedPostId,
                                            receipt: {
                                              TerminalID: getValue(
                                                rescheck,
                                                "data.terminal_id",
                                              ),
                                              ReceiptSeq: getValue(
                                                rescheck,
                                                "data.tartib_raqami",
                                              ),
                                              DateTime: getValue(
                                                rescheck,
                                                "data.date_time",
                                              ),
                                              FiscalSign: getValue(
                                                rescheck,
                                                "data.fiskal_belgi",
                                              ),
                                              AppletVersion: getValue(
                                                rescheck,
                                                "data.versiya",
                                              ),
                                              QRCodeURL: getValue(
                                                rescheck,
                                                "data.link",
                                              ),
                                              checkID,
                                            },
                                            type: "SALE",
                                          })
                                            .then(resData => {
                                              if (resData) {
                                                setIsLoading(SUCCESS);
                                                setIsLoadingAVVAL(false);
                                              }
                                            })
                                            .catch(error => {
                                              showErrorMessage1(error);
                                              setLocation(ORDER_LIST_URL);
                                              setIsLoadingAVVAL(false);
                                            });
                                        } else {
                                          fetch(urlAVVAL, {
                                            method: "POST",
                                            body: JSON.stringify(
                                              isNeedToCancel
                                                ? editAVVALData()
                                                : createAVVALData(
                                                    tempCreatedPostId,
                                                    tempCreatedBarcode,
                                                  ),
                                            ),
                                          })
                                            .then(response => response.json())
                                            .then(res => {
                                              if (
                                                res &&
                                                getValue(res, "code") === "1"
                                              ) {
                                                showErrorMessage1(
                                                  getLocalisationMessage(
                                                    fp.toString(
                                                      getValue(
                                                        res,
                                                        "resInfo.code",
                                                      ),
                                                    ),
                                                  ),
                                                );
                                                setIsLoading(ERROR);
                                                setLocation(ORDER_LIST_URL);
                                                setTimeout(
                                                  () =>
                                                    setIsLoadingAVVAL(false),
                                                  1500,
                                                );
                                              } else if (
                                                res &&
                                                getValue(res, "code") === "-1"
                                              ) {
                                                showErrorMessage1(
                                                  getLocalisationMessage(
                                                    fp.toString(
                                                      getValue(
                                                        res,
                                                        "resInfo.code",
                                                      ),
                                                    ),
                                                    getValue(res, "resInfo"),
                                                  ),
                                                );
                                                setIsLoading(ERROR);
                                                setLocation(ORDER_LIST_URL);
                                                setTimeout(
                                                  () =>
                                                    setIsLoadingAVVAL(false),
                                                  1500,
                                                );
                                              } else if (
                                                res &&
                                                (getValue(
                                                  res,
                                                  "resInfo.result.QRCodeURL",
                                                ) === null ||
                                                  !getValue(
                                                    res,
                                                    "resInfo.result.QRCodeURL",
                                                  ))
                                              ) {
                                                showErrorMessage1(
                                                  getLocalisationMessage(
                                                    "the_payment_was_not_successful",
                                                  ),
                                                );
                                                setIsLoading(ERROR);
                                                setLocation(ORDER_LIST_URL);
                                                setTimeout(
                                                  () =>
                                                    setIsLoadingAVVAL(false),
                                                  1500,
                                                );
                                              } else if (checkAVVALres(res)) {
                                                setIsLoadingAVVAL(true);
                                                setReceipt(tempCreatedPostId, {
                                                  check_number: tempCreatedPostId,
                                                  receipt: {
                                                    ..._.get(
                                                      res,
                                                      "resInfo.result",
                                                    ),
                                                    checkID,
                                                  },
                                                  type: "SALE",
                                                })
                                                  .then(resData => {
                                                    if (resData) {
                                                      setIsLoading(SUCCESS);
                                                      setIsLoadingAVVAL(false);
                                                    }
                                                  })
                                                  .catch(error => {
                                                    showErrorMessage1(error);
                                                    setLocation(ORDER_LIST_URL);
                                                    setIsLoadingAVVAL(false);
                                                  });
                                              } else
                                                setLocation(ORDER_LIST_URL);
                                            })
                                            .catch(() => {
                                              showErrorMessage1(
                                                getLocalisationMessage(
                                                  "user_is_not_connected_to_avval",
                                                ),
                                              );
                                              setIsLoadingAVVAL(false);
                                              setLocation(ORDER_LIST_URL);
                                            });
                                        }
                                      })
                                      .catch(() => {
                                        setIsLoading(ERROR);
                                        setIsLoadingAVVAL(false);
                                        setLocation(ORDER_LIST_URL);
                                      });
                                  } else {
                                    showErrorMessage1(resOrder.message);
                                    setIsLoadingAVVAL(false);
                                    setIsLoading(ERROR);
                                  }
                                })
                                .catch(error => {
                                  showErrorMessage1(error);
                                  setIsLoadingAVVAL(false);
                                  setIsLoading(ERROR);
                                });
                            } else {
                              handleSubmit();
                            }
                          }}
                          endIcon={
                            isLoadingAVVAL || isLoading === LOADING ? (
                              <CircularProgress size={20} color="secondary" />
                            ) : (
                              <Check />
                            )
                          }
                        >
                          {getLocalisationMessage(
                            isFreePayType
                              ? "yes_confirm_create_a_shipment"
                              : lastTotalSum !== allSum
                              ? "pay_with_avval"
                              : "yes_confirm_create_a_shipment",
                          )}
                        </CustomButton>
                        {2 === 3 && (
                          <CustomButton
                            disabled={isLoading === LOADING}
                            className={classes.btn}
                            variant={CONTAINED}
                            color={SECONDARY}
                            onClick={() => {
                              handleSubmit();
                            }}
                            endIcon={
                              isLoading === LOADING ? (
                                <CircularProgress size={20} color="secondary" />
                              ) : (
                                <Check />
                              )
                            }
                          >
                            {getLocalisationMessage(
                              "yes_confirm_create_a_shipment",
                              "Yes confirm. Create a shipment",
                            )}
                          </CustomButton>
                        )}
                      </React.Fragment>
                    )}
                  </FlexBox>
                </FlexBox>
              </DialogActions>
            </React.Fragment>
          )}
        </Dialog>
        <FlexBox
          flex={true}
          justify={JUSTIFY_SPACE_AROUND}
          align={ALIGN_END}
          style={{ margin: "1rem 0" }}
        >
          <FlexBox>
            <h4
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "24px",
                lineHeight: "28px",
                textTransform: "capitalize",
                color: "#263238",
              }}
            >
              {edit
                ? `${getLocalisationMessage("edit_order")} ${createdPostId}`
                : getLocalisationMessage(
                    "create_new_order",
                    "Create New Order",
                  )}
            </h4>
          </FlexBox>
          <FlexBox>
            <h5
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  marginRight: ".5rem",
                  color: isLoadingConnectingAVVAL
                    ? orange[500]
                    : isConnectedAVVAL
                    ? green[500]
                    : red[500],
                }}
              >
                {getLocalisationMessage(
                  !userIdAVVAL
                    ? "user_profile_is_not_connected_with_avval_profile"
                    : isLoadingConnectingAVVAL
                    ? "connecting_to_avval"
                    : isConnectedAVVAL
                    ? "user_is_connected_to_avval"
                    : "user_is_not_connected_to_avval",
                )}
              </span>
              {isLoadingConnectingAVVAL ? (
                <CircularProgress size={20} style={{ color: orange[500] }} />
              ) : isConnectedAVVAL ? (
                <CheckCircle style={{ color: green[500] }} />
              ) : (
                <Warning style={{ color: red[500] }} />
              )}
            </h5>
          </FlexBox>
          <FlexBox className={classes.btnWrapper}>
            <CustomButton
              className={classes.btn}
              onClick={() => setLocation(ORDER_LIST_URL)}
              startIcon={<KeyboardArrowLeft />}
              variant={OUTLINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("back", "Back")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
        <FlexBox
          gutter={8}
          element={<Card />}
          className={classes.card}
          direction="column"
        >
          <CardContent className={classes.titleWithBorder}>
            <FlexBox align={ALIGN_CENTER} justify={JUSTIFY_SPACE_AROUND}>
              <FlexBox align={ALIGN_CENTER}>
                <strong className={classes.rightBorder}>
                  {getLocalisationMessage(
                    "sender_information",
                    "Sender information",
                  )}
                </strong>
                <FormCheckbox
                  disabled={isNotPaidMode}
                  name="corporate"
                  style={{
                    padding: ".5rem .5rem",
                    marginLeft: "1rem",
                  }}
                  label={getLocalisationMessage(
                    "corporate_client",
                    "Corporate client",
                  )}
                  onChange={() => {
                    change("senderName", " ");
                  }}
                />
              </FlexBox>
            </FlexBox>
          </CardContent>
          <CardContent>
            <FlexBox direction="column">
              <FlexBox gutter={16}>
                <FlexBox flex={true} gutter={16}>
                  <FlexBox flex={true} className={classes.jmWrapper}>
                    <FormJMAutoComplete
                      disabled={isNotPaidMode}
                      withTargetFilter={true}
                      renderOption={option => (
                        <FlexBox direction="column">
                          <span>{option.name}</span>
                          <span
                            style={{
                              fontSize: ".8rem",
                              fontStyle: "italic",
                            }}
                          >
                            {option.value.hierarchy.map((item, index, arr) =>
                              index === arr.length - 1
                                ? item.name
                                : `${item.name} > `,
                            )}
                          </span>
                        </FlexBox>
                      )}
                      fullWidth={true}
                      name="senderJurisdiction"
                      onChange={() => change("senderPostcode", "")}
                      label={getLocalisationMessage(
                        "sender_jurisdiction",
                        "Sender Jurisdiction",
                      )}
                    />
                  </FlexBox>
                  <FlexBox style={{ paddingLeft: 0, maxHeight: "55px" }}>
                    <FormJurisdictionSelectField
                      closeDialog={() => setEditSenderJurisdiction(false)}
                      withoutInput={true}
                      withoutInputOpen={editSenderJurisdiction}
                      name="senderJurisdiction"
                      onChange={() => change("senderPostcode", null)}
                    />
                    <IconButton
                      size="small"
                      className={classes.jmIcon}
                      onClick={() => setEditSenderJurisdiction(true)}
                    >
                      <AccountTree />
                    </IconButton>
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormCustomerWithMerchantsAutoComplete
                      disabled={isNotPaidMode}
                      name="senderName"
                      merchantType={corporate}
                      hintText={getLocalisationMessage(
                        "для поиска введите номер телефона или имя",
                        "для поиска введите номер телефона или имя",
                      )}
                      label={getLocalisationMessage("name", "Name")}
                      fullWidth={true}
                      variant={OUTLINED}
                      parseInput={name => ({ name })}
                      formatOption={fp.get("name")}
                      onChange={v => {
                        if (v.id) {
                          const phone = get(v, "phone");
                          const address = get(v, "address.address");
                          const postcode = get(v, "address.post_code");
                          const addressType = get(v, "address.address_type");
                          if (phone) change("senderPhone", phone);
                          if (postcode) {
                            setValidationLevel(postcode, "SPVLevel", true);
                            change("senderPostcode", postcode);
                          }
                          if (isYandexMap) {
                            if (address) {
                              change("senderLocation", {
                                address,
                              });
                              setSenderLocationEdit(false);
                            }
                          } else if (address) change("senderLocation", address);

                          if (addressType)
                            change("senderAddressType", addressType);
                        }
                      }}
                      renderOption={option => (
                        <FlexBox direction="column">
                          <span>{option.name}</span>
                          <span
                            style={{
                              fontSize: ".8rem",
                              fontStyle: "italic",
                            }}
                          >
                            {getValue(option.value, "phone")}
                          </span>
                        </FlexBox>
                      )}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <MuiPhoneNumber
                      disabled={isNotPaidMode}
                      disableAreaCodes={true}
                      isValid={value => value.length > 8}
                      defaultCountry={
                        senderCountryCode
                          ? senderCountryCode.toLowerCase()
                          : "uz"
                      }
                      name="senderPhone"
                      value={senderPhone || ""}
                      onChange={v => change("senderPhone", v)}
                      variant={OUTLINED}
                      size="small"
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "phone_number",
                        "Phone Number",
                      )}
                    />
                    <FormTextField
                      style={{ display: "none" }}
                      name="senderPhone"
                      label={getLocalisationMessage(
                        "phone_number",
                        "Phone Number",
                      )}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true}>
                  {isYandexMap && (
                    <FlexBox
                      style={{ marginRight: 16 }}
                      className={classes.searchTypeIndex}
                    >
                      <FormSelectField
                        name="senderAddressLocationType"
                        label={getLocalisationMessage(
                          "search_address_type_sender",
                          "Search Address Type (Sender)",
                        )}
                        fullWidth={true}
                        options={LocationAddresstypes}
                        formatOption={x =>
                          formatAddressTypeLocalisation(
                            x,
                            getLocalisationMessage,
                          )
                        }
                        onChange={() => change("senderLocation", {})}
                      />
                    </FlexBox>
                  )}

                  <FlexBox flex={true}>
                    {isYandexMap ? (
                      <FlexBox flex={true}>
                        <FormYandexGeoAutoComplete
                          language={userLanguage}
                          location={{
                            lat: getValue(senderJurisdiction, "lat"),
                            lng: getValue(senderJurisdiction, "lng"),
                          }}
                          type={senderAddressLocationType}
                          autoComplete="off"
                          name="senderLocation"
                          countryCode="ru"
                          fullWidth={true}
                          label={`${getLocalisationMessage(
                            "address",
                            "Address",
                          )} `}
                          hintText={getLocalisationMessage(
                            "type_to_search_5_letters",
                            "Type to Search (5 letters minimum)",
                          )}
                          onChange={() => {
                            setSenderLocationEdit(false);
                          }}
                        />

                        <ManualSetPostLocationDialog
                          language={userLanguage}
                          latLon={{
                            lat: getValue(senderJurisdiction, "lat"),
                            lng: getValue(senderJurisdiction, "lng"),
                          }}
                          type={senderAddressLocationType}
                          fieldName="senderLocation"
                          change={change}
                          locationEdit={setSenderLocationEdit}
                          location={
                            senderLocation &&
                            senderLocation.lat &&
                            senderLocation.lng
                              ? senderLocation
                              : {
                                  lat: getValue(senderJurisdiction, "lat"),
                                  lng: getValue(senderJurisdiction, "lng"),
                                }
                          }
                        />
                      </FlexBox>
                    ) : (
                      <FormTextField
                        style={{ marginRight: 16 }}
                        disabled={isNotPaidMode}
                        name="senderLocation"
                        fullWidth={true}
                        withSuccessIcon={true}
                        label={getLocalisationMessage("address", "Address")}
                        placeholder="Улица, дом, квартира"
                      />
                    )}
                  </FlexBox>

                  <FlexBox className={classes.searchTypeIndex}>
                    <PostcodeAutoCompleteForOrder
                      immediatelyShowError={senderPostcode}
                      name="senderPostcode"
                      fullWidth={true}
                      jurisdictionId={senderJurisdictionId}
                      jurisdiction={senderJurisdiction}
                      disabled={isNotPaidMode || !senderJurisdictionId}
                      postcodes={senderPostcodeOptions}
                      getPostcodeOptions={v => setSenderPostcodeOptions(v)}
                      onChange={(e, v) =>
                        setValidationLevel(v, "SPVLevel", true)
                      }
                      label={getLocalisationMessage("index", "Index")}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type To Search...",
                      )}
                    />
                  </FlexBox>
                </FlexBox>

                {2 === 3 && (
                  <FlexBox flex={true}>
                    <FormSelectField
                      disabled={isNotPaidMode}
                      name="senderAddressType"
                      label={getLocalisationMessage(
                        "address_type",
                        "Address Type",
                      )}
                      fullWidth={true}
                      withSuccessIcon={true}
                      options={AddressTypes}
                      formatOption={x =>
                        getLocalisationMessage(x, formatText(x))
                      }
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          </CardContent>
        </FlexBox>
        <FlexBox
          gutter={8}
          element={<Card />}
          className={classes.card}
          direction="column"
          style={{ marginTop: "1rem" }}
        >
          <CardContent className={classes.titleWithBorder}>
            <FlexBox align={ALIGN_CENTER} justify={JUSTIFY_SPACE_AROUND}>
              <FlexBox>
                <strong className={classes.titleStyle}>
                  {getLocalisationMessage(
                    "address_of_the_recipient",
                    "Address of the recipient",
                  )}
                </strong>
              </FlexBox>
            </FlexBox>
          </CardContent>
          <CardContent>
            <FlexBox direction="column">
              <FlexBox gutter={16}>
                <FlexBox flex={true} gutter={16}>
                  <FlexBox flex={true} className={classes.jmWrapper}>
                    <FormJMAutoComplete
                      withTargetFilter={true}
                      renderOption={option => (
                        <FlexBox direction="column">
                          <span>{option.name}</span>
                          <span
                            style={{
                              fontSize: ".8rem",
                              fontStyle: "italic",
                            }}
                          >
                            {option.value.hierarchy.map((item, index, arr) =>
                              index === arr.length - 1
                                ? item.name
                                : `${item.name} > `,
                            )}
                          </span>
                        </FlexBox>
                      )}
                      onChange={() => change("recipientPostcode", "")}
                      fullWidth={true}
                      name="recipientJurisdiction"
                      label={getLocalisationMessage(
                        "recipient_jurisdiction",
                        "Recipient Jurisdiction",
                      )}
                    />
                  </FlexBox>
                  <FlexBox style={{ paddingLeft: 0, maxHeight: "55px" }}>
                    <FormJurisdictionSelectField
                      closeDialog={() => setEditRecipientJurisdiction(false)}
                      withoutInput={true}
                      withoutInputOpen={editRecipientJurisdiction}
                      name="recipientJurisdiction"
                      onChange={() => change("recipientPostcode", null)}
                    />
                    <IconButton
                      size="small"
                      className={classes.jmIcon}
                      onClick={() => setEditRecipientJurisdiction(true)}
                    >
                      <AccountTree />
                    </IconButton>
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormCustomerWithMerchantsAutoComplete
                      name="recipientName"
                      hintText={getLocalisationMessage(
                        "для поиска введите номер телефона или имя",
                        "для поиска введите номер телефона или имя",
                      )}
                      label={getLocalisationMessage("name", "Name")}
                      fullWidth={true}
                      variant={OUTLINED}
                      parseInput={name => ({ name })}
                      formatOption={fp.get("name")}
                      onChange={v => {
                        if (v.id) {
                          const phone = get(v, "phone");
                          const address = get(v, "address.address");
                          const postcode = get(v, "address.post_code");
                          const addressType = get(v, "address.address_type");
                          if (phone) change("recipientPhone", phone);
                          if (postcode) {
                            setValidationLevel(postcode, "RPVLevel");
                            change("recipientPostcode", postcode);
                          }
                          if (isYandexMap) {
                            if (address) {
                              change("recipientLocation", {
                                address,
                              });
                              setRecipientLocationEdit(false);
                            }
                          } else if (address) {
                            change("recipientLocation", address);
                          }

                          if (addressType)
                            change("recipientAddressType", addressType);
                        }
                      }}
                      renderOption={option => (
                        <FlexBox direction="column">
                          <span>{option.name}</span>
                          <span
                            style={{
                              fontSize: ".8rem",
                              fontStyle: "italic",
                            }}
                          >
                            {getValue(option.value, "phone")}
                          </span>
                        </FlexBox>
                      )}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <MuiPhoneNumber
                      disableAreaCodes={true}
                      isValid={value => value.length > 8}
                      defaultCountry={
                        recipientCountryCode
                          ? recipientCountryCode.toLowerCase()
                          : "uz"
                      }
                      name="recipientPhone"
                      value={recipientPhone || ""}
                      onChange={v => change("recipientPhone", v)}
                      variant={OUTLINED}
                      size="small"
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "phone_number",
                        "Phone Number",
                      )}
                    />
                    <FormTextField
                      style={{ display: "none" }}
                      name="recipientPhone"
                      label={getLocalisationMessage(
                        "phone_number",
                        "Phone Number",
                      )}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true}>
                  {isYandexMap && (
                    <FlexBox
                      style={{ marginRight: 16 }}
                      className={classes.searchTypeIndex}
                    >
                      <FormSelectField
                        name="recipientAddressLocationType"
                        label={getLocalisationMessage(
                          "search_address_type_recipient",
                          "Search Address Type (Recipient)",
                        )}
                        fullWidth={true}
                        options={LocationAddresstypes}
                        formatOption={x =>
                          formatAddressTypeLocalisation(
                            x,
                            getLocalisationMessage,
                          )
                        }
                        onChange={() => change("recipientLocation", {})}
                      />
                    </FlexBox>
                  )}

                  <FlexBox flex={true}>
                    {isYandexMap ? (
                      <FlexBox flex={true}>
                        <FormYandexGeoAutoComplete
                          language={userLanguage}
                          location={{
                            lat: getValue(recipientJurisdiction, "lat"),
                            lng: getValue(recipientJurisdiction, "lng"),
                          }}
                          type={recipientAddressLocationType}
                          autoComplete="off"
                          name="recipientLocation"
                          countryCode="ru"
                          fullWidth={true}
                          label={`${getLocalisationMessage(
                            "address",
                            "Address",
                          )} `}
                          onChange={() => setRecipientLocationEdit(false)}
                          hintText={getLocalisationMessage(
                            "type_to_search_5_letters",
                            "Type to Search (5 letters minimum)",
                          )}
                        />

                        <ManualSetPostLocationDialog
                          language={userLanguage}
                          latLon={{
                            lat: getValue(recipientJurisdiction, "lat"),
                            lng: getValue(recipientJurisdiction, "lng"),
                          }}
                          type={recipientAddressLocationType}
                          fieldName="recipientLocation"
                          locationEdit={setRecipientLocationEdit}
                          change={change}
                          location={
                            recipientLocation &&
                            recipientLocation.lat &&
                            recipientLocation.lng
                              ? recipientLocation
                              : {
                                  lat: getValue(
                                    recipientJurisdiction,
                                    "lat",
                                    null,
                                  ),
                                  lng: getValue(
                                    recipientJurisdiction,
                                    "lng",
                                    null,
                                  ),
                                }
                          }
                        />
                      </FlexBox>
                    ) : (
                      <FormTextField
                        style={{ marginRight: 16 }}
                        name="recipientLocation"
                        fullWidth={true}
                        withSuccessIcon={true}
                        label={getLocalisationMessage("address", "Address")}
                        placeholder="Улица, дом, квартира"
                      />
                    )}
                  </FlexBox>

                  <FlexBox className={classes.searchTypeIndex}>
                    <PostcodeAutoCompleteForOrder
                      immediatelyShowError={recipientPostcode}
                      name="recipientPostcode"
                      fullWidth={true}
                      jurisdictionId={recipientJurisdictionId}
                      disabled={!recipientJurisdictionId}
                      postcodes={recipientPostcodeOptions}
                      getPostcodeOptions={v => setRecipientPostcodeOptions(v)}
                      onChange={(e, v) => setValidationLevel(v, "RPVLevel")}
                      label={getLocalisationMessage("index", "Index")}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type To Search...",
                      )}
                    />
                  </FlexBox>
                </FlexBox>

                {2 === 3 && (
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="recipientAddressType"
                      label={getLocalisationMessage(
                        "address_type",
                        "Address Type",
                      )}
                      fullWidth={true}
                      options={AddressTypes}
                      formatOption={x =>
                        getLocalisationMessage(x, formatText(x))
                      }
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          </CardContent>
        </FlexBox>
        <FlexBox
          gutter={8}
          element={<Card />}
          className={classes.card}
          direction="column"
          style={{ marginTop: "1rem" }}
        >
          <CardContent className={classes.titleWithBorder}>
            <FlexBox align={ALIGN_CENTER}>
              <strong className={classes.titleStyle}>
                {getLocalisationMessage("shipment_type", "Shipment Type")}
              </strong>
            </FlexBox>
          </CardContent>
          <CardContent>
            <FlexBox direction="column">
              <FlexBox align={ALIGN_START} justify={JUSTIFY_CENTER} gutter={16}>
                <FlexBox direction="column" flex={true}>
                  {isPackageTelegram || simpleSizeType ? (
                    <FlexBox>
                      <FlexBox flex={true}>
                        <FormTextField
                          name="weight"
                          type="number"
                          onFocus={event => {
                            event.target.select();
                          }}
                          parse={value => {
                            if (value <= 0) {
                              return 0;
                            }

                            return String(
                              Math.round(parseFloat(String(value))),
                            );
                          }}
                          label={
                            isPackageTelegram
                              ? "количество слов"
                              : getLocalisationMessage("weight_g", "Weight (g)")
                          }
                          fullWidth={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getLocalisationMessage("g", "g")}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FlexBox>
                    </FlexBox>
                  ) : (
                    <FlexBox
                      style={{ minWidth: "500px" }}
                      flex={true}
                      gutter={8}
                    >
                      <FlexBox>
                        <FormTextField
                          name="weight"
                          type="number"
                          onFocus={event => {
                            event.target.select();
                          }}
                          parse={value => {
                            if (value <= 0) {
                              return 0;
                            }

                            return String(
                              Math.round(parseFloat(String(value))),
                            );
                          }}
                          label={getLocalisationMessage(
                            "weight_g",
                            "Weight (g)",
                          )}
                          fullWidth={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getLocalisationMessage("g", "g")}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FlexBox>
                      <FlexBox>
                        <FormTextField
                          name="width"
                          type="number"
                          onFocus={event => {
                            event.target.select();
                          }}
                          parse={value => {
                            if (value <= 0) {
                              return 0;
                            }

                            return String(
                              Math.round(parseFloat(String(value))),
                            );
                          }}
                          label={getLocalisationMessage("width", "Width")}
                          fullWidth={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getLocalisationMessage("sm", "sm")}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FlexBox>
                      <FlexBox>
                        <FormTextField
                          name="length"
                          type="number"
                          onFocus={event => {
                            event.target.select();
                          }}
                          parse={value => {
                            if (value <= 0) {
                              return 0;
                            }

                            return String(
                              Math.round(parseFloat(String(value))),
                            );
                          }}
                          label={getLocalisationMessage("length", "Length")}
                          fullWidth={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getLocalisationMessage("sm", "sm")}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FlexBox>
                      <FlexBox>
                        <FormTextField
                          name="height"
                          type="number"
                          onFocus={event => {
                            event.target.select();
                          }}
                          parse={value => {
                            if (value <= 0) {
                              return 0;
                            }

                            return String(
                              Math.round(parseFloat(String(value))),
                            );
                          }}
                          label={getLocalisationMessage("height", "Height")}
                          fullWidth={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getLocalisationMessage("sm", "sm")}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FlexBox>
                    </FlexBox>
                  )}
                  {!isPackageTelegram && (
                    <FlexBox style={{ marginLeft: "3rem" }}>
                      <a
                        className={classes.changeSize}
                        href={e => e.preventDefault()}
                        onClick={() => setSimpleSizeType(!simpleSizeType)}
                      >
                        {getLocalisationMessage(
                          "set_the_size_of_the_shipment",
                          "Set the size of the shipment",
                        )}
                      </a>
                    </FlexBox>
                  )}
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="serviceType"
                    fullWidth={true}
                    options={serviceTypes}
                    formatOption={x => _.get(x, "name")}
                    label={getLocalisationMessage("shipment_type")}
                    loading={serviceTypesIsLoading}
                    disabled={!serviceTypes.length}
                  />
                </FlexBox>
                <FlexBox direction="column" flex={true}>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="package"
                      loading={packagePricesIsLoading}
                      fullWidth={true}
                      label={getLocalisationMessage("service_type")}
                      onChange={(e, v) => {
                        if (_.get(v, "id")) {
                          change(
                            "transportationType",
                            _.get(v, "transportation_type"),
                          );
                          const tempPackagePrice = _.get(v, "price");
                          const customsFee2 = _.get(
                            tempPackagePrice,
                            "customs_fee",
                            0,
                          );
                          const codFee2 = _.get(
                            tempPackagePrice,
                            "cod_fee_percentage",
                            0,
                          );
                          const notificationFee2 = toFinite(
                            _.get(tempPackagePrice, "notification_fee", 0),
                          );
                          if (customsFee !== customsFee2)
                            change("customsFee", customsFee2);
                          if (codFee !== codFee2) change("codFee", codFee2);
                          if (
                            notificationEnabled &&
                            notificationFee !== notificationFee2
                          )
                            change("notificationFee", notificationFee2);
                          if (packageName === "Телеграмма")
                            change("isPackageTelegram", true);
                          else change("isPackageTelegram", false);
                          if (!valuable && _.get(v, "pay_with_stamp")) {
                            change("paymentType", NON_PAYMENT);
                            change(
                              "paidWithStamps",
                              toFinite(_.get(v, "price.total", 0)),
                            );
                          } else {
                            change("paymentType", "");
                            change("paidWithStamps", 0);
                          }
                          change("notificationEnabled", false);
                          change("notificationFee", 0);
                        }
                      }}
                      formatOption={x => (
                        <FlexBox direction="column">
                          <span>{`${getLocalisationMessage(
                            _.get(x, "transportation_type"),
                          )} ${money(_.get(x, "price.total")) +
                            getLocalisationMessage("som")}`}</span>
                          <span
                            style={{
                              fontSize: ".8rem",
                              fontStyle: "italic",
                            }}
                          >
                            {userLanguage === UZBEK_LANGUAGE
                              ? x.description
                              : userLanguage === RUSSIAN_LANGUAGE
                              ? x.description_ru
                              : x.description_en}
                          </span>
                        </FlexBox>
                      )}
                      compareOptions={isEqualDataIn(["price", "id"])}
                      options={packagePrices}
                      disabled={!packagePrices.length}
                    />
                  </FlexBox>
                  {_.get(values.package, "with_notification") && (
                    <FlexBox style={{ marginBottom: ".5rem" }}>
                      <FormCheckbox
                        name="notificationEnabled"
                        disabled={initialValues.notificationEnabled}
                        style={{
                          padding: ".5rem .5rem",
                          marginLeft: "1rem",
                        }}
                        label={getLocalisationMessage(
                          "with_notification",
                          "with notification",
                        )}
                        onChange={(e, v) => {
                          const tempNotificationFee = toFinite(
                            _.get(values.package, "price.notification_fee"),
                          );
                          if (v) {
                            change("notificationFee", tempNotificationFee);
                            if (isNeedPayWithStamps && !valuable) {
                              change(
                                "paidWithStamps",
                                packagePriceTotal + tempNotificationFee,
                              );
                            } else {
                              change("paidWithStamps", tempNotificationFee);
                            }
                          } else {
                            change(
                              "paidWithStamps",
                              toFinite(paidWithStamps) - tempNotificationFee,
                            );
                            change("notificationFee", 0);
                          }
                        }}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
                <FlexBox direction="column" flex={true} style={{ gap: 16 }}>
                  <FormTextField
                    name="barcode"
                    label={getLocalisationMessage("barcode", "Barcode")}
                    fullWidth={true}
                    withSuccessIcon={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <svg style={{ width: "1.5rem", height: "1.5rem" }}>
                            <path
                              fill="currentColor"
                              d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"
                            />
                          </svg>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!barcode && (
                    <span style={{ color: "#f57c00" }}>
                      {getLocalisationMessage(
                        "if_you_do_not_enter_a_barcode_it_will_be_generated_automatically",
                        "if you do not enter a barcode, it will be generated automatically",
                      )}
                    </span>
                  )}
                  <FlexBox
                    flex={true}
                    style={{
                      margintop: ".25rem",
                      opacity: notificationEnabled ? 1 : 0,
                    }}
                  >
                    <FormTextField
                      disabled={!notificationEnabled}
                      name="notificationBarcode"
                      fullWidth={true}
                      label={getLocalisationMessage("notification_barcode")}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              {2 === 3 && (
                <span>
                  {getLocalisationMessage("payable_weight", "Payable weight")}:{" "}
                  {weight} {getLocalisationMessage("g", "g")}
                </span>
              )}
            </FlexBox>
          </CardContent>
        </FlexBox>
        <FlexBox
          gutter={8}
          element={<Card />}
          className={classes.card}
          direction="column"
          style={{ marginTop: "1rem" }}
        >
          <CardContent className={classes.titleWithBorder}>
            <FlexBox align={ALIGN_CENTER} justify={JUSTIFY_SPACE_AROUND}>
              <FlexBox>
                <strong className={classes.titleStyle}>
                  {getLocalisationMessage("payment", "Payment")}
                </strong>
              </FlexBox>
              <FlexBox align={ALIGN_CENTER}>
                <span className={classes.rightBorder2}>
                  {getLocalisationMessage("tariff", "Tariff")}:
                  <strong>
                    {money(packagePriceTotal)}
                    {getLocalisationMessage("som", "so'm")}
                  </strong>
                </span>
                {notificationFee > 0 && (
                  <span className={classes.rightBorder2}>
                    {getLocalisationMessage("notification", "Notification")}:
                    <strong>
                      {money(notificationFee)}
                      {getLocalisationMessage("som", "so'm")}
                    </strong>
                  </span>
                )}
                {customsFee > 0 && (
                  <span className={classes.rightBorder2}>
                    {getLocalisationMessage("customs_fee", "Customs fee")}:
                    <strong>
                      {money(customsFee)}
                      {getLocalisationMessage("som", "so'm")}
                    </strong>
                  </span>
                )}
                {forwardingFee > 0 && (
                  <span className={classes.rightBorder2}>
                    {getLocalisationMessage("forwarding_fee", "Forwarding fee")}
                    :
                    <strong>
                      {money(forwardingFee)}
                      {getLocalisationMessage("som", "so'm")}
                    </strong>
                  </span>
                )}
                {codFee > 0 && (
                  <span className={classes.rightBorder2}>
                    {getLocalisationMessage("cod_fee", "COD Fee")}:
                    <strong>
                      {money(codFee)}
                      {getLocalisationMessage("som", "so'm")}
                    </strong>
                  </span>
                )}
                {corporate &&
                  getValue(senderName, "can_create_cod_order", false) &&
                  cod > 0 && (
                    <span className={classes.rightBorder2}>
                      {getLocalisationMessage("cod", "COD")}:
                      <strong>
                        {money(cod)}
                        {getLocalisationMessage("som", "so'm")}
                      </strong>
                    </span>
                  )}
                <span className={classes.rightBorder2}>
                  {getLocalisationMessage(
                    "prices_for_services",
                    "Prices for services",
                  )}
                  :
                  <strong>
                    {money(allServicesSum)}
                    {getLocalisationMessage("som", "so'm")}
                  </strong>
                </span>
                <span className={classes.rightBorder2}>
                  {getLocalisationMessage("payment_done", "Payment Done")}:
                  <strong>
                    {money(paidWithStamps)}
                    {getLocalisationMessage("som", "so'm")}
                  </strong>
                </span>
              </FlexBox>
            </FlexBox>
          </CardContent>
          <CardContent>
            <FlexBox direction="column">
              <FlexBox align={ALIGN_START} justify={JUSTIFY_CENTER} gutter={16}>
                <FlexBox flex={true}>
                  <FormTextField
                    disabled={true}
                    name="paidWithStamps"
                    type="number"
                    onFocus={event => {
                      event.target.select();
                    }}
                    parse={value => {
                      if (value <= 0) {
                        return 0;
                      }

                      return String(Math.round(parseFloat(String(value))));
                    }}
                    label={getLocalisationMessage(
                      "paid_with_stamps",
                      "Paid with stamps",
                    )}
                    variant={OUTLINED}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {getLocalisationMessage("som", "so'm")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column" style={{ gap: 13 }}>
                  <FormSelectField
                    autoWidth={true}
                    fullWidth={true}
                    loading={paymentTypesIsLoading}
                    name="paymentType"
                    label={getLocalisationMessage("payment_type")}
                    options={
                      valuable
                        ? paymentTypes.filter(x => x !== NON_PAYMENT)
                        : paymentTypes
                    }
                    formatOption={x => getLocalisationMessage(x, formatText(x))}
                    disabled={
                      !paymentTypes.length || paymentType === NON_PAYMENT
                    }
                    onChange={(e, v) => {
                      if (v === CORPORATE_INTERNAL) setAllSum(0);
                      if (!edit) {
                        change("cardType", null);
                        change("pptId", null);
                        change("cardType2", null);
                        change("pptId2", null);
                      }
                    }}
                  />

                  {paymentType === CARD && (
                    <FlexBox flex={true} style={{ gap: 16 }}>
                      <FormSelectField
                        autoWidth={true}
                        fullWidth={true}
                        name="cardType"
                        disabled={
                          paymentType === CARD &&
                          order &&
                          order.get("card_type") &&
                          edit
                        }
                        label={`${getLocalisationMessage("card_type")} *`}
                        options={cardType}
                        formatOption={x =>
                          x === "PRIVATE"
                            ? getLocalisationMessage("personal")
                            : getLocalisationMessage("corporate")
                        }
                      />
                      <FormTextField
                        fullWidth={true}
                        name="pptId"
                        type="number"
                        disabled={
                          paymentType === CARD &&
                          order &&
                          order.get("ppt_id") &&
                          edit
                        }
                        onFocus={event => {
                          event.target.select();
                        }}
                        onInput={e => {
                          // eslint-disable-next-line radix
                          e.target.value = e.target.value
                            .toString()
                            .slice(0, 20);
                        }}
                        label={`${getLocalisationMessage(
                          "ppt_id",
                          "PPT ID",
                        )} *`}
                        variant={OUTLINED}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
                <FlexBox
                  flex={true}
                  direction="column"
                  style={{ minWidth: "27rem" }}
                >
                  <FlexBox>
                    <FormCheckbox
                      name="valuable"
                      style={{
                        padding: ".5rem .5rem",
                        marginLeft: "1rem",
                      }}
                      label={getLocalisationMessage("valuable")}
                      onChange={(e, v) => {
                        change("parcelValue", 0);
                        change("paymentType", "");
                        if (isNeedPayWithStamps && !v) {
                          change("paymentType", NON_PAYMENT);
                          change(
                            "paidWithStamps",
                            packagePriceTotal + notificationFee,
                          );
                        } else {
                          change("paidWithStamps", notificationFee);
                        }
                      }}
                    />
                    <FormTextField
                      disabled={!values.valuable}
                      fullWidth={true}
                      name="parcelValue"
                      type="number"
                      onFocus={event => {
                        event.target.select();
                      }}
                      parse={value => {
                        if (value <= 0) {
                          return 0;
                        }

                        return String(Math.round(parseFloat(String(value))));
                      }}
                      label={getLocalisationMessage("shipping_cost")}
                      variant={OUTLINED}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {getLocalisationMessage("som", "so'm")}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FlexBox>
                  <span>
                    {getLocalisationMessage(
                      "insurance_premium_of_the_cost_of_the_shipment",
                    )}
                    : {money(nalog)} {getLocalisationMessage("som", "so'm")}
                  </span>
                </FlexBox>
                {/* // && Boolean( */}
                {/* //   transportationType === HAND_TO_HAND || */}
                {/* //     transportationType === COURIER_SERVICE, */}
                {/* // ) */}
                {corporate &&
                  getValue(senderName, "can_create_cod_order", false) && (
                    <FlexBox
                      justify={JUSTIFY_END}
                      gutter={16}
                      style={{ marginBottom: "1rem" }}
                    >
                      <FlexBox style={{ width: 250 }}>
                        <CustomButton
                          fullWidth={true}
                          className={classes.oneLine}
                          style={{
                            height: "39px",
                            borderColor: isContentItemsNotValid && "#f44336",
                          }}
                          onClick={() => {
                            if (contentItemsLength === 0) {
                              change("contentItems", [contentItem]);
                            }
                            setIsOpenCommissionTIN(true);
                          }}
                          variant={OUTLINED}
                          color={isContentItemsNotValid ? "#f44336" : SECONDARY}
                        >
                          {`${getLocalisationMessage(
                            "cod",
                            "Cod",
                          )} (${contentItemsLength})`}
                        </CustomButton>
                      </FlexBox>
                    </FlexBox>
                  )}
                {paymentType === NON_PAYMENT && allSum > 0 && (
                  <FlexBox flex={true} direction="column" style={{ gap: 13 }}>
                    <FormSelectField
                      autoWidth={true}
                      fullWidth={true}
                      loading={paymentTypesIsLoading}
                      name="paymentType2"
                      label={getLocalisationMessage("payment_type_for_others")}
                      options={paymentTypes.filter(x => x !== NON_PAYMENT)}
                      formatOption={x =>
                        getLocalisationMessage(x, formatText(x))
                      }
                      disabled={!paymentTypes.length}
                      onChange={(e, v) => {
                        if (v === CORPORATE_INTERNAL) setAllSum(0);
                        change("cardType2", null);
                        change("pptId2", null);
                      }}
                    />

                    {paymentType2 === CARD && (
                      <FlexBox flex={true} style={{ gap: 16 }}>
                        <FormSelectField
                          autoWidth={true}
                          fullWidth={true}
                          disabled={paymentType === CARD && edit}
                          name="cardType2"
                          label={`${getLocalisationMessage("card_type")} *`}
                          options={cardType}
                          formatOption={x =>
                            x === "PRIVATE"
                              ? getLocalisationMessage("personal")
                              : getLocalisationMessage("corporate")
                          }
                        />
                        <FormTextField
                          fullWidth={true}
                          name="pptId2"
                          type="number"
                          disabled={paymentType === CARD && edit}
                          onFocus={event => {
                            event.target.select();
                          }}
                          onInput={e => {
                            // eslint-disable-next-line radix
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 20);
                          }}
                          label={`${getLocalisationMessage(
                            "ppt_id",
                            "PPT ID",
                          )} *`}
                          variant={OUTLINED}
                        />
                      </FlexBox>
                    )}
                  </FlexBox>
                )}
                <FlexBox style={{ width: 250 }}>
                  <CustomButton
                    className={classes.oneLine}
                    style={{
                      height: "39px",
                    }}
                    fullWidth={true}
                    onClick={() => setIsOpenServicesDialog(true)}
                    variant={OUTLINED}
                    color={SECONDARY}
                  >
                    {`${getLocalisationMessage(
                      "add_services",
                      "Add services",
                    )} (${totalAdditionalCount})`}
                  </CustomButton>
                </FlexBox>
              </FlexBox>

              {/*! (corporate && ( */}
              {/* transportationType === HAND_TO_HAND || */}
              {/* transportationType === COURIER_SERVICE */}
              {/* )) */}
              {!(
                (corporate &&
                  getValue(senderName, "can_create_cod_order", false)) ||
                getValue(serviceType, "category", false)
              ) && (
                <FlexBox
                  justify={JUSTIFY_END}
                  gutter={16}
                  style={{ marginBottom: "1rem" }}
                >
                  <FlexBox style={{ width: 250 }}>
                    <CustomButton
                      fullWidth={true}
                      className={classes.oneLine}
                      style={{
                        height: "39px",
                        borderColor: isContentItemsNotValid && "#f44336",
                      }}
                      onClick={() => {
                        if (contentItemsLength === 0) {
                          change("contentItems", [
                            {
                              name: "",
                              qty: 1,
                              net_weight: 0,
                              price: 0,
                              origin_country: JURISDICTION_UZBEKISTAN,
                              currency: UZB_CURRENCY,
                            },
                          ]);
                          change("nature_type", SALE_OF_GOODS);
                        }
                        setIsOpenContentItems(true);
                      }}
                      variant={OUTLINED}
                      color={isContentItemsNotValid ? "#f44336" : SECONDARY}
                    >
                      {`${getLocalisationMessage(
                        "add_inventory",
                        "Add inventory",
                      )} (${contentItemsLength})`}
                    </CustomButton>
                  </FlexBox>
                </FlexBox>
              )}
              {!(
                corporate && getValue(senderName, "can_create_cod_order", false)
              ) &&
                getValue(serviceType, "category", false) && (
                  <FlexBox
                    justify={JUSTIFY_END}
                    gutter={16}
                    style={{ marginBottom: "1rem" }}
                  >
                    <FlexBox style={{ width: 250 }}>
                      <CustomButton
                        fullWidth={true}
                        className={classes.oneLine}
                        style={{
                          height: "39px",
                          borderColor: isProductCategoriesNotValid && "#f44336",
                        }}
                        onClick={() => {
                          if (productCategories.length === 0) {
                            change("productCategories", [productCategory]);
                          }
                          setIsOpenProductCategories(true);
                        }}
                        variant={OUTLINED}
                        color={
                          isProductCategoriesNotValid ? "#f44336" : SECONDARY
                        }
                      >
                        {`${getLocalisationMessage(
                          "product_categories",
                          "Product Categories",
                        )} (${productCategories.length})`}
                      </CustomButton>
                    </FlexBox>
                  </FlexBox>
                )}
              <FlexBox
                align={ALIGN_CENTER}
                justify={edit ? JUSTIFY_END : JUSTIFY_SPACE_AROUND}
              >
                {!edit && (
                  <FlexBox>
                    <CustomButton
                      style={{
                        textTransform: "uppercase",
                      }}
                      fullWidth={true}
                      color={SECONDARY}
                      variant={OUTLINED}
                      disabled={
                        !(
                          paymentType === NON_PAYMENT ||
                          paymentType === CREDIT_BALANCE
                        ) || isProductCategoriesNotValid
                      }
                      type={valid ? "button" : "submit"}
                      onClick={() => valid && saveNotPaidPost()}
                    >
                      {isNotPaidMode
                        ? getLocalisationMessage(
                            "save_this_and_create_on_form_103",
                            "SAVE THIS AND Create on Form 103",
                          )
                        : getLocalisationMessage("create_by_form_103")}
                    </CustomButton>
                  </FlexBox>
                )}
                <FlexBox align={ALIGN_CENTER}>
                  <FlexBox style={{ minWidth: edit ? "500px" : "200px" }}>
                    {edit ? (
                      <h5>
                        {getLocalisationMessage("to_pay", "To pay")}:{" "}
                        {money(allSum)}-{money(lastTotalSum)}(
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: "normal",
                            fontStyle: "italic",
                          }}
                        >
                          последний платеж
                        </span>
                        ) ={differenceSum}
                        {getLocalisationMessage("som", "so'm")}
                      </h5>
                    ) : (
                      <h5>
                        {getLocalisationMessage("to_pay", "To pay")}:{" "}
                        {money(allSum)}
                        {getLocalisationMessage("som", "so'm")}
                      </h5>
                    )}
                  </FlexBox>
                  {isNotPaidMode ? (
                    <CustomButton
                      style={{
                        marginLeft: "1rem",
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                      }}
                      fullWidth={true}
                      color={SECONDARY}
                      variant={CONTAINED}
                      onClick={() => setIsOpenMultipleSubmit(true)}
                    >
                      {getLocalisationMessage("go_to_payment")}(
                      {notPaidPosts.length})
                    </CustomButton>
                  ) : (
                    <CustomButton
                      style={{
                        marginLeft: "1rem",
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                      }}
                      disabled={
                        isContentItemsNotValid ||
                        isProductCategoriesNotValid ||
                        !isCanEditCost ||
                        (edit && isPaid && !isCanPaymentType) ||
                        (edit && isPaid && !isCanAdditionalPaymentType)
                      }
                      type={valid ? "button" : "submit"}
                      fullWidth={true}
                      color={SECONDARY}
                      variant={CONTAINED}
                      onClick={() => valid && setIsOpenSubmitDialog(true)}
                    >
                      {edit
                        ? getLocalisationMessage("finish")
                        : getLocalisationMessage("get_paid", "Get paid")}
                    </CustomButton>
                  )}
                </FlexBox>
              </FlexBox>
              {isContentItemsNotValid && (
                <FlexBox flex={true} justify={JUSTIFY_CENTER}>
                  <h5
                    style={{
                      marginTop: "1rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: red[500] }}>
                      {getLocalisationMessage(
                        "сant_create_international_shipments_without_inventory",
                      )}
                    </span>
                  </h5>
                </FlexBox>
              )}
              {!isCanEditCost && (
                <FlexBox flex={true} justify={JUSTIFY_CENTER}>
                  <h5
                    style={{
                      marginTop: "1rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: red[500] }}>
                      {getLocalisationMessage(
                        "sorry_you_cant_edit_this_order_now_as_in_it_changes_prices_please_contact_your_controller",
                      )}
                    </span>
                    {isLoadingConnectingAVVAL ? (
                      <CircularProgress
                        size={20}
                        style={{ color: orange[500] }}
                      />
                    ) : isConnectedAVVAL ? (
                      <CheckCircle style={{ color: green[500] }} />
                    ) : (
                      <Warning style={{ color: red[500] }} />
                    )}
                  </h5>
                </FlexBox>
              )}
              {edit && isPaid && !isCanPaymentType && (
                <FlexBox flex={true} justify={JUSTIFY_CENTER}>
                  <h5
                    style={{
                      marginTop: "1rem",
                      display: "flex",
                    }}
                  >
                    <span style={{ color: red[500] }}>
                      {formatPaymentTypeLocalisation(
                        userLanguage,
                        getLocalisationMessage(order.get("payment_type")),
                      )}
                    </span>
                    {isLoadingConnectingAVVAL ? (
                      <CircularProgress
                        size={20}
                        style={{ color: orange[500] }}
                      />
                    ) : isConnectedAVVAL ? (
                      <CheckCircle style={{ color: green[500] }} />
                    ) : (
                      <Warning style={{ color: red[500] }} />
                    )}
                  </h5>
                </FlexBox>
              )}
              {edit && isPaid && !isCanAdditionalPaymentType && (
                <FlexBox flex={true} justify={JUSTIFY_CENTER}>
                  <h5
                    style={{
                      marginTop: "1rem",
                      display: "flex",
                    }}
                  >
                    <span style={{ color: red[500] }}>
                      {formatPaymentTypeLocalisation(
                        userLanguage,
                        getLocalisationMessage(
                          order.get("payment_type_additional"),
                        ),
                      )}
                    </span>
                    {isLoadingConnectingAVVAL ? (
                      <CircularProgress
                        size={20}
                        style={{ color: orange[500] }}
                      />
                    ) : isConnectedAVVAL ? (
                      <CheckCircle style={{ color: green[500] }} />
                    ) : (
                      <Warning style={{ color: red[500] }} />
                    )}
                  </h5>
                </FlexBox>
              )}
            </FlexBox>
          </CardContent>
        </FlexBox>
      </FlexBox>
    </div>
  );
};
AdminPostForm.propTypes = {
  values: PropTypes.object,
  setLocation: PropTypes.func,
  userName: PropTypes.string,
  orderBarcode: PropTypes.string,
  mapProvider: PropTypes.string,
  userWarehouseName: PropTypes.string,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  edit: PropTypes.bool,
  isLoading: PropTypes.string,
  resMessage: PropTypes.any,
  orderId: PropTypes.any,
  showErrorMessage: PropTypes.func,
  untouch: PropTypes.func,
  setIsLoading: PropTypes.func,
  setResMessage: PropTypes.func,
  initialValues: PropTypes.object,
  showSuccessMessage: PropTypes.func,
  userLanguage: PropTypes.func,
  userIdAVVAL: PropTypes.func,
  order: PropTypes.object,
  hasEditCostPermission: PropTypes.bool,
  printReceipt: PropTypes.bool,
};

export default enhancer(AdminPostForm);
