import React, { useEffect, useState } from "react";
import { Map } from "immutable";
import { compose, getContext } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { CircularProgress, makeStyles, Paper } from "@material-ui/core";
import { Collapse } from "react-collapse";
import OrderDetailsDialogHeader from "./OrderDetailsDialogHeader";
import OrderDetailsDialogTabs, {
  BRIEF_TAB,
  COD_HISTORY_TAB,
  COURIER_TAB,
  CUSTOMER_TAB,
  HISTORY_TAB,
  MAP_TAB,
  PROOF_OF_PAYMENT,
  SIGNATURE_TAB,
} from "../OrderDetailsDialogTabs";
import ModalPaper from "../../ui-core/ModalPaper";
import { renderIf } from "../../../helpers/HOCUtils";
import FlexBox, { JUSTIFY_CENTER } from "../../ui-core/FlexBox";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../../ui-core/CustomButton";
import { connect } from "react-redux";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { batchAsyncUpdateOrder } from "../../../api/admin/AdminOrderApi";
import {
  getAVVALUserId,
  getUser,
  getUserId,
  getUserJurisdiction,
  getUserWarehouse,
  getUserWarehouseId,
  hasUserPermission,
} from "../../../reducers/ProfileReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import {
  CANCELLED,
  isNotFinalStatus,
  ISSUED_TO_RECIPIENT,
  showedOrderButton,
} from "../../../constants/OrderStatusCodes";
import { getValue, isEmpty, toJS } from "../../../helpers/DataUtils";
import _ from "lodash";
import { Check, CheckCircle, DeleteForever, Warning } from "@material-ui/icons";
import {
  avvalServiceTypes,
  RefundInfo,
  serviceId,
  urlAVVAL,
} from "../../orders-core/AdminPostForm";
import { updateSinglePayment } from "../../../api/admin/AdminBatchApi";
import { CARD, CASH, freePayTypes } from "../../../constants/OrderPaymentTypes";
import {
  formatDateTimeToUrlWithSeconds,
  formatDateToUrl,
} from "../../../helpers/FormatUtils";
import {
  COD,
  CUSTOMS_FEE,
  FORWARDING_FEE,
  INSURANCE_FEE,
  NOTIFICATION_FEE,
  RTO_INTERNATI0NAL,
  SERVICE,
  SERVICE_ADDED,
  SERVICE_MAIN,
} from "../../../constants/OrderChargeItemTypes";
import { green, orange, red } from "@material-ui/core/colors";
import { getCheckId, setReceipt } from "../../../api/v2/admin/AdminOrderApi";
import { PUBLIC } from "../../../constants/NotePrivacyTypes";
import { DEFAULT_SUPPLIER } from "../../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper2";
import { API_V2_ROOT_URL } from "../../../../shared/constants/ApiConstants";
import { calculateVAT } from "./OrderDetailsDialog";
import { hasRole } from "../../../helpers/RoleUtils";
import fp from "lodash/fp";
import {
  BANDEROL,
  PARCEL,
  SMALL_PACKETS,
} from "../../../helpers/OrderOutboundSortingHelper";
import { SIMPLE } from "../../../constants/TransportationType";

export const RECEIPT_RETURN_URL = `${API_V2_ROOT_URL}/receipt_return`;
const avvalSearchUrl = "http://avval.uz/App/avval_search.php";
export const serviceTypes = [PARCEL, SMALL_PACKETS, BANDEROL];

const optionsCod = commissionTin => ({
  method: "POST",
  headers: {
    Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    search_inn_comm: {
      comm_inn: commissionTin,
    },
  }),
});

const options = (userId, date) => ({
  method: "POST",
  headers: {
    Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
    "Content-Type": "application/json",
    Cookie: "PHPSESSID=5lgnfmu5ov5vgb3b8g7tdb9rg2",
  },
  body: JSON.stringify({
    get_chekjosn: {
      user_id: userId,
      serviceId: "14ca2a5f4872973acabe338ffa068325",
      date: formatDateToUrl(date),
    },
  }),
});

const useStyles = makeStyles(theme => ({
  paper: { fontFamily: "Blogger Sans" },
  background: {
    zIndex: -10,
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    backgroundColor: "red",
  },

  topBackground: {
    zIndex: 1,
    flex: "1 1 0%",
    borderRadius: 0,
    transition: "background 0.5s ease",
    backgroundColor: theme.palette.primary.main,
  },

  bottomBackground: { backgroundColor: "#ebebeb", flex: "1 1 0%" },

  loading: {
    top: 0,
    width: "100%",
    height: "300px",
    textAlign: "center",
    lineHeight: "300px",
    position: "absolute",
    transition: "opacity 1s ease",
  },
  visibleLoader: { opacity: 1 },
  invisibleLoader: { opacity: 0, top: "2000px" },

  collapse: {
    overflow: "hidden",
    marginLeft: "-3px",
    paddingLeft: "3px",
    marginBottom: "-3px",
    paddingBottom: "3px",
    marginRight: "-3px",
    paddingRight: "3px",
  },

  container: { transition: "opacity 1s ease" },
  visibleContainer: { opacity: 1 },
  invisibleContainer: { opacity: 0 },
}));
const enhancer = compose(
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];
      return {
        hasAccessToOrderIssuance: hasUserPermission(
          state,
          "HAS_ACCESS_TO_ORDER_ISSUANCE",
        ),
        hasAccessToOrderRTOPayment: hasUserPermission(
          state,
          "RTO_INTERNATIONAL_WITH_PAYMENT",
        ),
        userIdAVVAL: getAVVALUserId(state),
        isDevelopers: hasRole(userRoles, "R42"),
        userId: getUserId(state),
        userWarehouse: toJS(getUserWarehouse(state)),
        userWarehouseId: getUserWarehouseId(state),
        userJurisdictionId: getValue(getUserJurisdiction(state), "id"),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  renderIf("open"),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
);

function OrderDetailsDialogForOrderList(props) {
  const classes = useStyles();
  const {
    getLocalisationMessage,
    userIdAVVAL,
    order,
    showErrorMessage: showErrorMessage1,
  } = props;
  const [checkID, setCheckID] = useState(0);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isCODLoadingCancel, setIsCODLoadingCancel] = useState(false);
  const [isLoadingAVVAL, setIsLoadingAVVAL] = useState(false);
  const [isDisableAVVAL, setIsDisableAVVAL] = useState(false);
  const [isLoadingConnectingAVVAL, setIsLoadingConnectingAVVAL] = useState(
    false,
  );
  const orderId = order.get("id");
  const [isConnectedAVVAL, setIsConnectedAVVAL] = useState(false);
  const [isCancelAvvalReceipt, setIsCancelAvvalReceipt] = useState(false);

  const [avvalChekInfo, setAvvalChekInfo] = useState([]);
  const barcode = order.get("barcode");
  const isPaid = order.get("paid");
  const chargeItems = toJS(order.get("charge_items")) || [];
  const oldChargeItems = toJS(order.get("avval_charge_items")) || [];
  const avvalReceipt = toJS(order.get("avval_receipt") || RefundInfo);
  const createdByUserId = order.getIn(["created_by_user", "id"]);
  const paymentType = order.get("payment_type");
  const orderStatus = order.get("status");
  const isThisUserCreatedOrder = props.userId === createdByUserId;
  const [date, setDate] = useState(new Date());
  const total = _.get(
    chargeItems.find(v => v.charge_type === "total"),
    "charge",
    0,
  );
  const transportationType = order.get("transportation_type");
  const serviceType = order.getIn(["service_type", "code"]);
  const orderWarehouseId = order.getIn(["warehouse", "id"]);
  const isOrderLocatedHere = props.userWarehouseId === orderWarehouseId;
  const isCanShowCardTypeError =
    order.get("payment_type") === CARD &&
    !order.get("card_type") &&
    !order.get("ppt_id");

  const chargeItemCOD = _.find(chargeItems, { charge_type: COD }) || {};
  const isHaveCOD = chargeItemCOD.charge > 0;
  const isPaidCOD = chargeItemCOD.paid;

  const contentItems = toJS(order.get("content_items")) || [];

  const chargeItemRTO =
    _.find(chargeItems, { charge_type: RTO_INTERNATI0NAL }) || {};
  const isPaidRTO = chargeItemRTO.paid;

  const isFreePayTypeOrTotalZero =
    freePayTypes.find(v => v === paymentType) || total === 0;
  const charges = chargeItems
    .filter(
      x =>
        x.charge_type === INSURANCE_FEE ||
        x.charge_type === CUSTOMS_FEE ||
        x.charge_type === FORWARDING_FEE ||
        x.charge_type === SERVICE ||
        x.charge_type === SERVICE_ADDED ||
        x.charge_type === NOTIFICATION_FEE,
    )
    .filter(v => v.charge);
  const oldCharges = oldChargeItems
    .filter(
      x =>
        x.charge_type === INSURANCE_FEE ||
        x.charge_type === CUSTOMS_FEE ||
        x.charge_type === FORWARDING_FEE ||
        x.charge_type === SERVICE ||
        x.charge_type === SERVICE_ADDED ||
        x.charge_type === NOTIFICATION_FEE,
    )
    .filter(v => v.charge);

  const [refreshChekId, setRefreshChekId] = useState(false);

  useEffect(() => {
    if (!isEmpty(order)) {
      if (
        (isHaveCOD || !freePayTypes.includes(paymentType)) &&
        order.get("status") !== CANCELLED
      ) {
        getCheckId().then(res => setCheckID(_.get(res, "data")));
      }
    }
  }, [order, refreshChekId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (checkID === 0) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [order, checkID]);

  useEffect(() => {
    if (userIdAVVAL) {
      setIsLoadingConnectingAVVAL(true);
      fetch(urlAVVAL, {
        method: "POST",
        body: JSON.stringify({
          method: "get-info",
          userId: userIdAVVAL,
          serviceId,
          request: {
            login: "123",
          },
        }),
      })
        .then(() => {
          setIsConnectedAVVAL(true);
        })
        .catch(() => setIsConnectedAVVAL(false))
        .finally(() => setIsLoadingConnectingAVVAL(false));
    }
  }, [userIdAVVAL]);

  useEffect(() => {
    if (props.isDevelopers && userIdAVVAL) {
      fetch(avvalSearchUrl, options(userIdAVVAL, date))
        .then(response => response.json())
        .then(res => {
          if (res && res.data && res.data.length > 0) {
            const filteribleArray = res.data
              .filter(
                item => String(item.full_json.barcode) === String(barcode),
              )
              .filter(
                i =>
                  String(i.ofd_result.mTime) !==
                    String(avvalReceipt.DateTime) &&
                  String(i.ofd_result.fID) !==
                    String(avvalReceipt.FiscalSign) &&
                  String(i.ofd_result.chTartibRaqami) !==
                    String(avvalReceipt.ReceiptSeq),
              )
              .map(data => ({
                method: "cancel-services",
                userId: getValue(data, "full_json.userId"),
                serviceId: getValue(data, "full_json.serviceId"),
                barcode: data.full_json.barcode,
                checkID: data.full_json.checkID,
                chNumber: data.full_json.chNumber,
                services: data.full_json.services,
                request: {
                  Items: getValue(data, "full_json.request.Items", []),
                  ReceivedCash: getValue(
                    data,
                    "full_json.request.ReceivedCash",
                    0,
                  ),
                  ReceivedCard: getValue(
                    data,
                    "full_json.request.ReceivedCard",
                    0,
                  ),
                  Time: formatDateTimeToUrlWithSeconds(new Date()),
                  RefundInfo: {
                    TerminalID: data.ofd_result.terminalID,
                    ReceiptSeq: data.ofd_result.chTartibRaqami,
                    DateTime: data.ofd_result.mTime,
                    FiscalSign: data.ofd_result.fID,
                    QRCodeURL: data.ofd_result.link,
                    checkID: data.ofd_result.checkID,
                  },
                  Location: getValue(data, "full_json.request.Location", {}),
                },
              }));

            setAvvalChekInfo(filteribleArray);
          }
        });
    }
  }, [date]);

  const optionsPayment = isCancel => ({
    method: "POST",
    headers: {
      Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
      "Content-Type": "application/json",
      Cookie: "PHPSESSID=5lgnfmu5ov5vgb3b8g7tdb9rg2",
    },
    body: JSON.stringify({
      search_chek: {
        barcode,
        method: isCancel ? "cancel-services" : "sale-services",
      },
    }),
  });

  const createAVVALData = isCancel => {
    // const mergeDuplicates =
    //   (field, uniqueField) =>
    //   (source = [], value) => {
    //     const target = source.find(
    //       (item) => item[uniqueField] === value[uniqueField],
    //     );
    //     if (target) target[field].push(value[field]);
    //     else source.push({ ...value, [field]: [value[field]] });
    //     return source;
    //   };
    const servicesAvval = [];
    const Items = [];
    const createService = v =>
      servicesAvval.push({
        type: _.get(v, "charge_type"),
        id: _.get(v, "id"),
        name:
          _.get(v, "name", "") && _.get(v, "name", "").length
            ? _.get(v, "name", "").slice(0, 254)
            : "",
      });
    const createRequest = (v, price, Amount) =>
      Items.push({
        Name:
          _.get(v, "name", "") && _.get(v, "name", "").length
            ? _.get(v, "name", "").slice(0, 254)
            : "",
        Barcode: _.get(v, "barcode", "$10406001001000000$"),
        Label: _.get(v, "label", ""),
        Spic: _.get(v, "spic", "10105001001000000"),
        Units: _.get(v, "units", 1221),
        Price: Number(_.multiply(price, 100).toFixed(2)),
        VatPercent: 12,
        PackageCode: "",
        CommissionInfo: {
          TIN: "",
        },
        OwnerType: 2,
        Vat: calculateVAT(price),
        Amount: Number(_.multiply(Amount, 100).toFixed(2)),
        Discount: _.get(v, "discount", 0),
        Other: _.get(v, "other", 0),
      });
    const tempCharges = isCancel ? oldCharges : charges;
    tempCharges
      .filter(x => x.payment_type === CASH || x.payment_type === CARD)
      // .reduce(mergeDuplicates("charge", "id"), [])
      .forEach(v => {
        const found = avvalServiceTypes.find(
          j => getValue(v, "charge_type") === j.type,
        );
        if (getValue(v, "charge_type") === SERVICE) {
          const temp = {
            ...v,
            charge_type: SERVICE_MAIN,
            id: order.getIn(["package_type", "id"]),
          };
          createService(temp);
          createRequest(temp, temp.charge);
        } else if (found) {
          const temp = { ...v, id: found.id, name: found.name };
          createService(temp);
          createRequest(temp, temp.charge);
        } else {
          createService(v);
          createRequest(v, v.charge);
        }
      });
    const getTotalPrice = (items, orderPaymentType) => {
      const reducedSum = items
        .filter(v => v.payment_type === orderPaymentType)
        .reduce(
          (totalReducer, item) => _.get(item, "charge") + totalReducer,
          0,
        );
      return Number(_.multiply(reducedSum, 100).toFixed(2));
    };
    const ReceivedCash = Number(getTotalPrice(tempCharges, CASH).toFixed(2));
    const ReceivedCard = Number(getTotalPrice(tempCharges, CARD).toFixed(2));

    const additionalService = chargeItems.filter(
      chargeItem => chargeItem.charge_type === SERVICE_ADDED,
    );
    const additionalPptId =
      additionalService && additionalService.length > 0
        ? additionalService[0].ppt_id
        : null;
    const additionalCardType =
      additionalService && additionalService.length > 0
        ? additionalService[0].card_type
        : null;

    return {
      method: isCancel ? "cancel-services" : "sale-services",
      userId: userIdAVVAL,
      serviceId,
      checkID,
      chNumber: order.get("chNumber", orderId),
      barcode: order.get("barcode"),
      services: servicesAvval,
      request: {
        Items,
        ReceivedCash,
        ReceivedCard,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo: isCancel ? avvalReceipt : RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            order.get("card_type") === "CORPORATIVE"
              ? 1
              : order.get("card_type") === "PRIVATE"
              ? 2
              : additionalCardType === "CORPORATIVE"
              ? 1
              : additionalCardType === "PRIVATE"
              ? 2
              : "",
          PPTID: order.get("ppt_id") || additionalPptId,
        },
      },
    };
  };

  const createAVVALDataCOD = QQS => {
    const customerName = order.getIn(["customer", "name"]);
    const servicesCod = [];
    const commissionINNItems = [];

    contentItems.map(item => {
      const vat = parseFloat(
        ((Number(_.get(item, "price")) * Number(_.get(item, "qty"))) / 1.12) *
          0.12,
      ).toFixed(2);
      const Amount = Number(_.get(item, "qty")) * 1000;
      const Price =
        Number(_.get(item, "qty")) * Number(_.get(item, "price", 0)) * 100;
      servicesCod.push({
        type: "COD",
        id: Number(_.get(item, "service_id", "")),
        name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
      });
      return commissionINNItems.push({
        Name:
          _.get(item, "name", "") && _.get(item, "name", "").length
            ? _.get(item, "name", "").slice(0, 254)
            : "",
        Barcode: _.get(item, "barcode", "$10406001001000000$"),
        Label: "",
        Spic: _.get(item, "spic", "10105001001000000"),
        Units: _.get(item, "units", 1221),
        Price,
        VatPercent: QQS || 0,
        PackageCode: _.get(item, "package_code", 0),
        CommissionInfo: {
          TIN:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 9
              ? _.get(item, "commission_tin", "")
              : "",
          PINFL:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 14
              ? _.get(item, "commission_tin", "")
              : "",
        },
        Vat: QQS > 0 ? Number((vat * 100).toFixed(0)) : 0,
        Amount,
        OwnerType: 0,
        Discount: _.get(item, "discount", 0),
        Other: _.get(item, "other", 0),
      });
    });

    const ReceivedCashCOD =
      _.get(chargeItemCOD, "payment_type") === CASH
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCardCOD =
      _.get(chargeItemCOD, "payment_type") === CARD
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;

    return {
      method: "cancel-services",
      userId: userIdAVVAL,
      serviceId,
      chNumber: order.get("chNumber", orderId),
      barcode: order.get("barcode"),
      checkID,
      services: servicesCod,
      request: {
        Items: commissionINNItems,
        ReceivedCash: ReceivedCashCOD,
        ReceivedCard: ReceivedCardCOD,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo: avvalReceipt,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            _.get(chargeItemCOD, "card_type") === "CORPORATIVE"
              ? 1
              : _.get(chargeItemCOD, "card_type") === "PRIVATE"
              ? 2
              : "",
          PPTID: _.get(chargeItemCOD, "ppt_id"),
        },
      },
    };
  };

  const checkAVVALres = response => {
    if (response === null) {
      showErrorMessage1(
        getLocalisationMessage(
          "unexpected_error_occurred_and_payment_failed_please_contact_your_manage_or_try_later",
        ),
      );
      return false;
    } else if (
      response &&
      getValue(response, "resInfo.result.QRCodeURL") === null
    ) {
      showErrorMessage1(
        getLocalisationMessage("the_payment_was_not_successful"),
      );
      return false;
    } else if (_.get(response, "msg") === "error") {
      const errorMessage = `${getLocalisationMessage(
        "error_msg_from_avval",
      )} : ${_.get(response, "resInfo.message")}`;
      showErrorMessage1(errorMessage);
      return false;
    }
    return true;
  };

  let refreshList;
  useEffect(() => {
    if (isLoadingCancel) {
      if (order.get("status") !== CANCELLED) {
        refreshList = setInterval(() => props.onRequestRefresh(), 1500);
      } else {
        setTimeout(() => setIsLoadingAVVAL(false), 500);
      }
    }
    return () => clearInterval(refreshList);
  }, [isLoadingCancel, order, props.onRequestRefresh]);

  return (
    <ModalPaper
      open={props.open}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.paper}
    >
      <div
        className={cx(classes.loading, {
          [classes.invisibleLoader]: !props.isLoading,
          [classes.visibleLoader]: props.isLoading,
        })}
      >
        Loading...
      </div>

      <div
        className={cx(classes.container, {
          [classes.visibleContainer]: !props.isLoading,
          [classes.invisibleContainer]: props.isLoading,
        })}
      >
        <div className={classes.background}>
          <Paper
            className={classes.topBackground}
            // style={{
            //   backgroundColor: darken(props.theme.palette.primary.main, 0.2),
            // }}
          />
          <div className={classes.bottomBackground} />
        </div>

        <OrderDetailsDialogHeader
          order={order}
          getDriverUrl={props.getDriverUrl}
          getSupplierUrl={props.getSupplierUrl}
          getCustomerUrl={props.getCustomerUrl}
          getReverseUrl={id => props.getReverseUrl(id)}
          setDate={setDate}
          isDevelopers={props.isDevelopers}
          date={date}
        />

        <OrderDetailsDialogTabs
          tab={props.tab}
          withoutCourierTab={props.withoutCourierTab}
          order={order}
          onTabChange={props.onTabChange}
          onEditClick={props.onEditClick}
          createDriverSuggestHref={props.createDriverSuggestHref}
          onShowSuggestedDriverLink={props.onShowSuggestedDriverLink}
          withoutSignaturesTab={props.withoutSignaturesTab}
          onCancelOrderClick={props.onCancelOrderClick}
          isOrderClosed={props.isOrderClosed}
          withoutCustomerTab={props.withoutCustomerTab}
          withoutMap={props.withoutMap}
          withCODHistory={props.withCODHistory}
          withTaxInvoice={props.withTaxInvoice}
        />

        {orderId > 0 && (
          <Collapse isOpened={!props.isLoading}>{props.children}</Collapse>
        )}
        <FlexBox
          direction="column"
          flex={true}
          style={{ marginBottom: "1rem" }}
        >
          {!isFreePayTypeOrTotalZero && !isPaid && (
            <FlexBox
              style={{ marginBottom: "0.5rem" }}
              justify={JUSTIFY_CENTER}
              flex={true}
            >
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                    color: isLoadingConnectingAVVAL
                      ? orange[500]
                      : isConnectedAVVAL
                      ? green[500]
                      : red[500],
                  }}
                >
                  {getLocalisationMessage(
                    !userIdAVVAL
                      ? "user_profile_is_not_connected_with_avval_profile"
                      : isLoadingConnectingAVVAL
                      ? "connecting_to_avval"
                      : isConnectedAVVAL
                      ? "user_is_connected_to_avval"
                      : "user_is_not_connected_to_avval",
                  )}
                </span>
                {isLoadingConnectingAVVAL ? (
                  <CircularProgress size={20} style={{ color: orange[500] }} />
                ) : isConnectedAVVAL ? (
                  <CheckCircle style={{ color: green[500] }} />
                ) : (
                  <Warning style={{ color: red[500] }} />
                )}
              </h5>
            </FlexBox>
          )}
          {!isFreePayTypeOrTotalZero && !isPaid && isCanShowCardTypeError && (
            <FlexBox
              style={{ marginBottom: "0.5rem" }}
              justify={JUSTIFY_CENTER}
              flex={true}
            >
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                    color: isLoadingConnectingAVVAL
                      ? orange[500]
                      : isConnectedAVVAL
                      ? green[500]
                      : red[500],
                  }}
                >
                  {getLocalisationMessage("the_accept_the_payment_first_edit")}
                </span>
                {isLoadingConnectingAVVAL ? (
                  <CircularProgress size={20} style={{ color: orange[500] }} />
                ) : isConnectedAVVAL ? (
                  <CheckCircle style={{ color: green[500] }} />
                ) : (
                  <Warning style={{ color: red[500] }} />
                )}
              </h5>
            </FlexBox>
          )}
          {isPaid ? (
            <FlexBox justify={JUSTIFY_CENTER} flex={true}>
              {isThisUserCreatedOrder &&
                isNotFinalStatus(orderStatus) &&
                isOrderLocatedHere &&
                showedOrderButton(orderStatus) &&
                orderStatus !== CANCELLED && (
                  <CustomButton
                    style={{ margin: "0 .5rem" }}
                    disabled={isLoadingCancel}
                    variant={CONTAINED}
                    color={SECONDARY}
                    onClick={() => {
                      setIsLoadingCancel(true);
                      if (isFreePayTypeOrTotalZero) {
                        batchAsyncUpdateOrder({
                          privacy: PUBLIC,
                          supplier: DEFAULT_SUPPLIER,
                          warehouse: props.userWarehouse,
                          type: null,
                          batch_type: "inactive",
                          order_barcodes: [barcode],
                          order_status: CANCELLED,
                        })
                          .then(res => {
                            if (res) {
                              props.showSuccessMessage(
                                getLocalisationMessage("successfully"),
                              );

                              setTimeout(() => {
                                if (props.refreshOrderList) {
                                  props.refreshOrderList();
                                }
                              }, 1500);
                            }
                          })
                          .catch(error => {
                            showErrorMessage1(error);
                            setIsLoadingCancel(false);
                          });
                      } else {
                        fetch(avvalSearchUrl, optionsPayment(true))
                          .then(response => response.json())
                          .then(rescheck => {
                            if (rescheck && rescheck.res_id === 103) {
                              setReceipt(orderId, {
                                check_number: orderId,
                                type: "CANCEL",
                                receipt: {
                                  TerminalID: getValue(
                                    rescheck,
                                    "data.terminal_id",
                                  ),
                                  ReceiptSeq: getValue(
                                    rescheck,
                                    "data.tartib_raqami",
                                  ),
                                  DateTime: getValue(
                                    rescheck,
                                    "data.date_time",
                                  ),
                                  FiscalSign: getValue(
                                    rescheck,
                                    "data.fiskal_belgi",
                                  ),
                                  AppletVersion: getValue(
                                    rescheck,
                                    "data.versiya",
                                  ),
                                  QRCodeURL: getValue(rescheck, "data.link"),
                                  checkID,
                                },
                              })
                                .then(resReceipt => {
                                  if (resReceipt) {
                                    batchAsyncUpdateOrder({
                                      privacy: PUBLIC,
                                      supplier: DEFAULT_SUPPLIER,
                                      warehouse: props.userWarehouse,
                                      type: null,
                                      batch_type: "inactive",
                                      order_barcodes: [barcode],
                                      order_status: CANCELLED,
                                    })
                                      .then(resData => {
                                        if (resData) {
                                          props.showSuccessMessage(
                                            getLocalisationMessage(
                                              "successfully",
                                            ),
                                          );

                                          setTimeout(() => {
                                            if (props.refreshOrderList) {
                                              props.refreshOrderList();
                                            }
                                          }, 1500);
                                        }
                                      })
                                      .catch(error => {
                                        showErrorMessage1(error);
                                        setIsLoadingCancel(false);
                                      });
                                  }

                                  setRefreshChekId(prev => !prev);
                                })
                                .catch(err => {
                                  showErrorMessage1(err);
                                  setIsLoadingCancel(false);
                                });
                            } else {
                              fetch(urlAVVAL, {
                                method: "POST",
                                body: JSON.stringify(createAVVALData(true)),
                              })
                                .then(response => response.json())
                                .then(res => {
                                  if (res && getValue(res, "code") === "1") {
                                    showErrorMessage1(
                                      getLocalisationMessage(
                                        fp.toString(
                                          getValue(res, "resInfo.code"),
                                        ),
                                      ),
                                    );
                                    setIsLoadingCancel(false);
                                  } else if (
                                    res &&
                                    getValue(res, "code") === "-1"
                                  ) {
                                    showErrorMessage1(
                                      getLocalisationMessage(
                                        fp.toString(getValue(res, "resInfo")),
                                      ),
                                    );
                                    setIsLoadingCancel(false);
                                  } else if (
                                    res &&
                                    (getValue(
                                      res,
                                      "resInfo.result.QRCodeURL",
                                    ) === null ||
                                      !getValue(
                                        res,
                                        "resInfo.result.QRCodeURL",
                                      ))
                                  ) {
                                    showErrorMessage1(
                                      getLocalisationMessage(
                                        "the_payment_was_not_successful",
                                      ),
                                    );
                                    setIsLoadingCancel(false);
                                  } else if (checkAVVALres(res)) {
                                    setReceipt(orderId, {
                                      check_number: orderId,
                                      type: "CANCEL",
                                      receipt: {
                                        ..._.get(res, "resInfo.result"),
                                        checkID,
                                      },
                                    })
                                      .then(resReceipt => {
                                        if (resReceipt) {
                                          batchAsyncUpdateOrder({
                                            privacy: PUBLIC,
                                            supplier: DEFAULT_SUPPLIER,
                                            warehouse: props.userWarehouse,
                                            type: null,
                                            batch_type: "inactive",
                                            order_barcodes: [barcode],
                                            order_status: CANCELLED,
                                          })
                                            .then(resData => {
                                              if (resData) {
                                                props.showSuccessMessage(
                                                  getLocalisationMessage(
                                                    "successfully",
                                                  ),
                                                );

                                                setTimeout(() => {
                                                  if (props.refreshOrderList) {
                                                    props.refreshOrderList();
                                                  }
                                                }, 1500);
                                              }
                                            })
                                            .catch(error => {
                                              showErrorMessage1(error);
                                              setIsLoadingCancel(false);
                                            });
                                        }

                                        setRefreshChekId(prev => !prev);
                                      })
                                      .catch(err => {
                                        showErrorMessage1(err);
                                        setIsLoadingCancel(false);
                                      });
                                  } else {
                                    setIsLoadingCancel(false);
                                  }
                                })
                                .catch(() => {
                                  showErrorMessage1(
                                    getLocalisationMessage(
                                      "user_is_not_connected_to_avval",
                                    ),
                                  );
                                  setIsLoadingCancel(false);
                                });
                            }
                          })
                          .catch(() => {
                            setTimeout(() => setIsLoadingCancel(false), 1500);
                          });
                      }
                    }}
                    endIcon={
                      isLoadingCancel ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        <DeleteForever />
                      )
                    }
                  >
                    {getLocalisationMessage("cancel_order")}
                  </CustomButton>
                )}

              {props.isDevelopers && isPaidCOD && isHaveCOD && (
                <CustomButton
                  style={{ margin: "0 .5rem" }}
                  disabled={isCODLoadingCancel}
                  variant={CONTAINED}
                  color={SECONDARY}
                  onClick={() => {
                    setIsCODLoadingCancel(true);
                    fetch(avvalSearchUrl, optionsPayment(true))
                      .then(response => response.json())
                      .then(rescheck => {
                        if (rescheck && rescheck.res_id === 103) {
                          setReceipt(orderId, {
                            check_number: orderId,
                            type: "CANCEL",
                            receipt: {
                              TerminalID: getValue(
                                rescheck,
                                "data.terminal_id",
                              ),
                              ReceiptSeq: getValue(
                                rescheck,
                                "data.tartib_raqami",
                              ),
                              DateTime: getValue(rescheck, "data.date_time"),
                              FiscalSign: getValue(
                                rescheck,
                                "data.fiskal_belgi",
                              ),
                              AppletVersion: getValue(rescheck, "data.versiya"),
                              QRCodeURL: getValue(rescheck, "data.link"),
                              checkID,
                            },
                          })
                            .then(() => {
                              setRefreshChekId(prev => !prev);
                            })
                            .catch(err => {
                              showErrorMessage1(err);
                              setIsCODLoadingCancel(false);
                            });
                        } else {
                          fetch(
                            avvalSearchUrl,
                            optionsCod(contentItems[0].commission_tin),
                          )
                            .then(response => response.json())
                            .then(resAvval => {
                              if (resAvval && resAvval.mess === "success") {
                                const QQS = resAvval.data.nds_active ? 12 : 0;
                                fetch(urlAVVAL, {
                                  method: "POST",
                                  body: JSON.stringify(createAVVALDataCOD(QQS)),
                                })
                                  .then(response => response.json())
                                  .then(res => {
                                    if (res && getValue(res, "code") === "1") {
                                      showErrorMessage1(
                                        getLocalisationMessage(
                                          fp.toString(
                                            getValue(res, "resInfo.code"),
                                          ),
                                        ),
                                      );
                                      setIsCODLoadingCancel(false);
                                    } else if (
                                      res &&
                                      getValue(res, "code") === "-1"
                                    ) {
                                      showErrorMessage1(
                                        getLocalisationMessage(
                                          fp.toString(getValue(res, "resInfo")),
                                        ),
                                      );
                                      setIsCODLoadingCancel(false);
                                    } else if (
                                      res &&
                                      (getValue(
                                        res,
                                        "resInfo.result.QRCodeURL",
                                      ) === null ||
                                        !getValue(
                                          res,
                                          "resInfo.result.QRCodeURL",
                                        ))
                                    ) {
                                      showErrorMessage1(
                                        getLocalisationMessage(
                                          "the_payment_was_not_successful",
                                        ),
                                      );
                                      setIsCODLoadingCancel(false);
                                    } else if (checkAVVALres(res)) {
                                      setReceipt(orderId, {
                                        check_number: orderId,
                                        type: "CANCEL",
                                        receipt: {
                                          ..._.get(res, "resInfo.result"),
                                          checkID,
                                        },
                                      })
                                        .then(() => {
                                          setRefreshChekId(prev => !prev);
                                        })
                                        .catch(err => {
                                          showErrorMessage1(err);
                                          setIsCODLoadingCancel(false);
                                        });
                                    } else {
                                      setIsCODLoadingCancel(false);
                                    }
                                  })
                                  .catch(() => {
                                    showErrorMessage1(
                                      getLocalisationMessage(
                                        "user_is_not_connected_to_avval",
                                      ),
                                    );
                                    setIsLoadingCancel(false);
                                  });
                              }
                            });
                        }
                      })
                      .catch(() => {
                        setTimeout(() => setIsCODLoadingCancel(false), 1500);
                      });
                  }}
                  endIcon={
                    isLoadingCancel ? (
                      <CircularProgress size={20} color="secondary" />
                    ) : (
                      <DeleteForever />
                    )
                  }
                >
                  {getLocalisationMessage("cancel_cod", "Cancel COD")}
                </CustomButton>
              )}

              {showedOrderButton(orderStatus) &&
                isNotFinalStatus(orderStatus) &&
                isOrderLocatedHere &&
                orderStatus !== ISSUED_TO_RECIPIENT && (
                  <CustomButton
                    style={{ margin: "0 .5rem" }}
                    variant={OUTLINED}
                    onClick={() => {
                      props.setIsOpenRedirect(true);
                      props.setIsOpenBrief(false);
                    }}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage("redirect_order")}
                  </CustomButton>
                )}
              {showedOrderButton(orderStatus) &&
                isNotFinalStatus(orderStatus) &&
                isOrderLocatedHere &&
                orderStatus !== ISSUED_TO_RECIPIENT && (
                  <CustomButton
                    style={{ margin: "0 .5rem" }}
                    variant={OUTLINED}
                    onClick={() => {
                      props.setIsOpenRTO(true);
                      props.setIsOpenBrief(false);
                    }}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage("return")}
                  </CustomButton>
                )}
              {showedOrderButton(orderStatus) &&
                isOrderLocatedHere &&
                isNotFinalStatus(orderStatus) && (
                  <CustomButton
                    style={{ margin: "0 .5rem" }}
                    variant={OUTLINED}
                    onClick={() => {
                      props.setIsOpenDispatch(true);
                      props.setIsOpenBrief(false);
                    }}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage("need_dispatch")}
                  </CustomButton>
                )}
              {!isPaidRTO &&
                showedOrderButton(orderStatus) &&
                isOrderLocatedHere &&
                isNotFinalStatus(orderStatus) &&
                transportationType !== SIMPLE &&
                serviceTypes.includes(serviceType) &&
                Boolean(props.hasAccessToOrderRTOPayment) && (
                  <CustomButton
                    style={{ margin: "0 .5rem", width: "200px" }}
                    variant={CONTAINED}
                    onClick={() => {
                      props.setIsOpenRTOPrice(true);
                      props.setIsOpenBrief(false);
                    }}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage(
                      "payment_for_rto",
                      "Payment for RTO",
                    )}
                  </CustomButton>
                )}
              {props.isDevelopers &&
                avvalChekInfo &&
                avvalChekInfo.length > 0 &&
                avvalChekInfo.map((avvalChek, index) => (
                  <CustomButton
                    key={index}
                    style={{ margin: "0 .5rem" }}
                    onClick={() => {
                      setIsCancelAvvalReceipt(true);
                      fetch(urlAVVAL, {
                        method: "POST",
                        body: JSON.stringify(avvalChek),
                      })
                        .then(response => response.json())
                        .then(res => {
                          if (res && getValue(res, "code") === "-1") {
                            showErrorMessage1(getValue(res, "resInfo"));
                            setTimeout(
                              () => setIsCancelAvvalReceipt(false),
                              1000,
                            );
                          } else if (
                            res &&
                            (getValue(res, "resInfo.result.QRCodeURL") ===
                              null ||
                              !getValue(res, "resInfo.result.QRCodeURL"))
                          ) {
                            showErrorMessage1(
                              getLocalisationMessage(
                                "the_payment_was_not_successful",
                              ),
                            );
                            setTimeout(
                              () => setIsCancelAvvalReceipt(false),
                              1000,
                            );
                          } else if (res && res.msg === "succes") {
                            props.showSuccessMessage("Chek qaytarildi");
                            setTimeout(
                              () => setIsCancelAvvalReceipt(false),
                              1500,
                            );
                          }
                        })
                        .catch(() => {
                          showErrorMessage1(
                            getLocalisationMessage(
                              "user_is_not_connected_to_avval",
                            ),
                          );
                          setTimeout(
                            () => setIsCancelAvvalReceipt(false),
                            1000,
                          );
                        });
                    }}
                    variant={CONTAINED}
                    color={SECONDARY}
                    disabled={isCancelAvvalReceipt}
                    endIcon={
                      isCancelAvvalReceipt ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        <DeleteForever />
                      )
                    }
                  >
                    {`${getLocalisationMessage(
                      "cancel_receipt",
                      "Cancel Receipt",
                    )} ${avvalChek.chNumber} #${index + 1}`}
                  </CustomButton>
                ))}
              {showedOrderButton(orderStatus) &&
                isOrderLocatedHere &&
                isNotFinalStatus(orderStatus) &&
                Boolean(
                  props.hasAccessToOrderIssuance ||
                    props.userJurisdictionId ===
                      order.getIn(["to_jurisdiction", "id"]),
                ) && (
                  <CustomButton
                    style={{ margin: "0 .5rem", width: "200px" }}
                    variant={CONTAINED}
                    onClick={() => {
                      props.setIsOpenIssuance(true);
                      props.setIsOpenBrief(false);
                    }}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage("issuance")}
                  </CustomButton>
                )}
            </FlexBox>
          ) : (
            <FlexBox justify={JUSTIFY_CENTER} flex={true}>
              <CustomButton
                disabled={
                  isLoadingAVVAL ||
                  isDisableAVVAL ||
                  (order.get("payment_type") === CARD &&
                    !order.get("ppt_id") &&
                    !order.get("card_type"))
                }
                className={classes.btn}
                variant={CONTAINED}
                color={SECONDARY}
                onClick={() => {
                  setIsLoadingAVVAL(true);
                  setIsDisableAVVAL(true);
                  if (isFreePayTypeOrTotalZero) {
                    updateSinglePayment(orderId)
                      .then(res => {
                        if (res) {
                          props.showSuccessMessage(
                            getLocalisationMessage(
                              "successfully_created",
                              "Successfully Created",
                            ),
                          );
                          props.onRequestRefresh();
                          setTimeout(() => setIsLoadingAVVAL(false), 1500);

                          if (props.refreshOrderList) {
                            props.refreshOrderList();
                          }
                        }
                      })
                      .catch(error => {
                        setIsDisableAVVAL(false);
                        showErrorMessage1(error);
                        setTimeout(() => setIsLoadingAVVAL(false), 1500);
                      });
                  } else {
                    fetch(avvalSearchUrl, optionsPayment())
                      .then(response => response.json())
                      .then(rescheck => {
                        if (rescheck && rescheck.res_id === 103) {
                          setReceipt(orderId, {
                            check_number: orderId,
                            receipt: {
                              TerminalID: getValue(
                                rescheck,
                                "data.terminal_id",
                              ),
                              ReceiptSeq: getValue(
                                rescheck,
                                "data.tartib_raqami",
                              ),
                              DateTime: getValue(rescheck, "data.date_time"),
                              FiscalSign: getValue(
                                rescheck,
                                "data.fiskal_belgi",
                              ),
                              AppletVersion: getValue(rescheck, "data.versiya"),
                              QRCodeURL: getValue(rescheck, "data.link"),
                              checkID,
                            },
                            type: "SALE",
                          })
                            .then(resAvval => {
                              if (resAvval) {
                                props.onRequestRefresh();

                                if (props.refreshOrderList) {
                                  props.refreshOrderList();
                                }
                                setTimeout(
                                  () => setIsLoadingAVVAL(false),
                                  1500,
                                );
                                props.showSuccessMessage(
                                  getLocalisationMessage(
                                    "successfully_created",
                                    "Successfully Created",
                                  ),
                                );

                                setRefreshChekId(prev => !prev);
                              }
                            })
                            .catch(error => {
                              showErrorMessage1(error);
                            });
                        } else {
                          fetch(urlAVVAL, {
                            method: "POST",
                            body: JSON.stringify(createAVVALData()),
                          })
                            .then(response => response.json())
                            .then(res => {
                              if (res && getValue(res, "code") === "1") {
                                showErrorMessage1(
                                  getLocalisationMessage(
                                    fp.toString(getValue(res, "resInfo.code")),
                                  ),
                                );
                                setIsDisableAVVAL(false);
                                setTimeout(
                                  () => setIsLoadingAVVAL(false),
                                  1500,
                                );
                              } else if (
                                res &&
                                getValue(res, "code") === "-1"
                              ) {
                                showErrorMessage1(
                                  getLocalisationMessage(
                                    fp.toString(getValue(res, "resInfo")),
                                    getValue(res, "resInfo"),
                                  ),
                                );
                                setIsDisableAVVAL(false);
                                setTimeout(
                                  () => setIsLoadingAVVAL(false),
                                  1500,
                                );
                              } else if (
                                res &&
                                (getValue(res, "resInfo.result.QRCodeURL") ===
                                  null ||
                                  !getValue(res, "resInfo.result.QRCodeURL"))
                              ) {
                                showErrorMessage1(
                                  getLocalisationMessage(
                                    "the_payment_was_not_successful",
                                  ),
                                );
                                setIsDisableAVVAL(false);
                                setTimeout(
                                  () => setIsLoadingAVVAL(false),
                                  1500,
                                );
                              } else if (res && res.msg === "succes") {
                                if (checkAVVALres(res)) {
                                  setReceipt(orderId, {
                                    check_number: orderId,
                                    receipt: {
                                      ..._.get(res, "resInfo.result"),
                                      checkID,
                                    },
                                    type: "SALE",
                                  })
                                    .then(resAvval => {
                                      if (resAvval) {
                                        props.onRequestRefresh();

                                        if (props.refreshOrderList) {
                                          props.refreshOrderList();
                                        }
                                        setTimeout(
                                          () => setIsLoadingAVVAL(false),
                                          1500,
                                        );
                                        props.showSuccessMessage(
                                          getLocalisationMessage(
                                            "successfully_created",
                                            "Successfully Created",
                                          ),
                                        );
                                      }

                                      setRefreshChekId(prev => !prev);
                                    })
                                    .catch(error => {
                                      showErrorMessage1(error);
                                    });
                                }
                              } else {
                                setIsDisableAVVAL(false);
                                setTimeout(
                                  () => setIsLoadingAVVAL(false),
                                  1500,
                                );
                              }
                            })
                            .catch(() => {
                              setIsDisableAVVAL(false);
                              showErrorMessage1(
                                getLocalisationMessage(
                                  "user_is_not_connected_to_avval",
                                ),
                              );
                              setTimeout(() => setIsLoadingAVVAL(false), 1500);
                            });
                        }
                      })
                      .catch(() => {
                        setIsDisableAVVAL(false);
                        setTimeout(() => setIsLoadingAVVAL(false), 1500);
                      });
                  }
                }}
                endIcon={
                  isLoadingAVVAL ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <Check />
                  )
                }
              >
                {getLocalisationMessage(
                  isFreePayTypeOrTotalZero
                    ? "accept_payment"
                    : "pay_with_avval",
                )}
              </CustomButton>
            </FlexBox>
          )}
        </FlexBox>
      </div>
    </ModalPaper>
  );
}

OrderDetailsDialogForOrderList.propTypes = {
  open: PropTypes.bool,
  setIsOpenBrief: PropTypes.func,
  setIsOpenDispatch: PropTypes.func,
  setIsOpenRTO: PropTypes.func,
  setIsOpenRedirect: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  onTabChange: PropTypes.func.isRequired,
  tab: PropTypes.oneOf([
    BRIEF_TAB,
    HISTORY_TAB,
    CUSTOMER_TAB,
    COURIER_TAB,
    MAP_TAB,
    SIGNATURE_TAB,
    COD_HISTORY_TAB,
    PROOF_OF_PAYMENT,
  ]),
  onEditClick: PropTypes.func,
  createDriverSuggestHref: PropTypes.func,
  onShowSuggestedDriverLink: PropTypes.bool,
  onCancelOrderClick: PropTypes.func,
  children: PropTypes.node,
  withoutCourierTab: PropTypes.bool,
  withoutSignaturesTab: PropTypes.bool,
  withoutCustomerTab: PropTypes.bool,
  getDriverUrl: PropTypes.func,
  getSupplierUrl: PropTypes.func,
  getReverseUrl: PropTypes.func,
  getCustomerUrl: PropTypes.func,
  isOrderClosed: PropTypes.bool,
  withoutMap: PropTypes.bool,
  withCODHistory: PropTypes.bool,
  withTaxInvoice: PropTypes.bool,
  setIsOpenIssuance: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  userWarehouseId: PropTypes.number,
  userJurisdictionId: PropTypes.number,
  hasAccessToOrderIssuance: PropTypes.bool,
  hasAccessToOrderRTOPayment: PropTypes.bool,
  isDevelopers: PropTypes.bool,
  userIdAVVAL: PropTypes.number,
  refreshOrderList: PropTypes.func,
  setIsOpenRTOPrice: PropTypes.func,
  userWarehouse: PropTypes.object,
  userId: PropTypes.number,
};
export default enhancer(OrderDetailsDialogForOrderList);
