export const COD = "cod";
export const COD_FEE = "cod_fee";

export const TOTAL = "total";
export const DISCOUNT = "discount";

export const SERVICE = "service";
export const SERVICE_MAIN = "service_main";
export const RTO = "rto";
export const SERVICE_CUSTOM = "service_custom";

export const STAMP = "stamp";
export const SERVICE_ADDED = "service_added";
export const NOTIFICATION_FEE = "notification_fee";
export const CUSTOMS_FEE = "customs_fee";
export const FORWARDING_FEE = "forwarding_fee";
export const INSURANCE_FEE = "insurance_fee";

export const DRIVER_TIP = "driver_tip";

export const DRIVER_FEE = "driver_fee";
export const RTO_INTERNATI0NAL = "rto_international";
