import { Observable } from "rxjs";
import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import WingApi from "../../helpers/WingApi";
import DataListFilter from "../../helpers/DataListFilter";
import { CARD } from "../../constants/OrderPaymentTypes";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const ORDER_URL = `${API_ROOT_URL}/admin/order`;
const ORDER_LIST_URL = `${API_V2_ROOT_URL}/admin/orders`;
const ORDER_ITEM_URL = `${API_ROOT_URL}/admin/order/%s`;
const ORDER_SYNC_URL = `${API_ROOT_URL}/admin/order/sync/%s`;
const ORDER_NOTES_WITHOUT_CHARGE_UPDATE = `${API_ROOT_URL}/admin/order/%s/notes_without_charge_update`;
const ORDER_COD_NOTES_URL = `${API_ROOT_URL}/admin/order/%s/notes_with_charge_update`;
const BATCH_CREATE_NOTE_URL = `${API_ROOT_URL}/admin/order/notes`;
const BATCH_SET_ORDER_SIZE_URL = `${API_ROOT_URL}/admin/orders/size`;
const ORDER_CHARGE_URL = `${API_ROOT_URL}/admin/order/%s/charge_item`;
const ORDER_EXCEPTIONS_URL = `${API_ROOT_URL}/admin/order/exceptions`;
const ORDER_HISTORY_URL = `${API_ROOT_URL}/admin/order/%s/history_items`;
const BATCH_ORDER_RESCHEDULE_URL = `${API_ROOT_URL}/admin/orders/deadline`;
const BATCH_UPDATE_ORDER_URL = `${API_ROOT_URL}/admin/orders/batch_update`;
const UPDATE_RECIPIENT_POSTCODE_URL = `${API_V2_ROOT_URL}/admin/order/location/change`;
const UPDATE_SENDER_POSTCODE_URL = `${API_V2_ROOT_URL}/admin/order/sender/postcode`;
const COMPLETE_BATCH_UPDATE = `${API_ROOT_URL}/admin/batch_update/complete`;
const BATCH_UPDATE_WAREHOUSE_URL = `${API_ROOT_URL}/admin/orders/warehouse`;
const ORDER_PRIVATE_NOTE_URL = `${API_ROOT_URL}/admin/order/%s/private_note`;
const ORDER_SOLVE_EXCEPTIONS_URL = `${API_ROOT_URL}/admin/order/solve_exceptions`;
const CALCULATE_TOTAL_URL = `${API_ROOT_URL}/admin/orders/calculate_cod_total`;
const ORDER_ITEM_SIGNATURE_URL = `${API_ROOT_URL}/admin/admin/order/%s/signature`;
const ORDER_ITEM_ISSUANCE_URL = `${API_V2_ROOT_URL}/admin/order/%s/issuanceOrder`;
const ORDER_RTO_INTERNATIONAL_URL = `${API_V2_ROOT_URL}/admin/order/rto_international`;
const ORDER_ITEM_POP_URL = `${API_V2_ROOT_URL}/driver/order/`;
const ORDER_COD_HISTORY_ITEMS_URL = `${API_ROOT_URL}/admin/order/%s/finance_history_items`;
const OVERWEIGHT_UPLOAD_CSV_URL = `${API_ROOT_URL}/admin/orders/upload_overweight_csv`;
const UPDATE_WEIGHT_UPLOAD_CSV_URL = `${API_ROOT_URL}/admin/orders/update_order_weight`;
const BATCH_ASSIGN_ORDER_SUPPLIER_URL = `${API_ROOT_URL}/admin/orders/batch_assign_supplier`;
const ASSIGN_ORDER_TO_SORTING_FACILITY = `${API_ROOT_URL}/admin/order/%s/in_sorting_facility`;
const BATCH_UPDATE_PICK_UP_DRIVER_URL = `${API_ROOT_URL}/admin/orders/batch_update_pick_up_driver`;
const ORDER_DRIVERS_SUGGESTION_URL = `${API_ROOT_URL}/admin/order/%s/driver/suggestion/`;
const ORDER_OTP_VERIFICATION_URL = `${API_V2_ROOT_URL}/admin/order/otp/verification`;

const LOCAL_SORTING_CODE_URL = `https://cache.wing.ae/api/v1/sorting/code/%s`;

const ORDER_SCANNED_PIECES = `${API_ROOT_URL}/admin/admin/order/%s/piece_scanned`;

const ORDER_PIECES_SUMMARY_URL = `${API_ROOT_URL}/admin/customer/order/%s/pieces_summary`;

const ORDER_LAST_SYNC_DATE = `${API_ROOT_URL}/admin/order/history/last_sync_date`;

const DYNAMIC_FIELDS = `${API_ROOT_URL}/admin/order/csv/dynamic_fields`;

const ORDER_BARCODE_PIECE_SCANNED = `${API_V2_ROOT_URL}/admin/admin/order/%s/piece_scanned`;

const SWAP_REFERENCE_ID = `${API_ROOT_URL}/admin/order/swap_reference_id`;

const UPDATE_WEIGHT_UPLOAD_V2_CSV_URL = `${API_V2_ROOT_URL}/admin/upload/update_order_weight_excel`;

const BARCODE_SORTING_ITEM = `${API_ROOT_URL}/sorting/barcode`;
const BARCODE_SEARCH_ITEM = `${API_ROOT_URL}/sorting/search`;
const BATCH_SORTING_CHILDREN = `${API_ROOT_URL}/sorting/batch/%s`;
const BATCH_SORTING_CHILDREN_SIMPLE = `${API_ROOT_URL}/sorting/batch/simple/%s`;
const BATCH_UPDATE_SORTING_BATCH = `${API_ROOT_URL}/sorting/update/batch`;
const UPDATE_REGISTRY_URL = `${API_ROOT_URL}/admin/batch_updates/clone`;
const CANCEL_REGISTRY_URL = `${API_ROOT_URL}/admin/batch_updates/cancel`;
const BATCH_BARCODES_STATUS_UPDATE_URL = `${API_ROOT_URL}/sorting/update/batches`;
const CHILD_PARCELS_OF_BARCODES = `${API_ROOT_URL}/sorting/search_children`;
const GET_HYBRID_ORDER = `${API_ROOT_URL}/sorting/hybrid/barcode`;

const HS_CODE_CHOOSE = `${API_V2_ROOT_URL}/admin/get-hs-code/choose`;

export const getOrder = id => api.getStream(sprintf(ORDER_ITEM_URL, id));
export const createOrder = values => api.post(ORDER_URL, { body: values });
export const updateOrder = (id, values) =>
  api.put(sprintf(ORDER_ITEM_URL, id), { body: values });

export const getOrderCODHistory = id =>
  Observable.combineLatest(
    api
      .getStream(sprintf(ORDER_COD_NOTES_URL, id))
      .catch(() => Observable.of({})),
    api
      .getStream(sprintf(ORDER_COD_HISTORY_ITEMS_URL, id))
      .catch(() => Observable.of({})),
    (notes, history) => ({
      pending: Boolean(notes.pending || history.pending),
      progress: (notes.progress + history.progress) / 2,
      payload: {
        notes: notes.payload,
        history: history.payload,
      },
    }),
  );

export const getDynamicFields = () => api.getStream(DYNAMIC_FIELDS);

export type UploadOrderSignatureRequest = {
  name: string,
  phone: string,
  photo: string,
  signature: string,
  type: string,
};
export type UploadOrderPOP = {
  charge_items: [
    {
      id: number,
      receipt_id: number,
      receipt_number: string,
    },
  ],
  payment_type: CARD,
};

export type SolveExceptionsRequest = {
  driver: {
    id: number,
    name: string,
  },
  event_date: string,
  note: string,
  order_numbers: [string],
  order_status: string,
  supplier: {
    description: string,
    id: number,
    name: string,
    phone: string,
    photo: string,
  },
  type: string,
};

export const getOrderExceptions = (request: DataListFilter) =>
  api.getStream(ORDER_EXCEPTIONS_URL, { params: request.getDefinedValues() });

export const solveExceptions = (body: SolveExceptionsRequest) =>
  api.post(ORDER_SOLVE_EXCEPTIONS_URL, { body });

export const getCalculateTotal = body =>
  api.post(CALCULATE_TOTAL_URL, { body });

export const uploadOrderSignature = (id, body: UploadOrderSignatureRequest) =>
  api.put(sprintf(ORDER_ITEM_SIGNATURE_URL, id), { body });
export const updateOrderIssuance = (id, body) =>
  api.put(sprintf(ORDER_ITEM_ISSUANCE_URL, id), { body });

export const verificationOrderOTP = body =>
  api.post(ORDER_OTP_VERIFICATION_URL, { body });

export const updateRTOInternational = body =>
  api.post(ORDER_RTO_INTERNATIONAL_URL, { body });

export const uploadOrderProofOfPayment = (id, body: UploadOrderPOP) =>
  api.put(sprintf(`${ORDER_ITEM_POP_URL + id}/receive_payment`), { body });

export const orderSync = id => api.post(sprintf(ORDER_SYNC_URL, id));

export const getOrderList = (request: DataListFilter) =>
  api.getStream(ORDER_LIST_URL, {
    timeout: 10 * 60 * 1000,
    params: request.getDefinedValues(),
  });
export const getOrderList2 = params =>
  api.get(ORDER_LIST_URL, {
    params,
  });

export const fetchOrderList = (request: DataListFilter) =>
  api.get(ORDER_LIST_URL, {
    timeout: 10 * 60 * 1000,
    params: request.getDefinedValues(),
  });

export const getBarcode = barcode =>
  api.getStream(BARCODE_SORTING_ITEM, {
    timeout: 10 * 60 * 1000,
    params: { barcode },
  });

export const getAsyncBarcode = barcode =>
  api.get(BARCODE_SORTING_ITEM, {
    timeout: 10 * 60 * 1000,
    params: { barcode },
  });

export const getBarcodeV2 = barcode =>
  api.get(BARCODE_SORTING_ITEM, {
    timeout: 10 * 60 * 1000,
    params: { barcode },
  });

export const getOrderObject = (request: DataListFilter) =>
  api.getStream(BARCODE_SEARCH_ITEM, {
    timeout: 10 * 60 * 1000,
    params: request.getDefinedValues(),
  });

export const getHSCodes = description =>
  api.get(HS_CODE_CHOOSE, { params: { description } });

export const getBatchChildren = (id, params) =>
  api.get(sprintf(BATCH_SORTING_CHILDREN, id), { params });

export const getBatchChildrenSimpleStream = id =>
  api.getStream(sprintf(BATCH_SORTING_CHILDREN_SIMPLE, id));

export const getChildParcels = barcodes =>
  api.get(CHILD_PARCELS_OF_BARCODES, { params: { barcodes } });

export const updateBatchStatusSorting = body =>
  api.post(BATCH_UPDATE_SORTING_BATCH, { body });

export const updateRegistryStatusSorting = body =>
  api.post(UPDATE_REGISTRY_URL, { body });

export const cancelRegistryStatusSorting = body =>
  api.post(CANCEL_REGISTRY_URL, { body });

export const batchBarcodesStatusUpdate = body =>
  api.post(BATCH_BARCODES_STATUS_UPDATE_URL, { body });

export type createOrderNoteRequest = {
  cancellation_reason: string,
  category: string,
  content: string,
  created_date: string,
  entity_class: string,
  entity_id: number,
  id: number,
  noted_by: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  noted_by_role: string,
  privacy: string,
  requires_attention: boolean,
  scheduled_for_cancellation: boolean,
};

export const createOrderNote = (id, body: createOrderNoteRequest) =>
  api.put(sprintf(ORDER_PRIVATE_NOTE_URL, id), { body });

export type BatchAsyncAssignSupplierRequest = {
  order_numbers: string[],
  resend_shipment: boolean,
  supplier: { id: number },
};

export const uploadOverweightCSV = (supplierId, file: File) =>
  api.postStream(OVERWEIGHT_UPLOAD_CSV_URL, {
    file,
    params: { supplier_id: supplierId },
  });

export const updateWeightsCSV = (customerId, file: File) =>
  api.postStream(UPDATE_WEIGHT_UPLOAD_CSV_URL, {
    file,
    params: { customer_id: customerId },
  });

export const updateWeightsV2CSV = (file: File) =>
  api.postStream(UPDATE_WEIGHT_UPLOAD_V2_CSV_URL, {
    file,
  });

export const batchAsyncAssignSupplier = (
  body: BatchAsyncAssignSupplierRequest,
) => api.put(BATCH_ASSIGN_ORDER_SUPPLIER_URL, { body });

export type BatchAsyncUpdateOrderRequest = {
  category: string,
  driver: { id: number },
  note: string,
  order_numbers: string[],
  manual_items: [],
  order_status: string,
  paid_to_customer: boolean,
  paid_to_wing: boolean,
  privacy: string,
  reason: string,
  requires_attention: boolean,
  scheduled_for_cancellation: boolean,
  warehouse: { id: number },
};

export const batchAsyncUpdateOrder = (body: BatchAsyncUpdateOrderRequest) =>
  api.put(BATCH_UPDATE_ORDER_URL, { body });
export const batchAsyncUpdateOrderHybrid = (
  body: BatchAsyncUpdateOrderRequest,
) =>
  api.put(
    `${BATCH_UPDATE_ORDER_URL}?skip_warehouse_validation=true&hybrid=true`,
    { body },
  );

export type BatchUpdatePickupDriverRequest = {
  driver: { id: number },
  order_numbers: string[],
};

export const batchUpdatePickupDriver = (
  values: BatchUpdatePickupDriverRequest,
) => api.post(BATCH_UPDATE_PICK_UP_DRIVER_URL, { body: values });

export const getOrderActivities2 = id =>
  Promise.all([
    api.get(sprintf(ORDER_NOTES_WITHOUT_CHARGE_UPDATE, id)),
    api.get(sprintf(ORDER_HISTORY_URL, id)),
  ]);
export const getOrderActivities = id =>
  Observable.combineLatest(
    api
      .getStream(sprintf(ORDER_NOTES_WITHOUT_CHARGE_UPDATE, id))
      .catch(() => Observable.of({})),
    api
      .getStream(sprintf(ORDER_HISTORY_URL, id))
      .catch(() => Observable.of({})),
    (notes, history) => ({
      pending: Boolean(notes.pending || history.pending),
      progress: (notes.progress + history.progress) / 2,
      payload: {
        notes: notes.payload,
        history: history.payload,
      },
    }),
  );

export type UpdateOrderChargeRequest = {
  charge_type: string,
  id: number,
  quantity: number,
  charge: number,
  date: string,
  paid: boolean,
  payer: string,
  paid_to_customer: boolean,
  paid_to_wing: boolean,
};

export const updateOrderCharge = (orderId, body: UpdateOrderChargeRequest) =>
  api.put(sprintf(ORDER_CHARGE_URL, orderId), { body });

export type BatchCreateNoteRequest = {
  cancellation_reason: string,
  category: string,
  content: string,
  created_date: string,
  entity_class: string,
  entity_id: number,
  id: number,
  noted_by: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  noted_by_role: string,
  privacy: string,
  requires_attention: boolean,
  scheduled_for_cancellation: boolean,
};

export const batchCreateNote = (ids, body: BatchCreateNoteRequest) =>
  api.post(BATCH_CREATE_NOTE_URL, {
    params: { orderNumbers: ids.join(",") },
    body,
  });

export const batchSetOrderSize = (body: {
  size: string,
  order_numbers: string[],
}) => api.put(BATCH_SET_ORDER_SIZE_URL, { body });

export type BatchRescheduleOrderRequest = {
  deadline: string,
  order_numbers: string[],
};

export const batchRescheduleOrder = (body: BatchRescheduleOrderRequest) =>
  api.put(BATCH_ORDER_RESCHEDULE_URL, { body });

export const assignOrderToWarehouse = (id, warehouseId) =>
  api.put(sprintf(ASSIGN_ORDER_TO_SORTING_FACILITY, id), {
    timeout: 10 * 1000,
    params: { warehouse_id: warehouseId },
  });

export type BatchUpdateWarehouseRequest = {
  order_status: string,
  order_numbers: string[],
  warehouse: { id: number },
};

export const batchUpdateOrderWarehouse = (body: BatchUpdateWarehouseRequest) =>
  api.put(BATCH_UPDATE_WAREHOUSE_URL, { body });

export const updateRecipientPostcode = body =>
  api.post(UPDATE_RECIPIENT_POSTCODE_URL, { body });

export const updateSenderPostcode = body =>
  api.post(UPDATE_SENDER_POSTCODE_URL, { body });

export const completeBatch = body => api.post(COMPLETE_BATCH_UPDATE, { body });

export const getOrderSuggestedDrivers = (id, request: DataListFilter) =>
  api.getStream(sprintf(ORDER_DRIVERS_SUGGESTION_URL, id), {
    params: request.getDefinedValues(),
  });
const RADAR_ORDER_DRIVERS_SUGGESTION_URL = `${API_ROOT_URL}/admin/order/%s/driver-suggestions/`;
export const getRadarOrderSuggestedDrivers = (id, request: DataListFilter) =>
  api.getStream(sprintf(RADAR_ORDER_DRIVERS_SUGGESTION_URL, id), {
    params: request.getDefinedValues(),
  });

export const setAdminOrderPiecesSummary = (orderNumber, body) =>
  api.post(sprintf(ORDER_PIECES_SUMMARY_URL, orderNumber), { body });

export const setOrderScannedPeices = orderNumber =>
  api.post(sprintf(ORDER_SCANNED_PIECES, orderNumber));

export const setOrderBarcodeScannedPieces = (orderBarcode, body) =>
  api.post(sprintf(ORDER_BARCODE_PIECE_SCANNED, orderBarcode), { body });

export const getOrderLastSyncDate = (orderId, supplierId) =>
  api.getStream(ORDER_LAST_SYNC_DATE, {
    params: {
      order_id: orderId,
      supplier_id: supplierId,
    },
  });

export const getLocalServerOrderList = (orderNumber, syncOrder) =>
  new WingApi(null, 1).getStream(sprintf(LOCAL_SORTING_CODE_URL, orderNumber), {
    timeout: 10 * 1000,
    headers: { sync_order: syncOrder },
  });

export const uploadSwapReferenceIDs = (file: File) =>
  api.postStream(SWAP_REFERENCE_ID, {
    file,
  });
// export const getHybridOrder = (params) => api.get(GET_HYBRID_ORDER, { params });

export const getHybridOrder = params =>
  api.getStream(GET_HYBRID_ORDER, { params });
