import React, { useEffect, useState } from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import { isMainAdminForProofOfPayment } from "../../helpers/isMainAdmin";
import {
  getCourierType,
  isEnabledAssignDriver,
} from "../../helpers/OrderHelper";
import { getUserAccountAuthorities } from "../../reducers/UserReducer";
import {
  getUser,
  getUserPermissions,
  getUserWarehouseId,
} from "../../reducers/ProfileReducer";
import { getMarketplaceEnableAssignDriver } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  CANCELLED,
  COMPLETED,
  isNotFinalStatus,
  ISSUED_TO_RECIPIENT,
  RETURNED_TO_ORIGIN,
} from "../../constants/OrderStatusCodes";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { getAppName } from "../../../shared/reducers/AppReducer";
import { SUPPLIER } from "../../../server/constants/AppTypes";
import {
  ROLE_ADMIN_VIEWER,
  ROLE_SUPPLIER_BASIC,
} from "../../../shared/constants/Authorities";
import { CREATE_ORDER_TAX_INVOICE_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";
import { getPDF } from "../../helpers/FormUtils";
import {
  AWB_URL,
  BATCH_RECEIPT_URL,
  COD_RECEIPT_URL,
  CP72_URL,
  IS_DOWNLOADING,
  IS_PRINTING,
  RECEIPT_PRINTABLE_URL,
  RTO_RECEIPT_URL,
  UZ,
  ZEBRA_URL,
} from "../orders-core/AdminPostForm";
import CustomButton, { TEXT } from "../ui-core/CustomButton";
import { CircularProgress } from "@material-ui/core";
import { Print } from "@material-ui/icons";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { POST_ITEM_URL } from "../../constants/AdminPathConstants";
import TextWithLink from "../router/TextWithLink";
import { RECEIPT_RETURN_URL } from "./v2/OrderDetailsDialog";
import _ from "lodash";
import { COD, RTO_INTERNATI0NAL } from "../../constants/OrderChargeItemTypes";
import { toJS } from "../../helpers/DataUtils";
import { SIMPLE } from "../../constants/TransportationType";

export const BRIEF_TAB = "brief";
export const SERVICES = "services";
export const HISTORY_TAB = "history";
export const COURIER_TAB = "courier";
export const CUSTOMER_TAB = "customer";
export const MAP_TAB = "map";
export const COD_HISTORY_TAB = "cod_history";
export const SIGNATURE_TAB = "signature";
export const PROOF_OF_PAYMENT = "proof_of_payment";
export const ISSUANCE = "issuance";
export const INVENTORY = "inventory";

const enhancer = compose(
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];
      const app = getAppName(state);
      const supplierRoles = getUserAccountAuthorities(state);

      return {
        userWarehouseId: getUserWarehouseId(state),
        isMainAdminForProofOfPayment: isMainAdminForProofOfPayment(userRoles),
        enableAssignDriverForSettings: getMarketplaceEnableAssignDriver(state),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
        isEditEnabled: !(
          app === SUPPLIER && hasRole(supplierRoles, ROLE_SUPPLIER_BASIC)
        ),
        permissions: getUserPermissions(state),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  withTheme,
  useSheet({
    root: { display: "flex", padding: "0 12px 18px" },
    tab: {
      padding: 0,
      fontSize: "13px",
      marginLeft: "12px",
      marginRight: "12px",
      color: props => props.theme.palette.alternateTextColor,
      border: "none",
      outline: "none",
      cursor: "pointer",
      background: "none",
      fontFamily: "Blogger Sans",
      textTransform: "uppercase",
      transition: `color 250ms ease`,
      "&:hover": {
        color: props => props.theme.active.tab,
        textDecoration: "none",
      },
    },
    customTab: {
      padding: 0,
      fontSize: "13px",
      paddingLeft: "12px",
      paddingRight: "12px",
      color: props => props.theme.tabs.textColor,
      border: "none",
      outline: "none",
      cursor: "pointer",
      background: props => props.theme.palette.newButtonColor1,
      fontFamily: "Blogger Sans",
      textTransform: "uppercase",
      transition: `color 250ms ease`,
      "&:hover": {
        background: props => props.theme.palette.primary1Color,
        color: props => props.theme.tabs.textColor,
        textDecoration: "none",
      },
    },
    tabActive: { color: props => props.theme.active.tab },
    editOrderBtn: {
      textDecoration: "none",
      color: "#263238",
      transition: "all .2s ease",
      "&:hover": {
        color: "#efefef",
      },
    },
  }),
);

OrderDetailsDialogTabs.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  order: PropTypes.instanceOf(Map),
  onTabChange: PropTypes.func.isRequired,
  onShowSuggestedDriverLink: PropTypes.bool,
  createDriverSuggestHref: PropTypes.func,
  withoutCourierTab: PropTypes.bool,
  withoutCustomerTab: PropTypes.bool,
  withoutSignaturesTab: PropTypes.bool,
  tab: PropTypes.oneOf([
    BRIEF_TAB,
    SERVICES,
    CUSTOMER_TAB,
    COURIER_TAB,
    HISTORY_TAB,
    MAP_TAB,
    SIGNATURE_TAB,
    COD_HISTORY_TAB,
    PROOF_OF_PAYMENT,
    INVENTORY,
  ]),
  withCODHistory: PropTypes.bool,
  withTaxInvoice: PropTypes.bool,
  isMainAdminForProofOfPayment: PropTypes.bool,
  enableAssignDriverForSettings: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  userWarehouseId: PropTypes.number,
  permissions: PropTypes.array,
};

function OrderDetailsDialogTabs(props) {
  const [stateAWB, setStateAWB] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url:
      props.order.getIn(["recipient_data", "jurisdiction", "country_code"]) ===
      UZ
        ? AWB_URL
        : CP72_URL,
  });
  useEffect(() => {
    if (
      props.order.getIn(["recipient_data", "jurisdiction", "country_code"]) ===
      UZ
    ) {
      setStateAWB({
        ...stateAWB,
        url: AWB_URL,
      });
    } else {
      setStateAWB({
        ...stateAWB,
        url: CP72_URL,
      });
    }
  }, [props.order.getIn(["recipient_data", "jurisdiction", "country_code"])]);

  const [zebra, setZebra] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ZEBRA_URL,
  });
  const [stateReceiptText, setStateReceiptText] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RECEIPT_PRINTABLE_URL,
  });
  const [stateReceiptReturn, setStateReceiptReturn] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RECEIPT_RETURN_URL,
  });
  const [stateBatchReceipt, setStateBatchReceipt] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: BATCH_RECEIPT_URL,
  });
  const [stateReceiptCOD, setStateReceiptCOD] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: COD_RECEIPT_URL,
  });
  const [stateReceiptRTO, setStateReceiptRTO] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RTO_RECEIPT_URL,
  });
  const { classes, getLocalisationMessage } = props;
  const orderId = props.order.get("id");
  const chargeItems = props.order.get("charge_items", []);
  const chargeItemCOD = _.find(toJS(chargeItems), { charge_type: COD }) || {};
  const chargeItemRTO =
    _.find(toJS(chargeItems), { charge_type: RTO_INTERNATI0NAL }) || {};
  const isHaveCOD = chargeItemCOD.charge > 0;
  const isHaveRTO = _.get(chargeItemRTO, "paid", false);
  const orderStatus = props.order.get("status");
  const transportationType = props.order.get("transportation_type");
  const payBatchId = props.order.get("pay_batch_id");
  const orderWarehouseId = props.order.getIn(["warehouse", "id"]);
  const isOrderLocatedHere = props.userWarehouseId === orderWarehouseId;
  const canSeeSimpleOrderHistory = props.permissions.includes(
    "CAN_SEE_SIMPLE_ORDER_HISTORY",
  );

  return (
    <FlexBox className={classes.root}>
      <FlexBox flex={true}>
        <button
          disabled={props.disabled}
          onClick={() => props.onTabChange(BRIEF_TAB)}
          className={cx(classes.tab, {
            [classes.tabActive]: props.tab === BRIEF_TAB,
          })}
        >
          {getLocalisationMessage("brief", "Brief")}
        </button>

        <button
          disabled={props.disabled}
          onClick={() => props.onTabChange(SERVICES)}
          className={cx(classes.tab, {
            [classes.tabActive]: props.tab === SERVICES,
          })}
        >
          {getLocalisationMessage("services", "Services")}
        </button>

        {Boolean(
          2 === 3 &&
            !props.withoutCustomerTab &&
            props.order.getIn(["customer", "id"]),
        ) && (
          <button
            disabled={props.disabled}
            onClick={() => props.onTabChange(CUSTOMER_TAB)}
            className={cx(classes.tab, {
              [classes.tabActive]: props.tab === CUSTOMER_TAB,
            })}
          >
            {getLocalisationMessage("customer", "Customer")}
          </button>
        )}

        {transportationType === SIMPLE && canSeeSimpleOrderHistory && (
          <button
            disabled={props.disabled}
            onClick={() => props.onTabChange(HISTORY_TAB)}
            className={cx(classes.tab, {
              [classes.tabActive]: props.tab === HISTORY_TAB,
            })}
          >
            {getLocalisationMessage("history", "History")}
          </button>
        )}

        {transportationType !== SIMPLE && (
          <button
            disabled={props.disabled}
            onClick={() => props.onTabChange(HISTORY_TAB)}
            className={cx(classes.tab, {
              [classes.tabActive]: props.tab === HISTORY_TAB,
            })}
          >
            {getLocalisationMessage("history", "History")}
          </button>
        )}

        {props.withCODHistory && (
          <button
            disabled={props.disabled}
            onClick={() => props.onTabChange(COD_HISTORY_TAB)}
            className={cx(classes.tab, {
              [classes.tabActive]: props.tab === COD_HISTORY_TAB,
            })}
          >
            {getLocalisationMessage("finance_history", "Finance History")}
          </button>
        )}

        <button
          disabled={props.disabled}
          onClick={() => props.onTabChange(MAP_TAB)}
          className={cx(classes.tab, {
            [classes.tabActive]: props.tab === MAP_TAB,
          })}
        >
          {getLocalisationMessage("map", "Map")}
        </button>
        <button
          disabled={props.disabled}
          onClick={() => props.onTabChange(SIGNATURE_TAB)}
          className={cx(classes.tab, {
            [classes.tabActive]: props.tab === SIGNATURE_TAB,
          })}
        >
          {getLocalisationMessage("signatures", "Signatures")}
        </button>
        {props.isMainAdminForProofOfPayment && (
          <button
            disabled={props.disabled}
            onClick={() => props.onTabChange(PROOF_OF_PAYMENT)}
            className={cx(classes.tab, {
              [classes.tabActive]: props.tab === PROOF_OF_PAYMENT,
            })}
          >
            {getLocalisationMessage("proof_of_payment", "Proof of payment")}
          </button>
        )}
        <button
          disabled={props.disabled}
          onClick={() => props.onTabChange(INVENTORY)}
          className={cx(classes.tab, {
            [classes.tabActive]: props.tab === INVENTORY,
          })}
        >
          {getLocalisationMessage("inventory", "Inventory")}
        </button>
      </FlexBox>

      <FlexBox>
        {Boolean(
          isEnabledAssignDriver(
            props.enableAssignDriverForSettings,
            getCourierType(props.order),
          ),
        ) &&
          props.onShowSuggestedDriverLink && (
            <LinkButton
              className={classes.customTab}
              disabled={props.disabled}
              onClick={props.createDriverSuggestHref}
            >
              {getLocalisationMessage("assign_driver", "Assign Driver")}
            </LinkButton>
          )}

        {props.withTaxInvoice &&
          Boolean(
            orderStatus === COMPLETED || orderStatus === RETURNED_TO_ORIGIN,
          ) && (
            <Link
              target="_blank"
              className={classes.tab}
              disabled={props.disabled}
              to={updateQuery(CREATE_ORDER_TAX_INVOICE_URL, {
                id: orderId,
              })}
            >
              {getLocalisationMessage("get_tax_invoice", "Get Tax Invoice")}
            </Link>
          )}
        {transportationType !== SIMPLE && (
          <CustomButton
            variant={TEXT}
            onClick={() =>
              getPDF(
                stateAWB,
                setStateAWB,
                IS_PRINTING,
                orderId,
                props.showErrorMessage,
              )
            }
            endIcon={
              stateAWB[IS_PRINTING] ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <Print />
              )
            }
          >
            {getLocalisationMessage("get_awb", "Get AWB")}
          </CustomButton>
        )}

        {transportationType !== SIMPLE && (
          <CustomButton
            variant={TEXT}
            onClick={() =>
              getPDF(
                zebra,
                setZebra,
                IS_PRINTING,
                orderId,
                props.showErrorMessage,
              )
            }
            endIcon={
              zebra[IS_PRINTING] ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <Print />
              )
            }
          >
            {getLocalisationMessage("address_label")}
          </CustomButton>
        )}
        {transportationType !== SIMPLE &&
          (payBatchId ? (
            <CustomButton
              // style={{ color: !isPaid && "rgba(0, 0, 0, 0.26) !important" }}
              // variant={isPaid ? TEXT : OUTLINED}
              // disabled={!isPaid}
              data-cy="receipt_print"
              onClick={() =>
                getPDF(
                  stateBatchReceipt,
                  setStateBatchReceipt,
                  IS_PRINTING,
                  payBatchId,
                  props.showErrorMessage,
                )
              }
              endIcon={
                stateBatchReceipt[IS_PRINTING] ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("receipt")}
            </CustomButton>
          ) : (
            <CustomButton
              // style={{ color: !isPaid && "rgba(0, 0, 0, 0.26) !important" }}
              // variant={isPaid ? TEXT : OUTLINED}
              // disabled={!isPaid}
              data-cy="receipt_print"
              onClick={() =>
                getPDF(
                  orderStatus === CANCELLED
                    ? stateReceiptReturn
                    : stateReceiptText,
                  orderStatus === CANCELLED
                    ? setStateReceiptReturn
                    : setStateReceiptText,
                  IS_PRINTING,
                  orderId,
                  props.showErrorMessage,
                )
              }
              endIcon={
                stateBatchReceipt[IS_PRINTING] ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("receipt")}
            </CustomButton>
          ))}
        {isHaveCOD &&
          (orderStatus === ISSUED_TO_RECIPIENT ||
            orderStatus === COMPLETED) && (
            <CustomButton
              data-cy="receipt_print"
              onClick={() =>
                getPDF(
                  stateReceiptCOD,
                  setStateReceiptCOD,
                  IS_PRINTING,
                  orderId,
                  props.showErrorMessage,
                )
              }
              endIcon={
                stateReceiptCOD[IS_PRINTING] ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("receipt_cod")}
            </CustomButton>
          )}
        {isHaveRTO && orderStatus === RETURNED_TO_ORIGIN && (
          <CustomButton
            data-cy="receipt_print"
            onClick={() =>
              getPDF(
                stateReceiptRTO,
                setStateReceiptRTO,
                IS_PRINTING,
                orderId,
                props.showErrorMessage,
              )
            }
            endIcon={
              stateReceiptRTO[IS_PRINTING] ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <Print />
              )
            }
          >
            {getLocalisationMessage("receipt_rto", "ЧЕК ВОЗВРАТА")}
          </CustomButton>
        )}
        {transportationType !== SIMPLE &&
          isNotFinalStatus(orderStatus) &&
          Boolean(!props.order.get("paid") || isOrderLocatedHere) && (
            <LinkButton className={classes.tab} disabled={props.disabled}>
              <TextWithLink
                className={classes.editOrderBtn}
                to={POST_ITEM_URL + orderId}
              >
                {getLocalisationMessage("edit_order", "Edit Order")}
              </TextWithLink>
            </LinkButton>
          )}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderDetailsDialogTabs);
