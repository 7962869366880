import fp from "lodash/fp";
import { isValidDate, isValidTimeString } from "./DateUtils";
import {
  isBlankString,
  isValidCoordinates,
  isValidObjectId,
  isValidObjectIdForNeighborhood,
} from "./ValidateUtils";
import { parseIntString, parseString } from "./SerializeUtils";
import { api } from "../api/shared/BaseApi";
import { IS_PRINTING } from "../components/orders-core/AdminPostForm";
import { formatDateTimeToUrl } from "./FormatUtils";
import { IS_PRINTING2 } from "../containers/admin/AdminCashCertificateContainer";

const EMAIL_REG =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = email => EMAIL_REG.test(email);

export const getObjectId = fp.flow(fp.get("id"), fp.toInteger, id =>
  id > 0 ? id : null,
);

export const getObjectIdIncludingUnknown = fp.flow(
  fp.get("id"),
  fp.toInteger,
  id => (id > -2 ? id : null),
);

export const getObjectCode = fp.flow(fp.get("id"), id =>
  !fp.isEmpty(id) ? id : null,
);

export const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);
export const codesToObjectArray = fp.flow(
  parseString,
  fp.map(id => ({ id })),
);

export const createNotEmptyValidator = fp.memoize(message => value =>
  fp.isEmpty(value) ? message : null,
);

export const createValidateStringValidator = fp.memoize(message => value =>
  isBlankString(value) ? message : null,
);

export const createPhoneEmptyValidator = fp.memoize(message => value =>
  value && value.length < 8 ? message : null,
);
export const createNotEmptyValidatorWithState = fp.memoize(
  (message, setState) => value => {
    if (fp.isEmpty(value)) {
      setState(false);
      return message;
    }
    setState(true);
    return null;
  },
);

export const createNotNilValidator = fp.memoize(message => value =>
  fp.isNil(value) ? message : null,
);

export const createNotNullValidator = fp.memoize(message => value =>
  value < 1 ? message : null,
);

export const createNotFalsyValidator = fp.memoize(message => value =>
  value ? null : message,
);

export const createFileValidator = fp.memoize(message => value =>
  value instanceof File ? null : message,
);
export const tariffCodeValidator = fp.memoize(message => value =>
  value ? null : message,
);

export const createFiniteValidator = fp.memoize(message => value =>
  fp.isFinite(value) ? null : message,
);

export const createOtpValidator = fp.memoize(message => value =>
  value && message ? message : null,
);
export const createDateValidator = fp.memoize(message => value =>
  isValidDate(value) ? null : message,
);

export const createObjectIdValidator = fp.memoize(message => value =>
  isValidObjectId(value) ? null : message,
);

export const createObjectIdValidatorForNeighborhood = fp.memoize(
  message => value => (isValidObjectIdForNeighborhood(value) ? null : message),
);

export const createDateTimeStringValidator = fp.memoize(message => value =>
  isValidTimeString(value) ? null : message,
);

export const createRegisteredBarcodeValidator = fp.memoize(
  (barcodeErrorMessage, message) => value =>
    value && barcodeErrorMessage
      ? barcodeErrorMessage
      : fp.isEmpty(value) && message
      ? message
      : null,
);

export const createOtherNameValidator = fp.memoize(
  (otherNameRequired, message) => value =>
    fp.isEmpty(value) && otherNameRequired ? message : null,
);

export const createINNValidator = fp.memoize(message => value =>
  fp.isEmpty(value) && message ? message : null,
);

export const createMXIKCodeValidator = fp.memoize(message => value =>
  fp.isEmpty(value) && message ? message : null,
);

export const createUnlimitedDateTimeStringValidator = fp.memoize(
  message => value => (isValidTimeString(value, true) ? null : message),
);

export const createCoordinatesValidator = fp.memoize(message => value =>
  isValidCoordinates(value) ? null : message,
);

export const createPercentValidator = fp.memoize(message => value =>
  fp.isFinite(value) && value >= 0 && value <= 100 ? null : message,
);

export const toFinite = v => fp.toFinite(v);

export function pdfDownload(dataurl, ids) {
  const a = document.createElement("a");
  a.href = dataurl;
  a.setAttribute("download", `${ids}.pdf`);
  a.click();
  return false;
}

export const downloadPDF = (url, id) => {
  api.get(url, { params: { ids: id } }).then(res =>
    fetch(res.data)
      .then(response => response.blob())
      .then(myBlob => pdfDownload(URL.createObjectURL(myBlob), id)),
  );
};
export const setInvalidate = (
  state,
  setState,
  loading,
  batchIds,
  showErrorMessage,
  showSuccessMessage,
  refresh,
) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .post(state.url, { body: { batch_ids: batchIds } })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res => {
      if (res.status === "success") {
        showSuccessMessage(res.status);
        setState(prevState => ({ ...prevState, [loading]: false }));
        refresh();
      } else {
        showErrorMessage(res);
        setState(prevState => ({ ...prevState, [loading]: false }));
      }
    });
};
export const getPDF = (state, setState, loading, id, showErrorMessage) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(state.url, { params: { ids: fp.isArray(id) ? id.join(",") : id } })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL, id);
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};

export const getForm55PDF = (
  state,
  setState,
  loading,
  id,
  paramsFilter,
  showErrorMessage,
) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  const params =
    id && id.length > 0
      ? { ids: fp.isArray(id) ? id.join(",") : id }
      : { ...paramsFilter };
  api
    .get(state.url, { params })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL, id);
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};

export const getMpPDF = (
  state,
  setState,
  loading,
  paramsFilter,
  showErrorMessage,
) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  const params = { ...paramsFilter };
  api
    .get(state.url, { params })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL);
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};

export const getIPSPDF = (state, setState, loading, id, showErrorMessage) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(`${state.url}/${id}`)
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res => {
      // eslint-disable-next-line lodash/prefer-get
      if (res && res.data && res.data.value) {
        fetch(res.data.value)
          .then(response => response.blob())
          .then(myBlob => {
            let objectURL = URL.createObjectURL(myBlob);
            if (myBlob && myBlob.size > 0) {
              if (loading === IS_PRINTING) {
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                document.body.appendChild(iframe);
                iframe.src = objectURL;
                objectURL = URL.revokeObjectURL(myBlob);
                window.setTimeout(() => {
                  iframe.contentWindow.print();
                  setState(prevState => ({ ...prevState, [loading]: false }));
                }, 1000);
              } else {
                pdfDownload(objectURL, id);
                setState(prevState => ({ ...prevState, [loading]: false }));
              }
            } else {
              showErrorMessage(
                "Сервер не отвечает,пожалуйста повторите попытку позже",
              );
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          });
      } else {
        showErrorMessage("Pdf not found");

        setState(prevState => ({ ...prevState, [loading]: false }));
      }
    });
};

export const getShiftPDF = (state, setState, loading, id, showErrorMessage) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(`${state.url}/${id}`)
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res => {
      // eslint-disable-next-line lodash/prefer-get
      if (res && res.data) {
        fetch(res.data)
          .then(response => response.blob())
          .then(myBlob => {
            let objectURL = URL.createObjectURL(myBlob);
            if (myBlob && myBlob.size > 0) {
              if (loading === IS_PRINTING) {
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                document.body.appendChild(iframe);
                iframe.src = objectURL;
                objectURL = URL.revokeObjectURL(myBlob);
                window.setTimeout(() => {
                  iframe.contentWindow.print();
                  setState(prevState => ({ ...prevState, [loading]: false }));
                }, 1000);
              } else {
                pdfDownload(objectURL, id);
                setState(prevState => ({ ...prevState, [loading]: false }));
              }
            } else {
              showErrorMessage(
                "Сервер не отвечает,пожалуйста повторите попытку позже",
              );
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          });
      } else {
        showErrorMessage("Pdf not found");

        setState(prevState => ({ ...prevState, [loading]: false }));
      }
    });
};

export const getPDF2 = (
  state,
  setState,
  loading,
  id,
  showErrorMessage,
  consolidated,
) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(state.url, {
      params: { ids: fp.isArray(id) ? id.join(",") : id, consolidated },
    })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL, id);
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};
export const getPDFHybrid = (
  state,
  setState,
  loading,
  id,
  showErrorMessage,
  hybrid,
) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(state.url, {
      params: { ids: fp.isArray(id) ? id.join(",") : id, hybrid },
    })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL, id);
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};

export const getCCPDF = (
  state,
  setState,
  loading,
  showErrorMessage,
  fromDate,
  toDate,
  direction,
) => {
  const parsedFromDate = formatDateTimeToUrl(fromDate.setHours(0, 0, 0, 0));
  const parsedToDate = formatDateTimeToUrl(toDate.setHours(23, 59, 0, 0));
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(state.url, {
      params: {
        from_date_time: parsedFromDate,
        to_date_time: parsedToDate,
        direction,
      },
    })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING || loading === IS_PRINTING2) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL, "document");
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};
